import { useEffect } from "react";
import swal from "sweetalert";

import store from '../../stores/store';
import { newHandleReservation } from "../Methods/handleReservation/newHandleReservation";
const TagManager = require('react-gtm-module');

const GooglePay = ({ amount, onPaymentCompletion, state, setState, props, isGiftcard, handleFormData }) => {

    const settings = {
        container: '#container',
        merchantName: window.ENV.processorInfo.googleMerchantName,
        merchantId: window.ENV.processorInfo.googleMerchantId,
        gatewayMerchantId: window.ENV.processorInfo.publicKey,
        allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
        allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        transactionInfo: {
            countryCode: 'US',
            currencyCode: 'USD',
            totalPrice: amount.toString()
        },

        onGooglePaymentButtonClicked: async paymentDataRequest => {
            let isenablePurchase;
            if (!isGiftcard) {
                isenablePurchase = await handleFormData("googlePay");

            }
            let result;
            if (isenablePurchase || isGiftcard) {
                if (!isGiftcard) {
                    result = await newHandleReservation(state, setState, props);
                    if (window.ENV.gtmId) {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'beginCheckout',
                                data: window.ENV.gtmId
                            }
                        })
                    }
                } else {
                    result = "giftcard"
                }

                await paymentDataRequest
                    .then(paymentData => {
                        // Get the token.
                        const token = paymentData.paymentMethodData.tokenizationData.token
                        console.log(token, "token");
                        if (!isGiftcard) {
                            result.isGooglePay = true;
                            state = {};

                        }
                        else {
                            state.isGooglePay = true;
                        }

                        var body = {
                            "type": "sale",
                            "reservationData": result,
                            "data": state,
                            "amount": parseInt(amount * 100),
                            "payment_method": {
                                "google_pay_token": token
                            }
                        };
                        swal({
                            title: "Please Wait",
                            text: "Please Wait and check email in a few minutes, make sure to check your spam",
                            icon: "info",
                            dangerMode: false,
                        })
                        store.celeroTransaction(body)
                            .then(res => {

                                console.log('after celero transactoin');
                                console.log(res, "<=======res==");
                                if (res?.data?.data?.status === "pending_settlement") {
                                    swal({
                                        title: "Success",
                                        text: `Congratulations for creating your skydiving reservation \n Please check your email (including spam and junk) for confirmation and waiver signing \n We look forward to seeing you soon`,
                                        icon: "success",
                                        dangerMode: false,
                                    })
                                    console.log('status is success');
                                    res.data.isGooglePay = true;
                                    onPaymentCompletion(res.data, null);
                                }
                                else{
                                    swal({
                                        title: "Something went wrong",
                                        text: `Something wrong with googlepay, please try again`,
                                        icon: "warning",
                                        dangerMode: false,
                                    })
                                }
                            })
                            .catch(err => {
                                swal({
                                    title: "Something went wrong",
                                    text: `Something wrong with googlepay, please try again`,
                                    icon: "warning",
                                    dangerMode: false,
                                })
                            })
                    }).catch(err => {
                        console.log(err)
                    })
            } else {
                swal({
                    title: "Warning!",
                    text: "Please fill the required fields",
                    icon: "error",
                    dangerMode: false,
                })
            }
        }
    }

    let gp;
    useEffect(() => {
        gp = new walletjs.GooglePay(settings);
    }, [])


    return (
        <div id="container" className="googlePayButton"></div>
    )

}


export default GooglePay;