
import { create } from 'apisauce';


// import urls from '../config'
// define the api
var url = window.location.hostname;

let reservationsUrl =  'http://localhost:4000';
let tenant = "localhost";
if(url.includes("localhost") && !url.includes("localhost.reservations")){
	reservationsUrl =  'http://localhost:4000'
}
else{
	var dz = url.split('.')[0];
	if(dz){
		reservationsUrl = `https://${dz}.reservations-api.dizio.app`;
	}
	tenant  = dz;
}

const api = create({
	//localhost:4000
	baseURL: reservationsUrl,
	headers: {'tenant': tenant}
});

const payAPI = create({
	baseURL: reservationsUrl // don't really need this anymore;
});

const ipAPI = create({
	baseUrl: "https://api.ipify.org/?format=json"
})

const func = {};

func.getClientIp = async () => {
	return fetch("https://api.ipify.org/?format=json")
	.then(async (response) => {
		
		let res = await response.json();
		console.log('in getClientIp', res);
		let ip = res.ip;
		api.setHeader('client-ip', ip);
		payAPI.setHeader('client-ip', ip);
		window.clientIp = ip;
		return res;
	})
}
func.GetTimeslots = (date, phone) => {
	return api.get(`/timeslots/forReservations/?day=${date}&phone=${phone}`);
};

func.getMonthlySlots = (date) => {
	return api.get(`/timeslots/forReservations/?startDay=${date.start}&endDay=${date.end}&phone=${date.phone}`)
};

func.verifyDiscount = (code) => {
	return api.get('/timeslots/discount/' + code);
};

func.verifyTandemDiscount = (code) => {
	return api.get('/timeslots/tandemdiscount/' + code);
};

func.verifyGroupon = (code) => {
	return api.get('/groupon/verify/' + code);
};

func.verifyGiftcard = (code) => {
	return api.get('/giftcards/verify/' + code);
};

func.getIdentityInfo = () => {
	return api.get('/processorInfo');
};


func.loadSettings = () => {
	console.log('load settings');
	return api.get('/admin/loadSettings'); //TODO: need to make call to reservations api instead of main api
}

func.loadConfig = () => {
	console.log('load settings');
	return api.get('/admin/loadReservationsConfig'); //TODO: need to make call to reservations api instead of main api
}

func.loadSquareLocationId = () => {
	console.log('load settings');
	return api.get('/squareOauth/locationId'); //TODO: need to make call to reservations api instead of main api
}

func.loadPrices = (startdate, endDate) => {
	console.log('load price data');
	return api.get('/items/loadReservationItems'); //TODO: need to make call to reservations api instead of main api
}
func.loadLocalDetails = () => {
	return api.get('/items/loadReservationItems');
}

func.loadGiftCards = () => {
	return api.get('/items/loadGiftcard');
}

func.startSession = (identityToken) => {
	console.log(identityToken);

	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(identityToken + ':test').toString('base64'),
		Accept: 'application/json'
	});

	return payAPI.get('/SvcInfo/token');
};

func.getApplicationData = (sessionToken, profileId) => {

	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get(`/SvcInfo/appProfile/${profileId}`);
}

func.saveApplicationData = (sessionToken, applicationData) => {

	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.put('/SvcInfo/appProfile', applicationData);
}

func.getServiceInformation = (sessionToken) => {
	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get('/SvcInfo/serviceInformation');
}

func.isMerchantProfileInitialized = (sessionToken, merchantProfileId, serviceId) => {
	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json'
	});
	return payAPI.get(`/SvcInfo/merchProfile/${merchantProfileId}/OK?serviceId=${serviceId}`);
}

func.authorizeAndCapture = (sessionToken, serviceId, body) => {
	console.log('in authorize and capture');
	payAPI.setHeaders({
		Authorization: 'Basic ' + Buffer.from(sessionToken + ':test').toString('base64'),
		Accept: 'application/json',
		"Content-Type": 'application/json'
	});
	return payAPI.post(`/Txn/${serviceId}`, body);
}

func.sendEmail = (body) => {
	return api.post(`/sendEmail`, body);
}

func.sendErrorEmail = (body) => {
	return api.post(`/sendErrorEmail`, body);
}

func.sendTandCtoEmail = (body) => {
	return api.post(`/sendTandCEmail`, body);
}

func.saveCoupons = (body) => {
	return api.post(`/saveCoupons`, body);
}

func.getCoupons = (body) => {
	return api.get(`/getCoupons`, body);
}

func.checkCouponAPi = (body) => {
	return api.post(`/findCouponByCode`, body);
}

func.changeReservation = (payload) => {
	return api.put('/reservations/changeReservation', payload);
}

func.getReservationDetails = (id) => {
	return api.get(`/reservations/details/${id}`);
}

func.getTimeSlotsByEventName = (eventname) => {
	return api.get(`/timeslots/event/${eventname}`);
}

func.makeReservation = (reservationBody) => {
	console.log("in make Reservation Activity", reservationBody);
	return api.post('/reservations', reservationBody);
}

func.ceateOrUpdateReservation = (reservationBody) => {
	console.log("in make Reservation Activity");
	return api.post('/reservations/savedReservation', reservationBody);
}
func.deleteSavedParking = (reservationId) => {
	console.log("in make Reservation Activity");
	return api.delete(`/reservations/savedReservation/${reservationId}`);
}

func.getSavedParking = (reservationId) => {
	console.log("in make Reservation Activity");
	return api.get(`/reservations/savedReservation/${reservationId}`);
}


func.UpdateGiftCard = (body) => {
	console.log("in Update Gift Card");
	return api.put('/giftcards', body);
}

func.createGiftCard = (cardBody) => {
	console.log("in Create Gift Card");
	return api.post('/giftcards', cardBody);
}

func.verifyGiftCard = (cardBody) => {
	console.log("in make Reservation Activity");
	return api.post('/giftcards/verify', cardBody);
}

func.getLoadCalls = () => {
	console.log("in get Load calls");
	return api.get('/admin/loadcalls');
}
func.getCalenderEvents = () => {
	return api.get(`/reservations/calenderEvents`)
}

func.transaction = (body) => {
	return api.post(`/transnational/proxy/transaction`, body)
}

func.verifyTurnstile = (body) => {
	return api.post(`/verify-turnstile`, body)
}
export default func;
