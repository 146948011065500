import 'airbnb-js-shims'
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import Loader from './components/Loader/Loader'
import './css/app-component.css?abc=1';

import App from './App'
import { useState, useEffect } from 'react';
import api from './services/api';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4457ce5e9b1372c3844d03a223b4cea4@o1422956.ingest.sentry.io/4506683485650944",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function Index() {
  const [isTurnstileVerified, setIsTurnstileVerified] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);

  useEffect(() => {
    // Dynamically load Cloudflare Turnstile script
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    // Render the Turnstile widget once the script is loaded
    script.onload = () => {
      console.log('process.env', process.env.REACT_APP_TURNSTILE_SITE_KEY);
      if (window.turnstile) {
        window.turnstile.render('#turnstile-container', {
          sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY, // Replace with your Turnstile site key
          callback: function(token) {
            console.log(token);
            setTurnstileToken(token);
            verifyToken(token);
          },
        });
      }
    };
  }, []);

  // Function to verify the Turnstile token with the backend
  const verifyToken = async (token) => {
    //console.log(token);
    try {
      const ipresponse = await api.getClientIp();
      console.log('ip', ipresponse);
      const response = await api.verifyTurnstile({token, clientIp: ipresponse.ip})

      console.log(response);
      if (response.data.success) {
        setIsTurnstileVerified(true); // If token verification is successful
      } else {
        console.error('Turnstile verification failed.');
      }
    } catch (error) {
      console.error('Error verifying Turnstile token:', error);
    }
  };

  return (
    <div>
      {!isTurnstileVerified ? (
        <div>
          <h2>Redirecting to Bookings site ...</h2>
          <div id="turnstile-container"></div>
        </div>
      ) : (
        <App />
      )}
    </div>
  );
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);
