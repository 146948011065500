import React from 'react';
import tandemStore from '../../stores/store';

function TotalTax({ totalTax, payType, length }) {

    const isShow = () => {
        if (tandemStore.isTaxEnabled() && payType !== 'deposit' || length > 0) {
            return true;
        }
        return false;
    }
    //only show if tax is enable
    return isShow() ?
        (
            <tr>
                <td>Total Tax: </td>
                <td>-</td>
                <td style={{ textAlign: 'right' }}> ${totalTax.toFixed(2)}</td>
            </tr>
        )
        : null;
}
export default TotalTax;