import './Loader.css'

export default function Loader(){
    return(
        <div style={{height:"100vh"}}>
        <div className="fallback-loader">
            Loading...
        </div>
        </div>
    )
}

