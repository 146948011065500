import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Loader from './components/Loader/Loader'
import tandemDataStore from './stores/store'

import Reservation from './components/reservation';
import regularReservations from './components/regularReservations';
import GrouponReservations from './components/GrouponReservations';
import GiftcardReservations from './components/giftcardReservations';
import phoneReservations from './components/phoneReservations';
import ChangeReservation from './components/ChangeReservation/ChangeReservation';
import logo from './images/logo.svg'
import processorFactory from './components/ProcessorFatory';
import RegularReservations from './components/regularReservations';
import PhoneReservations from './components/phoneReservations';

var width = window.outerWidth
var ismobile = width <= 500 ? true : false
var backgroundImage;

const CheckCartComponent = React.lazy(() => import('./components/CheckCartComp'))
const CheckOutComponent = React.lazy(() => import('./components/CheckoutComp'))
const GalleryComponent = React.lazy(() => import('./components/GalleryComponent'))
const GiftCard = React.lazy(() => import('./components/GiftCard/giftCard'))
const ShopComponent = React.lazy(() => import('./components/ShopComponent'))
const LoadCallPage = React.lazy(() => import('./components/LoadCallPage/LoadCallContainer'))

class App extends Component {
    processorComp;
    constructor(props) {
        super(props);
        this.state = {
            isGroupon: false,
            isLoading: false,
            configLoaded: false
        }
    }
    handleConfigLoaded = () => {
        //alert(JSON.stringify(window.ENV, null,2));
        if (window.ENV.gtmId) {
            const TagManager = require('react-gtm-module');
            const tagManagerArgs = {
                gtmId: window.ENV.gtmId,
                /*events: {
                    bookNowClick: 'bookNow click',
                    giftcardClick: 'gift click',
                    calenderClick: 'calendar click',
                    beginCheckout: 'begin_checkout',
                    timeSlotClick: 'timeslot_click',
                    failedPurchase: 'failed_purchase',
                    giftcardPurchase: 'giftcard purchase',
                    giftcardAddToCart: 'giftcard add to cart',
                    giftcardFailedPurchase: 'giftcard failed purchase',
                    purchase: 'purchase'
                }*/
            }
            //alert('gtm init');
            TagManager.initialize(tagManagerArgs);
            TagManager.dataLayer({
                dataLayer: {
                    event: 'bookNowClick',
                }
            })
        }
        backgroundImage = window.ENV.backgroundImg;
        if (ismobile) {
            backgroundImage = window.ENV.mobileBackgroundImg
        }

        this.setState({ configLoaded: true })

        //alert(backgroundImage);
    }
    componentWillMount() {
        //tandemDataStore.loadPrices();
        window.MainApp = this;
        tandemDataStore.loadSettings();

        tandemDataStore.loadConfig()
            .then(stats => {
                console.log('after loading details', stats);
                if (stats) {
                    this.handleConfigLoaded();
                }
            })
    }
    componentDidMount() {

        /*
        if (window.ENV.facebooktrackingID) {

            const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
            const options = {
                autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                debug: false, // enable logs
            };
            ReactPixel.init(window.ENV.facebooktrackingID, advancedMatching, options);

            ReactPixel.pageView();
        }
        if (window.ENV.awId && window.ENV.awFullId) {
            let aw = document.createElement("script");
            aw.async = true;
            aw.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=" + window.ENV.awId);
            aw.setAttribute("language", "javascript");
            document.getElementsByTagName('head')[0].appendChild(aw);

            let aw2 = document.createElement("script");
            var inlineCode = document.createTextNode('window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(`js`, new Date());');
            aw2.appendChild(inlineCode);
            document.head.appendChild(aw2);
            window.gtag('config', window.ENV.awId);
            window.gtag('event', 'booking_click', { 'send_to': window.ENV.awFullId });

            //alert('aw added');
        }*/
    }
    IsEnableLoader = (isLoading) => {
        this.setState({
            isLoading: isLoading
        }, () => {
            return true
        })
    }


    render() {
        return (
            <React.Fragment>
                {this.state.configLoaded ?
                    <div style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeatY: 'no-repeat',
                        position: 'absolute',
                        width: '100%',
                        backgroundSize: 'cover',
                        minHeight: '100%'
                    }}
                    >
                        <div className="container" >
                            <div className="row">
                                <img src={logo} alt="logo" className='col-auto' style={{ height: 60, margin: 'auto', marginBottom: 50 }} />
                            </div>
                            <Router>
                                <div className='sweet-loading'>
                                    <ScaleLoader
                                        height={35}
                                        width={8}
                                        margin={'2px'}
                                        radius={2}
                                        color={'#123abc'}
                                        loading={this.state.isLoading}
                                    />
                                    {this.state.isloading ?
                                        <span style={{ color: 'white', fontSize: '20px', marginLeft: '-20px ' }}>Please Wait...</span> : ''}
                                </div>
                                {console.log(this.processorComp)}
                                <React.Suspense fallback={<Loader />}>
                                    <div className="App">
                                        <Route exact path="/" render={() => <RegularReservations groupon={false}  />} />
                                        <Route exact path="/plantcity" render={() => <RegularReservations groupon={false}  />} />
                                        <Route exact path="/lakewales" render={() => <RegularReservations groupon={false}  />} />
                                        <Route exact path="/groupon" render={() => <GrouponReservations component={GrouponReservations} />} />
                                        <Route exact path="/rush49" component={GrouponReservations} />
                                        <Route exact path="/giftcard-reservation/:giftcode" render={() => <GiftcardReservations giftcardReservation={true} />} />
                                        <Route exact path="/giftcard-reservation" render={() => <GiftcardReservations giftcardReservation={true} />} />
                                        <Route exact path="/phone" render={() => <PhoneReservations phone={true} groupon={false}  />} />
                                        <Route path="/giftCard" component={GiftCard} />
                                        <Route path="/giftshop" component={ShopComponent} />
                                        <Route path="/gallery" component={GalleryComponent} />
                                        <Route path="/checkout" render={() => <CheckOutComponent />}/>
                                        <Route path="/checkCart" component={CheckCartComponent} />
                                        <Route path="/loadcall" component={LoadCallPage} />
                                        <Route path="/change_reservation/:id" component={ChangeReservation} />
                                    </div>
                                </React.Suspense>

                            </Router>
                        </div>

                    </div> : <div>config loading</div>}
            </React.Fragment>
        );
    }
}

export default App;
