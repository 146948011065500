import { useEffect, useState } from "react"
import { getAllConditions, handleOnChange} from "./utils"
import './TermsConditions_index.css'


let isDisable;

const TermsConditionsEm = ({allConditions,setState, pSetState}) => {

    useEffect(() => {
        if(!window.ENV.isTermsConditionsCheckbox) {
            pSetState({isAcceptDisable:false})
        }
    }, [])

   
    return (
        <div className="render_conditions_em_main">
            {allConditions?.map((condition,index)=>{
                return(
                    <div className="render_condition" key={index}>
                        { 
                            window.ENV.isTermsConditionsCheckbox && 
                            <span>
                                <input type="checkbox" id={index} checked={condition.isChecked} 
                                    onChange={()=>handleOnChange(index,allConditions,setState)}
                                />
                            </span>
                        }
                        <span>{index+1}.</span>
                        <span className="condition-description">{condition.description}</span>
                    </div>
                )
            })
            }
        </div>
    )
}

export default TermsConditionsEm;