






export const full_pay_aff = (element,state) => {

    console.log(state,"<========state")
    element.totalPaid =state.affValue/state.ticketCount;
    // element.totalTandemPaid=elementSelectedTandemPackPrice;
    // tp[0].isTaxable ?
    //   element.totalTandemTaxPaid=((taxRate / 100) * element?.totalTandemPaid)
    // : element.totalTandemTaxPaid=0;
    // element.totalVideoPaid=video_price;
    // vp[0]?.isTaxable ?
    // element.totalVideoTaxPaid=((taxRate / 100) * element.totalVideoPaid)
    // :0;
    element.totalTaxPaid = (state.totalTax / state.ticketCount) || 0;
    return element;
}