


export const giftCard_Res = (element,vp,taxRate,state) => {


    console.log(state,"<============state =========")
    element.type = 'gift';
    element.tandemPackage = state.giftPackage.tandemPackageName;
    
    if (state.giftPackage.videoPackageName) {
      element.video = state.giftPackage.videoPackageName;
    }

    element.totalTandemPaid = 0;
    element.totalTandemTaxPaid = 0;
 

    if(vp.length > 0){
        element.totalVideoPaid = vp[0].videoPrice;
        element.totalVideoTaxPaid= vp[0]?.isTaxable ? ((taxRate / 100) * element.totalVideoPaid) : 0;
    }else{
        element.totalVideoPaid = 0;
        element.totalVideoTaxPaid = 0;
    }




    element.totalPaid =element.totalTandemPaid+element.totalVideoPaid
    element.totalTaxPaid = element.totalTandemTaxPaid+element.totalVideoTaxPaid;
    return element;
}