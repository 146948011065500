import { CalculatePrice } from "./calculatePrice";

export const updateTicketsInfoGrouponRes = (state,setState,newTicketSize,getPrice,props) => {
    const passengersInFinalForm  = (state?.finalForm?.passengers?.length) - 1;
    for(let i = passengersInFinalForm;  i >= newTicketSize; i--){
        let deleteVideoPack = state.finalForm.passengers[state.finalForm.passengers.length - 1].video;
        state.finalForm.passengers.pop();
        let updatedSelectedVideoPackages = state.selectedVideoPackages.filter(pack => {
            if (pack.name === deleteVideoPack) {
              let singlePackPrice = pack.price / pack.count;
              pack.count = pack.count - 1;
              pack.price = pack.price - singlePackPrice;
              if (pack.count === 0) {
                return
              }
            }
            return pack;
          })
          setState({ selectedVideoPackages: updatedSelectedVideoPackages },()=>{
            const priceObj = CalculatePrice(state,getPrice,props)
            setState({ ...priceObj })
          })
    }

    return;
}