import tandemStore from '../../stores/store'

export const calculateSalesTax = (state,props) => {
    console.log(state,"<=======tax state")
    let { payType, selectedTandemPackages, selectedVideoPackages, dicountedTandemPackage, EventName, namedEvent } = state;
    let TotalTax = 0;
    if(payType === "nopay") return TotalTax;
    
    if (payType !== "deposit" || selectedVideoPackages.length > 0 || 
        !!dicountedTandemPackage && tandemStore.isTaxEnabled())
    {
        let dp = [];
        let tandemPackages = tandemStore.getTandemPackages();
        let taxRate = tandemStore.getTaxRate();
        if(!!dicountedTandemPackage){
            dp = tandemPackages.filter(ele => ele.item ===  dicountedTandemPackage.item);
        }
        if (dp.length > 0 && dp[0].isTaxable) {
            TotalTax = (taxRate / 100) * dicountedTandemPackage.price;
        }


        if(selectedTandemPackages.length>0 && payType !== "deposit" &&  payType!=="giftCard"){
            selectedTandemPackages?.map(tandemPack=>{
                if(tandemPack.isTaxble){
                    TotalTax=state.isWeekEnd ?  TotalTax+((taxRate/100)*(tandemPack.pkgWeekendprice)) : TotalTax+((taxRate/100)*(tandemPack.pkgActualPrice))
                }
            })
        }
        if(selectedVideoPackages.length>0 && payType!=="giftCard"){
            selectedVideoPackages?.map(videoPack=>{
                if(videoPack?.isTaxble){
                    TotalTax=TotalTax+((taxRate/100)*(videoPack?.price))
                }
            })
        }

        if(state.tandemCoupon && payType === "tandemCoupon"){
            if(state.couponedTandemPack.isTaxable){
                let totalprice = state.isWeekEnd ? (state.couponedTandemPack.weekendPrice*state.ticketCount):
                                state.couponedTandemPack.price*state.ticketCount

                TotalTax = ((taxRate/100)*totalprice)
            }
        }


        if((props.location.pathname === "/groupon" || props.location.pathname === "/rush49") && state.grouponPackage !== null && state?.selectedGroupOnPack?.isTaxable){
            TotalTax += ((taxRate/100)*(state.groupOnPackPrice*state.grouponPackage.numTickets))
        } 

        if(EventName === "AFF") {
            const studentPackages = tandemStore.getStudentPackages();
            const selectedStudentPack = studentPackages.filter(pkg => pkg.calenderEvent === namedEvent);
            let AffFee = 0;
            if(selectedStudentPack.length > 0 && selectedStudentPack[0].price && selectedStudentPack[0].isTaxable)
                AffFee = selectedStudentPack[0].price;
            let totalPrice = state.ticketCount * AffFee;
            TotalTax += ((taxRate/100)*totalPrice);
        }

       
    }
    return TotalTax;
}
        
