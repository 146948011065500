import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import '../../components/CommonComp/Terms_conditions/TermsConditions_index.css'
import TermsConditionsEm from './Terms_conditions/TermsConditions_embedded_index';
const TagManager = require('react-gtm-module');


let isDisable;

class TermsAndConditionsEmbedded extends Component {

    state={
        isAcceptDisable:true
    }

    componentDidMount() {
        let {allConditions} = this.props;
        isDisable = allConditions?.every((condition)=>condition.isChecked===true);
        isDisable = false;
        this?.setState({isAcceptDisable:!isDisable})
    }
    
    componentWillReceiveProps(nextProps) {
        let {allConditions} = nextProps;
        isDisable = allConditions?.every((condition)=>condition.isChecked===true);
        isDisable = false;
        this?.setState({isAcceptDisable:!isDisable})
    }

    handleSubmit = (isAccept) => {
        this.props.handleTermsAndCondition(isAccept);
        if (window.ENV.gtmId && isAccept) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'tandcAccept',
                data: window.ENV.gtmId
              }
            })
          }
    }

    render() {
        return (
            <div className="em-terms-conditions-main">
                {!this?.props?.allConditions?.length ? 
                <>
                <p><b>Please Accept Terms & Conditions</b></p>
                <div className="em-terms-conditions" dangerouslySetInnerHTML={{ __html: window.ENV.TermAndCondition.Content }} />
                </>
                :
                <>
                <p><b>Please Accept All Terms & Conditions</b></p>
                <TermsConditionsEm allConditions={this.props.allConditions}
                                setState={this.props.setState}
                                pSetState= {this.setState.bind(this)}
                /> 
                </>}
                <button
                    className="btn btn-primary-em btn-lg right accept_terms_conditions_btn_em"
                    disabled={this.props.TandC}
                    onClick={() => this.handleSubmit(true)}
                >
                    {this.props.TandC ? <div>Terms & Conditions Accepted <i className="fa fa-check check-icon" aria-hidden="true"></i></div> : "Accept Terms & Conditions"}
                </button>
            </div>
        )
    }
}
export default withRouter(TermsAndConditionsEmbedded)