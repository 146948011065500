export const getTransactionDetails = (state,setState,r,customerRes) => {

    let {giftPackage, grouponPackage, totalAmount, checkoutId} = state;
    let transactionId,amount,maskedCardNum;

    if (state.payType === "nopay" && state.phone) {
        // no payment
    }
    else if (giftPackage !== null && totalAmount === 0){
        transactionId = 'GiftCardTransaction';
        amount = totalAmount;
        setState({
            submitted: true
        })
    }
    else if (grouponPackage !== null && totalAmount === 0) {
        transactionId = 'GrouponTransaction';
        amount = totalAmount;
        setState({
            submitted: true
        })
    }
    else if (window.ENV.processorInfo.processor == 'nab' && r && r.Status === "Successful" && checkoutId !== r.TransactionId) { //nab
        transactionId = r.TransactionId;
        amount = Number(r.Amount);
        maskedCardNum = r.MaskedPAN;
        setState({
            submitted: true
        })
    }
    else if (window.ENV.processorInfo.processor == 'transnational' && r && r.status === "success") { //transnational
        transactionId = r.data.id;
        amount = r.data.amount / 100;
        maskedCardNum = r.data.response_body.card.masked_card;
        setState({
            submitted: true
        });
    }
    else if (window.ENV.processorInfo.processor == 'cardConnect') {
        transactionId = r.retref;
        amount = Number(r.amount);
        setState({
            submitted: true
        });
    }

    if (r == null && customerRes == null) {
        transactionId = "ZeroTransaction";
        amount = 0;
        setState({
            submitted: true
        });
    }




    return {transactionId,amount,maskedCardNum}
}