import tandemStore from '../../stores/store';
import { calculateSalesTax } from './calculateSalesTax';


export const CalculatePrice = (state, getPrice, props) => {
  let { ticketCount, selectedTandemPackages, selectedVideoPackages,
    payType, EventName, deposit, namedEvent, tandemEventDepositAmount } = state;

  //alert(payType);
  var totalPrice = 0;
  var affValue = 0;
  var priceSchedule = tandemStore.priceSchedule;
  var scheduleFee = getPrice(priceSchedule, "schedule fee");
  if (payType === "deposit") {
    selectedTandemPackages.forEach(pkg => totalPrice += pkg.count * state.tandemEventDepositAmount);
  } else if (payType === "full") {
    state.isWeekEnd ?
      selectedTandemPackages.forEach(pkg => totalPrice += pkg.pkgWeekendprice)
      : selectedTandemPackages.forEach(pkg => totalPrice += pkg.pkgActualPrice);
  } else if (payType === "tandemCoupon") {
    totalPrice = state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) * state.ticketCount :
      (state.couponedTandemPack.price) * state.ticketCount;
  }

  if (payType !== "giftCard" && payType !== "tandemCoupon")
    selectedVideoPackages.forEach(pkg => totalPrice += pkg.price);

  var AffFee = 0;
  if (EventName === "AFF") {
    const studentPackages = tandemStore.getStudentPackages();
    const selectedStudentPack = studentPackages.filter(pkg => pkg.calenderEvent === namedEvent);
    if (selectedStudentPack.length > 0 && selectedStudentPack[0].price)
      AffFee = selectedStudentPack[0].price;
  }

  if (AffFee === 0 || !AffFee) {
    AffFee = tandemStore.getFirstJump().price;
  }
  let affFacebookTrackVal = 0
  if (EventName === "AFF") {
    if (state.giftPackage !== null) {
      affValue = 0;
      totalPrice = 0;
    }
    else {
      if (!deposit) {
        totalPrice = AffFee * ticketCount;
        affFacebookTrackVal = totalPrice
      }
      else {
        totalPrice = state.tandemEventDepositAmount * ticketCount;
        affFacebookTrackVal = AffFee * ticketCount
      }
      affValue = totalPrice;
    }
  }
  else if (props.location.pathname === "/phone") {
    if (payType === 'full') {
      totalPrice = totalPrice * ticketCount
      affFacebookTrackVal = AffFee * ticketCount
    }
    else if (payType === 'deposit' && selectedTandemPackages.length > 0) {
      totalPrice = state.tandemEventDepositAmount * ticketCount;
      affFacebookTrackVal = AffFee * ticketCount
    }
  }

  if (props.location.pathname === "/groupon" || props.location.pathname === "/rush49") {
    totalPrice += state.groupOnPackPrice * state.grouponPackage.numTickets
  }

  var taxPerc = parseInt(scheduleFee);
  var totalFees = 0;
  let processingFees = 0;
  var taxValue = totalPrice;

  if (props.location.pathname !== "/phone" && payType !== "tandemCoupon") {
    //alert('in here');
    totalFees = (taxPerc / 100) * taxValue;
    if (isNaN(totalFees)) {
      totalFees = 0;
    }
    totalPrice = totalPrice + totalFees;
  }

  if (props.location.pathname === "/phone" && window.ENV.processorInfo?.phoneCardFees) {
    //alert('in here');

    processingFees = (window.ENV.processorInfo?.phoneCardFees / 100) * taxValue;
    if (isNaN(processingFees) || payType === "nopay") {
      processingFees = 0;
    }
    totalPrice = totalPrice + processingFees;
    //alert('computing processing fees '+processingFees);

  }

  let totalTax = calculateSalesTax(state, props);

  totalPrice = totalPrice + totalTax;

  if (payType === "nopay" && props.location.pathname === "/phone") {
    totalPrice = 0;
    processingFees = 0;
  }
  return {
    totalAmount: totalPrice,
    processingFees,
    totalFees,
    totalTax,
    affValue,
    affFacebookTrackVal,
    taxPerc
  }
}