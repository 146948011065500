


export const setCustomerIntialDetails = (element) => {
    element.firstname = element.firstname.trim();
    element.lastname = element.lastname.trim();
    element.email = element.email.trim();
    element.firstname = element.firstname[0].toUpperCase() + element.firstname.substr(1).toLowerCase();
    element.lastname = element.lastname[0].toUpperCase() + element.lastname.substr(1).toLowerCase();
    element.email = element.email.toLowerCase();
    return element ; 
}