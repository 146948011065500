export const makeid = (length) => {
    var result = '';
    var characters = 'abcdefghijklmnopqr0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    const str = result + (new Date().getTime())
    return str
  }