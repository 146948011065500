
import './ChangeReservation.css'
import tandemStore from '../../stores/store'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import Calendar from 'react-calendar'
import moment from 'moment'
import Api from '../../services/api';
import DateSelector from '../dateselector'
import swal from 'sweetalert'
import { Show_Loader } from '../Loader/ShowLoader'


const ChangeReservation = () => {

    let {id} = useParams();
    const [reservation,setReservation] = useState({});
    const [timeslots,setTimeslots] = useState([]);
    const [currentMonthDate,setCurrentMonthDate] = useState(new Date());
    const [allowedDates,setAllowedDates] = useState([]);
    const [selectedDate,setSelectedDate] = useState();
    const [WeekEnd,setWeekEnd] = useState("");
    const [selectedTimeslotTiming,setSelectedTimeslotTiming] = useState([]);
    const [oldtimeSlot,setOldTimeSlot] = useState({});
    const [curSelectedTimeslot,setCurSelectedTimeslot] = useState({});
    const [enablebutton,setEnableButton] = useState(true);

    useEffect(()=>{
        getReservation();
    },[])


    async function getReservation(){
        const reservationDetails = await tandemStore.getReservationDetails(id);
        const wdate = new Date(reservationDetails.datetime);
        const weekEnd = (wdate.getDay() % 6 === 0);
        const timeSlots = await  tandemStore.getTimeSlotsByEventName(reservationDetails.selectedEvent);
        const selected_timeslot = timeSlots.filter(timeslot => timeslot.datetime === reservationDetails.datetime)
        setOldTimeSlot(selected_timeslot[0]);
        console.log(selected_timeslot[0],"<======selected_timeslot[0]")
        setWeekEnd(weekEnd)
        setReservation(reservationDetails)
        setTimeslots(timeSlots);
        setSelectedDate(new Date(reservationDetails.datetime))
    }

    useEffect(()=>{
        const d = new moment();
        const startDate = d.clone().startOf("day").format("YYYY-MM-DD");
        const allowed_dates = timeslots?.filter(x => x.isBlackedOut === false && x.datetime.split('T')[0] != startDate).map(elem => elem.datetime.split('T')[0]);
        setAllowedDates(allowed_dates);
    },[timeslots])




    const handleChangeSlot = async () => {
        if(curSelectedTimeslot._id === oldtimeSlot._id || curSelectedTimeslot.seatsRemaining < reservation.ticketCount){
            swal({
                title: "Warning!",
                text: "Please select another date",
                icon: "error",
                dangerMode: false,
            })
            return;
        }

        var reservationBody = {
            newslotId: curSelectedTimeslot._id,
            oldslotId: oldtimeSlot._id,
            id: reservation.timeSlotReservationId,
            newDate:curSelectedTimeslot.datetime,
            reservationId:reservation._id
        }

        const response = await tandemStore.changeReservation(reservationBody);
        if(response){
            swal({
                title: "Success",
                text: "Reservation date has been successfully changed",
                icon: "success",
                dangerMode: false,
            })
            setSelectedTimeslotTiming([]);
            setCurSelectedTimeslot({});
            getReservation();
            setEnableButton(true);
        }else{
            swal({
                title: "Warning!",
                text: "Please try again, Something wrong happened",
                icon: "error",
                dangerMode: false,
            })
        }
    }
    
    const onCalendarChange = async (date) => {
        setSelectedDate(date);
        const wdate = new Date(date);
        const weekEnd = (wdate.getDay() % 6 === 0);
        setWeekEnd(weekEnd);
        const theDate = new Date(date.getTime());
        const year = theDate.getFullYear();
        const month = theDate.getMonth() + 1;
        const _date = theDate.getDate();
        const formattedDate = ("0" + _date).slice(-2);
        const formattedMonth = ("0" + month).slice(-2);
        const parsedDate = year + '-' + formattedMonth + '-' + formattedDate;
        const time_slots = await Api.GetTimeslots(parsedDate);
        const filteredTimeslots = time_slots.data.filter(timeslot=> timeslot.isBlackedOut===false && timeslot.eventName === reservation.selectedEvent );
        filteredTimeslots.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
        setSelectedTimeslotTiming(filteredTimeslots);
        setCurSelectedTimeslot({});
        setEnableButton(true);
        window.scrollBy(0, 100);
    }

    const handleTimingClick = (slot,eventName) => {
        setCurSelectedTimeslot(slot);
        setEnableButton(false)
    }

    const checkDateAvailability = ({date}) => {
        let d = new moment(date);
        return (allowedDates.indexOf(d.format("YYYY-MM-DD")) == -1)
    }

    return(
        <div id="reservationForm" className="change_res_primary" style={{minHeight:window.innerHeight}}>
            <div className="change_res_sec" style={{ marginBottom: 50 }}>
                <img style={{width: window.ENV.logoWidth, height: window.ENV.logoHeight}} src={window.ENV.logoUrl} className="logo"/>
                <h4 className="text-center text-heading heading">SCHEDULE YOUR JUMP</h4>
                {!reservation ?  <Show_Loader/> :
                <>
                <div className="change_date">
                    <div className="reservation_details">
                        <p>Reservation Details</p>
                        <div className="reservation_details_sec">
                        {
                            reservation.tandemStudents?.map( (jumper,index) => (
                                <div key={jumper._id} className="reservation_details_sec_row"><div>{`Jumper Name`}</div><div>{jumper.firstname}&nbsp;{jumper.lastname}</div></div>
                            ))
                        }
                        <div className="reservation_details_sec_row"><div>Reserved Date</div><div>{reservation?.datetime?.slice(0,10)}</div></div>
                        <div className="reservation_details_sec_row"><div>Created On Date</div><div>{reservation?.createdAt?.slice(0,10)}</div></div>
                        <div className="reservation_details_sec_row"><div>Updated On Date</div><div>{reservation?.updatedAt?.slice(0,10)}</div></div>
                        <div className="reservation_details_sec_row"><div>Timing</div><div>{moment(reservation?.datetime)?.tz(oldtimeSlot?.timeZone)?.format("h:mm A")}</div></div>
                        {reservation?.tandemStudents?.length>0 && <div className="reservation_details_sec_row"><div>Phone</div><div>{reservation?.tandemStudents[0].phone}</div></div>}
                        <div className="reservation_details_sec_row"><div>Total Tickets</div><div>{reservation?.ticketCount}</div></div>
                        </div>
                    </div>
                    <div className="calendar">
                        <p>Select Date From Calendar</p>
                        <Calendar
                            minDate={new Date()}
                            onChange={onCalendarChange}
                            value={selectedDate}
                            activeStartDate={currentMonthDate}
                            minDetail="month"
                            // onActiveDateChange={updateAvailableDatesSlots}
                            tileDisabled={checkDateAvailability}
                        />
                    </div>
                </div>
                { selectedTimeslotTiming.length > 0 &&
                <div className="slots_timing">
                    <span className="date_title">Select Time For Date {moment(selectedDate).format('DD-MM-YYYY')}</span>
                    {
                        selectedTimeslotTiming?.map(slot=>(
                            <div key={slot._id} className="timing_div">
                                <DateSelector
                                    selected={true}
                                    handleClick={handleTimingClick}
                                    slot={slot}
                                    changeDate={true}
                                />
                            </div>
                        ))
                    }
                    <button onClick={handleChangeSlot} disabled={enablebutton} className="change_date_btn" style={enablebutton ? {background:"lightgray"} : {}}>Change Date</button>
                </div>
                }
               </>}
            </div>
        </div>
    )
}

export default ChangeReservation;