import React, { Component } from 'react';
import PassengerDetail from './passengerDetail';
import tandemStore from '../stores/store'

class PassengerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: this.props.phone
        };
        
    }
    // handleVideoCount = (val) =>
    // {
    //     this.handleVideoUpdate(val);
    // }

    renderPassenger = () => {
    	//alert("phone: " + this.state.phone);
        var passengerFormList = [];
        if (this.props.passengerCount) {
            
            var count = this.props.passengerCount;
            if(this.state.phone){
            	count = 1;
            }

	        for (let i = 0; i < count; i++) {
	                passengerFormList.push(
	                    <PassengerDetail
                            couponedTandemPack={this.props.couponedTandemPack}
	                        ticketDetails={this.props.ticketDetails}
                            handleVideoUpdate={(val, e, vidPkgArr) => this.props.handleVideoUpdate(val, e, vidPkgArr)}
                            handleTandemUpdate={(val, e, tanPkgArr,noVideo,video) => this?.props?.handleTandemUpdate(val, e, tanPkgArr,noVideo,video)}
	                        //handleValueUpdate={this.handleValueUpdate}
	                        index={i + 1}
                            trueIndex={i}
                            eventFilter={this.props.eventFilter}
	                        chkFormVal={this.props.chkFormVal}
	                    	phone = {this.props.phone}
	                    	type = {this.props.type}
	                        eventName={this.props.eventName}
	                        handleFieldChange={this.props.handleFieldChange}
	                        studentDetail={this.props.studentDetails[i]}
                            payType={this.props.payType}
                            selectedTandemPackages={this.props.selectedTandemPackages}
                            validateTandemPack={this.props.validateTandemPack}
                            tandemCoupon={this.props.tandemCoupons}
                            ticketCount={this.props.ticketCount}
                            passengerDetails = {tandemStore.getAll().finalForm.passengers[i]}
	                    />
	                );
	            }
        }
        // console.log("🚀 ~ file: passengerForm.js ~ line 48 ~ PassengerForm ~ passengerFormList", passengerFormList)

	    return passengerFormList;
    };

    render() {
        return (
            <div className="col-md-12 containerPadding">
                {this.props.passengerCount > 0 && <span className="customHeading">JUMPER INFORMATION</span>}
                <div className="row ticketContainer">{this.renderPassenger().map((item) => item)}</div>
            </div>
        );
    }
}

export default PassengerForm;
