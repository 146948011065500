import { EventEmitter } from 'events';
import api from '../services/api';
var ObjectID = require('bson').ObjectID;
import { handleReservation } from '../components/Methods/handleReservation/handleReservation';


// https://www.youtube.com/watch?v=bvEC6i7CUyE
class TandemData extends EventEmitter {
    constructor() {
        super();
        this.token = '';
        this.serviceID = '';
        this.applicationProfileId = '';
        this.serviceId = '';
        this.merchantProfileId = '';
        this.paySchedule = [];
        this.priceSchedule = [];
        this.tandemPackages = [];
        this.allTandemPackages = [];
        this.allStudentPackages = [];
        this.videoPackages = [];
        this.settings = [];
        this.studentPackages = [],
        this.events = [];
        this.enableTax;
        this.taxRate;
        this.formData = {
            finalForm: {
                timeslotId: null,
                passengers: []
            }
        };
        this.reservationId = '';
    }

    getTokenVal = () => {
        return this.token;
    }

    setTokenVal = (val) => {
        this.token = val;
    }

    setProcessorVals = (values) => {
        this.serviceID = values.ServiceID;
        this.applicationProfileId = values.applicationProfileId;
        this.merchantProfileId = values.merchantProfileId;
    }

    getServiceId = () => {
        return this.serviceID;
    }
    getApplicationProfileId = () => {
        return this.applicationProfileId;
    }
    getMerchantProfileId = () => {
        return this.merchantProfileId;
    }
    clearAll = () => {

        this.formData = {
            finalForm: {
                timeslotId: null,
                passengers: []
            }
        };
    }

    getAll = () => {
        return this.formData;
    }

    updateFormWithIndex = (formData, index) => {
        //alert(JSON.stringify(this.formData.finalForm.passengers[index], null,2))
        this.formData.finalForm.passengers[index] = formData;
        this.emit('childFormUpdate', this.formData.finalForm.passengers);
    }

    updateFinalForm = (data) => {
        data.passengers || (data.passengers = []) // setting default
        this.formData.finalForm = data;
    }

    getFormData(index) {
        return this.formData.finalForm.passengers[index] || null;
    }


    getReservationDetails = (payload) => {
        return new Promise((resolve, reject) => {
            api.getReservationDetails(payload).then((r) => {
                if (r.status == 200) {
                    resolve(r.data.reservation)
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    changeReservation = (payload) => {
        return new Promise((resolve, reject) => {
            api.changeReservation(payload).then((r) => {
                if (r.status == 200) {
                    console.log(r,"<====response=====")
                    resolve(r.status)
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    getTimeSlotsByEventName = (payload) => {
        return new Promise((resolve, reject) => {
            api.getTimeSlotsByEventName(payload).then((r) => {
                if (r.status == 200) {
                    resolve(r.data.timeslots)
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    // MakeReservation = (payload,transactionId) => {
    MakeReservation = async (payload) => {
            
        // let passangerBody = { timeslotId: payload.timeslotId, tandemStudents: payload.passengers, totalCharge: parseInt(payload.amount),totalFees: parseInt(payload.totalFees), numVideos: payload.numVideos,transactionId:transactionId,TransactionDateTime : new Date().toISOString()};
        payload.captchaAction = 'purchase2'
        payload.captchatoken = await window.genRecaptchaToken(payload.captchaAction);
        
        let passangerBody = {
            timeslotId: payload.slotId,
            students: payload.passengers,
            eventName: payload.eventName,
            namedEvent:payload.namedEvent,
            totalCharge: payload.amount ? parseFloat(payload.amount) : 0,
            totalFees: parseFloat(payload.totalFees),
            processingFees: parseFloat(payload.processingFees),
            totalTax: parseFloat(payload.totalTax),
            numVideos: parseFloat(payload.videoCount),
            transactionId: payload.transactionId,
            TransactionDateTime: new Date().toISOString(),
            discount: payload.discount,
            discountValue: payload.discountValue | 0,
            jumpsPrice: +payload.jumpsPrice,
            videoCost: payload.videoCost,
            maskedCardNum: payload.maskedCardNum,
            invoice: payload.invoice,
            site: payload.site,
            grouponCode: payload.grouponCode,
            grouponPackageName: payload.grouponPackageName,
            giftCode: payload.giftCode,
            giftPackageName: payload.giftPackageName,
            giftCardCode: payload.giftCardCode || '',
            method: payload.method,
            ticketCount: payload.ticketCount,
            transactionError: payload.transactionError ? payload.transactionError : null,
            memo: payload.memo,
            calender_id: payload.calender_id,
            conditions: payload.conditions,
            emailBody : payload.emailBody,
            transactionId: payload.transactionId,
            fromIncompleteReservationEmail : payload.fromIncompleteReservationEmail,
            captchatoken: payload.captchatoken,
            captchaAction: payload.captchaAction
        };

        return new Promise((resolve, reject) => {
            console.log("passangerBody==>", passangerBody)
            api.makeReservation(passangerBody).then((r) => {
                console.log("🚀 ~ file: store.js ~ line 107 ~ TandemData ~ api.makeReservation ~ r", r)
                if (r.status == 200) {
                    resolve(r.data.students)
                } else {
                    resolve(false);
                }
            })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    MakeAffReservation = (payload) => {
        alert('making aff reservation');
        // let passangerBody = { timeslotId: payload.timeslotId, tandemStudents: payload.passengers, totalCharge: parseInt(payload.amount),totalFees: parseInt(payload.totalFees), numVideos: payload.numVideos,transactionId:transactionId,TransactionDateTime : new Date().toISOString()};
        let passangerBody = {
            timeslotId: payload.slotId, students: payload.passengers, eventName: payload.eventName,
            totalCharge: parseInt(payload.amount), totalFees: parseInt(payload.totalFees),
            numVideos: parseInt(payload.videoCount), transactionId: payload.transactionId, invoice: payload.invoice, maskedCardNum: payload.maskedCardNum,
            TransactionDateTime: new Date().toISOString()
        };

        return new Promise((resolve, reject) => {
            api.makeAffReservation(passangerBody).then((r) => {
                if (r.status == 200) {

                } else {

                }
            })
                .catch((err) => {

                    reject(err);
                });
        });
    }

    saveParkedReservation = async (payload) => {
        payload.captchaAction = 'save'
        payload.captchatoken = await window.genRecaptchaToken(payload.captchaAction);
        
        if (this.reservationId == '') {
            var id = new ObjectID();
            payload.reservationId = this.reservationId = id.toString()
        }
        else {
            payload.reservationId = this.reservationId
        }
        return new Promise((resolve, reject) => {
            api.ceateOrUpdateReservation(payload)
                .then(result => {

                    if (result.status == 200) {
                        resolve(result.data);
                        this.reservationId = result.data.reservationId;
                    }
                    else {

                    }
                })
        })
    }
    deleteParkedReservation = () => {
        if (this.reservationId == '') {

        }
        else {
            return new Promise((resolve, reject) => {
                api.deleteSavedParking(this.reservationId)
                    .then(result => {

                        if (result.status == 200) {
                            this.reservationId = '';

                        }
                        else {

                        }
                    })

            })
        }
    }

    getParkedReservation = () => {
        
        if (this.reservationId == '') {

        }
        else {
            if (this.reservationId != '') {
                return new Promise((resolve, reject) => {
                    api.getSavedParking(this.reservationId)
                        .then(result => {
                            if (result.status == 200) {
                                if (result.data != null) {
                                    this.reservationId = result.data.reservationId;

                                    if (result.data.slotId != null) {
                                        resolve(result.data)
                                    }
                                    else {

                                        resolve(null)
                                    }
                                }
                                else {
                                    this.reservationId = ''
                                    resolve(null);
                                }
                            }
                            else {

                            }
                        })

                })
            }
            else {

            }

        }
    }

    CreateGiftCard = (payload) => {
        return new Promise((resolve, reject) => {
            api.createGiftCard(payload).then((r) => {
                if (r.status == 200) {

                } else {

                }
            })
                .catch((err) => {

                    reject(err);
                });
        });
    }


    isTaxEnabled = () => {
        if (this.enableTax === true) {
            return true;
        }
        else {
            return false
        }
    }
    getTaxRate = () => {
        if (!isNaN(this.taxRate)) {
            return Number(this.taxRate);
        }
        else {
            return 0;
        }
    }
    loadConfig = () => {
        console.log('in load reservations config');
        return new Promise((resolve, reject) => {
            api.loadConfig().then((r) => {
                if (r.status === 200) {
                    console.log(r.data);
					window.ENV= r.data.reservationsConfig;
                    window.loadScripts();
                    if(r.data.reservationsConfig.processorInfo.processor === "square"){
                        api.loadSquareLocationId()
                        .then((r)=>{
                            console.log('getting location Id');
                            console.log(r.data);
                            window.ENV.processorInfo.locationId = r.data;
                        })
                    }
					//api2.setApi();
                    resolve(true);
                }
            })
            .catch(err =>{
                console.log(err);
                reject(false);
            })
        })
    }
    loadSettings = () => {
        console.log('in load Details');
        return new Promise((resolve, reject) => {
            api.loadSettings().then((r) => {
                // console.log("🚀 ~ file: store.js ~ line 283 ~ TandemData ~ api.loadSettings ~ r", r)

                if (r.status === 200) {
                    //if there are no giftcards in backend local
                    // we use second api below
                    this.settings = r.data;
                    // console.log("🚀 ~ file: store.js ~ line 287 ~ TandemData ~ api.loadSettings ~ r.data", r.data)
                    var pst;
                    pst = this.settings.filter(st => st.name === "enable tax")[0];
                    if (pst !== undefined && pst.value !== undefined) {
                        this.enableTax = pst.value === 'yes' ? true : false;
                    }

                    pst = this.settings.filter(st => st.name === "tax rate (percentage)")[0];
                    if (pst !== undefined && pst.value !== undefined) {
                        this.taxRate = Number(pst.value);
                    }
                    resolve(true)
                }
                else {
                    resolve(false);
                }
            })

        })
    }

    loadDetails = () => {
        console.log('in load Details');
        return new Promise((resolve, reject) => {
            api.loadLocalDetails().then((r) => {
                console.log('after load Details');
                console.log(r.data)
                // console.log("🚀 ~ file: store.js ~ line 253 ~ TandemData ~ api.loadLocalDetails ~ r", r)
                if (r.status === 200 && r.data.giftcards && r.data.giftcards.length > 0) {
                    //if there are no giftcards in backend local
                    // we use second api below

                    resolve(true)
                }
                else {
                    resolve(false);
                }
            })

        })
    }

    getLoadcalls = () => {
        console.log('in load Details');
        return new Promise((resolve, reject) => {
            api.getLoadCalls().then((r) => {
                console.log('after load Details');
                if (r.status === 200) {
                    //if there are no giftcards in backend local
                    // we use second api below

                    resolve(r.data)
                }
                else {
                    resolve(false);
                }
                // console.log("🚀 ~ file: store.js ~ line 253 ~ TandemData ~ api.loadLocalDetails ~ r", r)
            })

        })
    }

    loadPrices = () => {
        console.log('about to load prices');
        return new Promise((resolve, reject) => {
            api.loadPrices().then((r) => {
                // console.log("🚀 ~ file: store.js ~ line 268 ~ TandemData ~ api.loadPrices ~ r", r)
                if (r.status == 200) {
                    var prices = r.data;
                    this.paySchedule = r.data.paySchedule;
                    this.priceSchedule = r.data.priceSchedule;
                    this.firstJump = r.data.studentPackages[0];

                    this.tandemPackages = r.data.tandemPackages.filter(pkg => pkg.isBookable);
                    this.allTandemPackages = r.data.tandemPackages;
                    this.videoPackages = r.data.videoPackages.filter(pkg => pkg.isBookable);
                    this.studentPackages = r.data.studentPackages.filter(pkg => pkg.isBookable);
                    this.allStudentPackages = r.data.studentPackages;

                    console.log('after load prices');
                    console.log(r.data);
                    //

                    //

                    //

                    //

                    resolve(prices);
                } else {
                    //   this.emitError({message:'could not load prices.'});
                }
            })
                .catch(err => {
                    // this.emitError({message:'Incomplete or Invalid data present'});
                    reject(err);
                });
        });
    }
    loadGiftCard = () => {
        return new Promise((resolve, reject) => {
            api.loadLocalDetails().then((r) => {
                if (r && r.data.giftcards && r.data.giftcards.length > 0) {
                    resolve(r.data.giftcards)
                }
            })
                .catch(err => {

                    resolve([])
                })

        })
    }

    loadGiftCards = () => {
        return new Promise((resolve, reject) => {
            api.loadGiftCards().then((r) => {
                if (r && r.data.giftcards) {
                    resolve(r.data.giftcards)
                }
            })
                .catch(err => {
                    resolve([])
                })

        })
    }

    VerifyGiftCard = (payload) => {
        return new Promise((resolve, reject) => {
            api.verifyGiftCard(payload).then((r) => {
                if (r.status == 200) {

                    resolve(r.data);
                } else {
                    resolve(false);

                }
            })
                .catch((err) => {

                    reject(err);
                });
        });
    }

    UpdateGiftCard = (payload) => {
        return new Promise((resolve, reject) => {
            api.UpdateGiftCard(payload).then((r) => {
                if (r.status == 200) {

                    resolve(true);
                } else {
                    resolve(false);

                }
            })
                .catch((err) => {

                    reject(err);
                });
        });
    }

    sendEmail = (body) => {

        return new Promise((resolve, reject) => {
            api.sendEmail(body).then((r) => {

                resolve(r);
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    sendErrorEmail = (body) => {

        return new Promise((resolve, reject) => {
            api.sendErrorEmail(body).then((r) => {
                resolve(r)
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    // sendEmail = (body) => {
    //
    //     return new Promise((resolve, reject) => {
    //         api.sendEmail(body).then((r) => {
    //
    //         })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // }
    checkCoupon = (body) => {

        return new Promise((resolve, reject) => {
            api.checkCouponAPi(body).then((r) => {

                resolve(r)
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    celeroTransaction = (body) => {
        return new Promise((resolve, reject) => {
            api.transaction(body).then((r) => {
                console.log(r);
                
                resolve(r)
            })
                .catch(err => {
                    reject(err);
                });
        });
    }



    saveCoupons = (body) => {

        return new Promise((resolve, reject) => {
            api.saveCoupons(body).then((r) => {

                resolve(r)
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getCoupons = () => {
        return new Promise((resolve, reject) => {
            api.getCoupons().then((r) => {

                resolve(r)
            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    sendTandCtoEmail = (body) => {

        return new Promise((resolve, reject) => {
            api.sendTandCtoEmail(body).then((r) => {

            })
                .catch(err => {
                    reject(err);
                });
        });
    }

    PaymentResponse = (r) => {

        alert("============>", "Payment Response");
    }

    getVideoPackages = () => {
        return this.videoPackages;
    }

    getFirstJump = () => {
        return this.firstJump;
    }

    getStudentPackages = () => {
        return this.studentPackages;
    }

    getTandemPackages = () => {
        return this.tandemPackages;
    }

    getAllTandemPackages = () => {
        return this.allTandemPackages;
    }

    getAllStudentPackages = () => {
        return this.allStudentPackages;
    }

    getAllCalendarEvents = () => {
        return this.events.data;
    }
    getEvents = () => {
        return new Promise((resolve, reject) => {
            api.getCalenderEvents().then(r => {
                if (r.status === 200) {
                    console.log("getCalenderEvents", r)
                    this.events = r.data;
                    resolve(this.events);
                }
            })
            // .catch(err => this.emitError("Deleting timeslot failed", err))
        })
    }
}
const tandemData = new TandemData();
window.tandemData = tandemData;
export default tandemData;
