import React, { Component, Fragment } from 'react';
import {
  Button, ButtonGroup
} from 'reactstrap';
import tandemData from '../stores/store'

class JumpTypeButton extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      eventList: [],
      calendarEventActiveInd : 0 
    
    }
  }

  componentDidMount = () => {
    tandemData.getEvents().then((res) => {       
      let lEventList = res.data;
      if (this.props.isGiftCard || this.props.isGroupOn) {
        lEventList = lEventList.filter(event => event.packageCategory !== "AFF");
      }
      lEventList = [{eventName:"All"},...lEventList];               
      this.setState({ eventList: lEventList });                       
    });                                       
  }                                                       

  onCalendarEventClick = (evt, index) => {
    this.setState({calendarEventActiveInd : index});          
    this.props.onFilterChange(evt.eventName, evt.packageCategory);
  }                 

  render() {                  
    return (
      <ButtonGroup>
        {
          this.state.eventList.map((evt, i) => (
            <Fragment key={i + 1}>
              <Button className= {`jumpbtns ${this.state.calendarEventActiveInd === i ? "calendar-event-active" : ""}`} onClick={(e) => { this.onCalendarEventClick(evt, i) }}>{evt.eventName}</Button>
            </Fragment>             
          ))                            
        }               
                    
      </ButtonGroup>
    )
  }
}
export default JumpTypeButton;
