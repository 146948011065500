


export const depoist_pay_tandem = (element,vp,taxRate,state) => {

    element.totalVideoPaid=window.ENV.dontApplyVideoCharge? 0 : vp[0]?.videoPrice || 0;
    element.totalPaid=state.tandemEventDepositAmount+element?.totalVideoPaid;
    element.totalTandemPaid = state.tandemEventDepositAmount;
    element.totalTandemTaxPaid=0;
    if(vp[0]?.isTaxable){
      element.totalVideoTaxPaid=((taxRate / 100) * element.totalVideoPaid);
    }else{
      element.totalVideoTaxPaid=0;
    }
    element.totalTaxPaid=element.totalTandemTaxPaid+element.totalVideoTaxPaid;
    element.totalPaid = state.tandemEventDepositAmount + element.totalVideoPaid;

    return element;
}