import { useEffect } from "react";

import store from '../../stores/store';
import { ApplePayButton } from "react-apple-pay-button";
import { newHandleReservation } from "../Methods/handleReservation/newHandleReservation";
const TagManager = require('react-gtm-module');

const ApplePay = ({ amount, onPaymentCompletion, state, setState, props, isGiftcard, handleFormData }) => {
    console.log('in apple pay');
    console.log(amount);
    console.log(window.ENV.processorInfo.applePayKey, "applePayKey");
    const settings = {
        key: window.ENV.processorInfo.applePayKey,

        domain: window.ENV.processorInfo.applePayDomain, //should be replaced with the environment you are looking to process in sandbox.<domain> or app.<domain>

        payment: {
            merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
            supportedNetworks: ["visa", "masterCard", "discover"],
            countryCode: "US",
            version: 3,
            merchantIdentifier: window.ENV.processorInfo.appleMerchantId
        },

        details: {
            total: {
                label: "Total Amount",
                amount: { currency: "USD", value: amount.toFixed(2).toString() }
            }
        },

        options: {
            requestShipping: false
        }
    }

    let ap;
    useEffect(() => {
        ap = new walletjs.ApplePay(settings);
    }, [])

    var submitApplePay = async () => {
        console.log('in submit apple pay');
        let isenablePurchase;
        if (!isGiftcard) {
            isenablePurchase = await handleFormData("googlePay");

        }
        let result;
        if (isenablePurchase || isGiftcard) {
            if (!isGiftcard) {
                result = await newHandleReservation(state, setState, props);
                if (window.ENV.gtmId) {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'beginCheckout',
                        data: window.ENV.gtmId
                      }
                    })
                  }
            } else {
                result = "giftcard"
            }
        }
        else{
            swal({
                title: "Warning!",
                text: "Please fill the required fields",
                icon: "error",
                dangerMode: false,
              })
              return;
        }
        ap = new walletjs.ApplePay(settings);
        console.log(ap, 'ap');
        var resp = ap.submit()
            .then(ret => {
                console.log(ret);
                if(ret.status !== "success"){
                    return;
                }
                var token = ret.token;
                console.log(token, "token");
                if (!isGiftcard) {
                    result.isApplePay = true;
                    state = {};

                }
                else {
                    state.isApplePay = true;
                }

                var body = {
                    "type": "sale",
                    "reservationData": result,
                    "data": state,
                    "amount": parseInt(amount * 100),
                    "payment_method": {
                        "apple_pay_token": {
                            "temporary_token": token
                        }
                    }
                };
                swal({
                    title: "Please Wait",
                    text: "Please Wait and check email in a few minutes, make sure to check your spam",
                    icon: "info",
                    dangerMode: false,
                })
                store.celeroTransaction(body)
                    .then(res => {

                        console.log('after celero transactoin');
                        console.log(res, "<=======res==");
                        if (res?.data?.data?.status === "pending_settlement") {
                            swal({
                                title: "Success",
                                text: `Congratulations for creating your skydiving reservation \n Please check your email (including spam and junk) for confirmation and waiver signing \n We look forward to seeing you soon`,
                                icon: "success",
                                dangerMode: false,
                            })
                            console.log('status is success');
                            res.data.isApplePay = true;
                            onPaymentCompletion(res.data, null);
                        }
                        else{
                            swal({
                                title: "Warning",
                                text: "Reservation failed please try again",
                                icon: "warning",
                                dangerMode: false,
                            })
                        }
                    })
            })
        console.log(resp);
        //console.log(resp);
    }
    return (
        <>
        {
           isGiftcard === true ?
            <button type="button" onClick={submitApplePay} className="pay_btn btn btn-primary btn-lg google_apple_styling"><span>Buy with</span><i class="fa fa-apple apple_button_icon" aria-hidden="true"></i><span>Pay</span></button>
           :
           <button type="button" onClick={submitApplePay} className="pay_btn btn btn-primary btn-lg google_apple_styling"><span>Book with</span><i class="fa fa-apple apple_button_icon" aria-hidden="true"></i><span>Pay</span></button>
        }
        </>

        // <button type="button" onClick={submitApplePay} className="pay_btn btn btn-primary btn-lg">Buy with Apple Pay</button>
       
    )

}


export default ApplePay;