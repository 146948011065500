import { makeid } from "./makeId";

export const createStuffJumpers=(tickets,finalForm,tandemPkgArrCopy)=>{ 
    for(let i = finalForm.passengers.length;i<tickets;i++){
        tandemPkgArrCopy.push(tandemPkgArrCopy[0]);
        finalForm.passengers.push({...finalForm.passengers[0]});
        finalForm.passengers[i].firstname="HOLD";
        finalForm.passengers[i].lastname=makeid(2)+"";
        finalForm.passengers[i].email=" ";
        finalForm.passengers[i].phone=" ";
    }
}