import moment from 'moment';
import tandemStore from '../../../stores/store'
import { createStuffJumpers } from '../createStuffJumpers';
import { makeid } from '../makeId';
import { depoist_pay_aff } from './depoist_pay_aff';
import { depoist_pay_tandem } from './depoist_pay_tandem';
import { full_pay_aff } from './full_pay_aff';
import { full_pay_depoist, full_pay_tandem } from './full_pay_tandem';
import { getPrice } from './getPrice';
import { getTransactionDetails } from './getTransactionDetails';
import { giftCard_Res } from './giftCardRes';
import { groupon_pack } from './groupon_pack';
import recompute_helper from './recomputer_helper';
import { setCustomerIntialDetails } from './setCustomerIntialDetails';
const TagManager = require('react-gtm-module');

export const handleReservation = async (state, setState, props, r, customerRes, Transactionerror) => {
  var { finalForm, totalAmount, isWeekEnd, discount, discountValue, phone, tandemPkgArr, vidPkgArr, affFacebookTrackVal, allConditions, giftCodeEmail, grouponCode, namedEvent } = state;
  console.log('in handle reservation');
  var payload = {};
  const vidPkgArrCopy = [...vidPkgArr]
  const tandemPkgArrCopy = [...tandemPkgArr]
  let { transactionId, amount, maskedCardNum } = getTransactionDetails(state, setState, r, customerRes);

  var priceSchedule = tandemStore.priceSchedule;
  var videoPackages = tandemStore.getVideoPackages();
  var tandemWeekend = state.loadPrices.tandemPackages[0].weekendPrice;
  var tandemWeekday = state.loadPrices.tandemPackages[0].price
  var tandemVideoCost = getPrice(priceSchedule, "video");
  var scheduleFee = getPrice(priceSchedule, "schedule fee");
  var AffFee = tandemStore.getFirstJump().price;
  var taxPerc = parseInt(scheduleFee);
  var tandemUnitCost = 0;


  if (state.EventName.trim() == 'Tandem') {
    tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
  }
  else if (state.EventName.trim() == 'AFF') {
    tandemUnitCost = parseInt(AffFee);
  }

  if (transactionId && amount >= 0 || state.grouponPackage !== null || state.manualGiftCard || state.giftPackage !== null) {
    let email = '';
    let name = '';
    let othername = '';
    let eventName = '';
    let ticketCount = '';
    let videoCount = '';
    let slotDates = '';
    let passangerBody = [];
    let recipients = [];
    let taxRate = tandemStore.getTaxRate();
    let videoPackages = tandemStore.getVideoPackages();
    let tandemPackages = tandemStore.getTandemPackages();

    if (props.location.pathname === "/phone" && state.ticketCount > 1) {
      createStuffJumpers(state.ticketCount, finalForm, tandemPkgArrCopy)
    }

    finalForm.passengers.forEach((element, indx) => {
      let tp = [];
      let vp = [];
      vp = videoPackages.filter(ele => ele.item === element.video)
      tp = tandemPackages.filter(ele => ele.item === element.tandemPackage)

      element = setCustomerIntialDetails(element);


      if (state.deposit && props.location.pathname !== '/groupon' && props.location.pathname !== '/rush49') {
        if (state.EventName.trim() == 'Tandem')
          element = depoist_pay_tandem(element, vp, taxRate, state);
        if (state.EventName.trim() == 'AFF')
          element = depoist_pay_aff(element, state);
      }


      if (state.payType === 'full' && props.location.pathname !== '/groupon' && props.location.pathname !== '/rush49') {
        if (state.EventName.trim() == 'Tandem')
          element = full_pay_tandem(element, vp, state, indx, tp, tandemPkgArrCopy, taxRate)
        if (state.EventName.trim() == 'AFF')
          element = full_pay_aff(element, state);
      }



      if (state.grouponPackage !== null && (props.location.pathname === '/rush49' || props.location.pathname === '/groupon')) {
        element = groupon_pack(element, vp, taxRate, state);
      }
      else if (state.giftPackage !== null) {
        element = giftCard_Res(element, vp, taxRate, state)
      }


      if (state.payType === "nopay" && props.location.pathname === '/phone') {
        element.totalPaid = 0
        element.totalTandemPaid = 0;
        element.totalVideoPaid = 0;
        element.totalTandemTaxPaid = 0;
        element.totalVideoTaxPaid = 0;
        element.totalTaxPaid = 0;
        if (state.EventName.trim() == 'AFF')
          element.totalPaid = 0;
      }


      if (state.payType === "tandemCoupon") {
        element.totalPaid = state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) : (state.couponedTandemPack.price);
        if (state.couponedTandemPack.isTaxable) {
          element.totalTaxPaid = ((taxRate / 100) * element.totalPaid);
          element.totalTandemTaxPaid = element.totalTaxPaid;
        } else {
          element.totalTaxPaid = 0;
          element.totalTandemTaxPaid = 0;
        }
        element.totalVideoPaid = 0;
        element.totalVideoTaxPaid = 0;
      }




      passangerBody.push(element);

      recipients.push({ firstname: element.firstname, lastname: element.lastname, email: element.email });
      if (email == '') {
        email = element.email;
        name = element.firstname + " " + element.lastname;
        amount = amount;
        eventName = state.EventName;
        ticketCount = state.ticketCount;
        videoCount = state.videoCount;
        slotDates = state.slotDates;
      }
      else {
        email = email + ' ,' + element.email;
        if (props.location.pathname !== "/phone")
          othername += `<p class="subText" style="font-family:'Opens Sans', sans-serif;margin: 0 auto; margin-top: 15px; text-align: center;font-size: 1.5em;text-align: center;padding-bottom: 0.2em"><b
              style="font-weight: 700; font-family: inherit;padding-bottom: 0.4em">Name: </b> ${element.firstname + " " + element.lastname}</p>`;
      }
    });

    passangerBody = recompute_helper(state, passangerBody, props);
    var date = state.slotDates.length > 0 ? new Date(state.slotDate) : new Date();

    var month = date.toLocaleString('en-us', { month: 'long' });

    var timeZone = state.slotDates[0].timeZone;
    console.log(state.slotDates[0]);
    console.log('timezone', timeZone);
    var totalAmountUpdate = 0
    if (amount != 0) {
      totalAmountUpdate = state.discount ? totalAmount - state.discountValue : totalAmount;
    }

    var Subject = "Inovice Report";
    var Message = "Your Reservation Has Been Successfully Made for " + moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A") + " and The Invoice generated is : " + totalAmountUpdate.toFixed(2) + ". ";
    var priceSchedule = tandemStore.priceSchedule;

    var tandemWeekend = state.loadPrices.tandemPackages[0].weekendPrice;
    var tandemWeekday = state.loadPrices.tandemPackages[0].price;
    var tandemVideoCost = getPrice(priceSchedule, "video");
    var scheduleFee = getPrice(priceSchedule, "schedule fee");
    var AffFee = tandemStore.getFirstJump().price;

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    var invoice = [];
    var invoiceMail = [];

    if (state.EventName === "AFF") {
      if (state.giftPackage !== null) {
        invoice.push({ item: state.giftPackage.studentPackageName, count: state.giftPackage.tickets, value: 0.00 })
      }
      else {
        invoice.push({ item: "AFF", count: state.ticketcount, value: state.affValue })
      }
    }

    if (state.grouponPackage !== null) {
      invoice.push({ item: state.grouponPackage.tandemPackageName, count: state.grouponPackage.numTickets, value: state.groupOnPackPrice * state.grouponPackage.numTickets })
    }

    if (state.giftPackage !== null && state.EventName !== "AFF") {
      invoice.push({ item: state.giftPackage.tandemPackageName, count: state.giftPackage.tickets, value: 0.00 })

      if (state.giftPackage.videoPackageName !== null && state.giftPackage.videoPackageName !== undefined && state.giftPackage.videoPackageName !== "none") {
        invoice.push({ item: state.giftPackage.videoPackageName, count: state.giftPackage.tickets, value: 0.00 })
      }
    }


    if (state.payType === 'full' && state.EventName !== "AFF") {
      state.selectedTandemPackages.forEach(pkg => {
        invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: phone ? state.isWeekEnd ? state.ticketCount * pkg.pkgWeekendprice : state.ticketCount * pkg.pkgActualPrice : state.isWeekEnd ? pkg.pkgWeekendprice : pkg.pkgActualPrice });
      });
    }

    if (state.deposit && state.EventName !== "AFF") {
      state.selectedTandemPackages.forEach(pkg => {
        invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: phone ? state.ticketCount * state.tandemEventDepositAmount : pkg.count * state.tandemEventDepositAmount });
      });
    }

    if (state.payType === 'nopay' && state.EventName !== "AFF") {
      state.selectedTandemPackages.forEach(pkg => {
        invoice.push({
          item: pkg.name,
          count: phone ? state.ticketCount : pkg.count,
          value: 0
        });
      });
    }

    if (state.payType === "tandemCoupon") {
      invoice.push({
        item: state.couponedTandemPack.item, count: state.ticketCount, value: state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) * state.ticketCount :
          (state.couponedTandemPack.price) * state.ticketCount
      })

      if (state.couponedTandemPack.videoType !== "none") {
        invoice.push({ item: state.couponedTandemPack.videoType, count: state.ticketCount, value: 0.00 })
      }
    }

    state.selectedVideoPackages.forEach(pkg => {
      invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: pkg.price });
    });

    if (Transactionerror) {
      if (state.EventName === "AFF") {
        invoiceMail.push({ item: "AFF", count: state.ticketCount, value: 0.00 })
      }

      if (state.grouponPackage !== null) {
        invoiceMail.push({ item: state.grouponPackage.tandemPackageName, count: state.grouponPackage.numTickets, value: state.groupOnPackPrice * state.grouponPackage.numTickets })
      }

      state.selectedTandemPackages.forEach(pkg => {
        invoiceMail.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: 0.00 });
      });

      state.selectedVideoPackages.forEach(pkg => {
        invoiceMail.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: 0.00 });
      });

      Message += ". You need to pay $" + parseFloat(totalAmount + state.totalFees).toFixed(2) + " on site.";
      var totalFees1 = 0.00
    }

    const totalAmountPaid = invoice.reduce((total, cur) => total + cur.value, 0);
    let selectedItems = [];

    invoice.forEach((item) => {
      const new_item = {
        item_name: item.item,
        price: item.value,
        quantity: item.count
      }
      selectedItems.push(new_item);
    })
    var emailBody = {
      email: email,
      phone: passangerBody[0].phone,
      recipients: recipients,
      name: name,
      othername: othername,
      subject: Subject,
      fees: Transactionerror ? totalFees1 : state.totalFees,
      processingFees: Transactionerror ? 0 : state.processingFees,
      amount: amount,
      eventName: eventName,
      ticketCount: ticketCount,
      videoCount: videoCount,
      message: Message,
      slotDates: slotDates,
      giftCodeEmail: giftCodeEmail,
      slotDateString: moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A"),
      invoice: Transactionerror ? invoiceMail : invoice,
      discountValue,
      discount
    };


    if (state.payType === "nopay" && state.phone) {
      // do nothing if nopay
    }
    else if (window.ENV.processorInfo.processor == 'transnational' && customerRes && customerRes.status === "success") {
      var MaskedPAN = customerRes.data.payment_method.card.masked_card;
      var CardType = customerRes.data.payment_method.card.card_type;
      var cardId = customerRes.data.payment_method.card.id;
      var customerId = customerRes.data.id
      var TransactionId = customerRes.data.id;
      var tokenBody = {
        cardNumLast4: MaskedPAN,
        cardType: CardType,
        cardId: cardId,
        customerId: customerId
      }
      passangerBody[0].cardOnFile = [];
      passangerBody[0].cardOnFile.push(tokenBody);
    }
    else if (window.ENV.processorInfo.processor == 'nab' && r && r.Status === "Successful") {
      var tokenBody = {
        cardNumLast4: r.MaskedPAN,
        cardType: r.CardType,
        token: r.PaymentAccountDataToken
      }
      passangerBody[0].cardOnFile = [];
      passangerBody[0].cardOnFile.push(tokenBody);
    }
    else if (window.ENV.processorInfo.processor == 'cardConnect' && customerRes) {
      var customerId = customerRes.profileid;
      var MaskedPAN = "xxxxxxxxxx" + r.account.slice(r.account.length - 4);
      var CardType = customerRes.accttype;
      var tokenBody = {
        cardNumLast4: MaskedPAN,
        cardType: CardType,
        customerId: customerId
      }
      passangerBody[0].cardOnFile = [];
      passangerBody[0].cardOnFile.push(tokenBody);
      passangerBody[0].zipcode = r.zipcode;
    } else {
      passangerBody = passangerBody
    }

    const calender_id = makeid(15)

    if (state.manualGiftCard)
      setState({ videoCost: 0 })



    payload = {
      calender_id,
      passengers: passangerBody,
      ticketCount: state.ticketCount,
      videoCount: state.videoCount,
      videoCost: state.videoCost || 0,
      jumpsPrice: state.deposit === true ? (state.tandemEventDepositAmount * state.ticketCount).toFixed(2) : (tandemUnitCost * state.ticketCount).toFixed(2),
      slotId: finalForm.timeslotId,
      deposit: state.deposit,
      amount: amount,
      totalFees: state.totalFees,
      processingFees: state.processingFees,
      transactionId: transactionId,
      eventName: state.EventName,
      maskedCardNum: maskedCardNum,
      fromIncompleteReservationEmail: state.fromIncompleteReservationEmail,
      transactionError: Transactionerror ? Transactionerror : null,
      invoice: invoice,
      discountValue,
      discount,
      conditions: allConditions,
      namedEvent: namedEvent
    };

    if (state.grouponPackage !== null) {

      payload.type = "groupon";
      payload.grouponCode = state.grouponCode;
      payload.grouponPackageName = state.grouponPackage.packageName;
      payload.ticketCount = state.grouponPackage.numTickets;
      ticketCount = state.grouponPackage.numTickets
    }

    if (state.giftPackage !== null) {
      console.log(state, "<========satte======")
      payload.type = "gift";
      payload.giftCode = state.giftPackage.couponCode;
      payload.giftPackageName = state.giftPackage.item;
      payload.ticketCount = state.giftPackage.tickets;
    }

    // if (state.manualGiftCard ) {
    //   payload.type = "giftCard";
    //   payload.giftCardCode = this?.state?.ticketDetails?.couponCode;
    // }

    if (props.location.pathname === "/phone") {
      payload.method = "phone";
      if (state.payType === "nopay") {
        payload.memo = state.memoMessage;
        payload.method = "phone (no pay)";
      }

    }
    else if (r?.isGooglePay) {
      payload.method = "online (google pay)";
    }
    else if (r?.isApplePay) {
      payload.method = "online (apple pay)";
    }
    else {
      payload.method = "online";
    }

    if (props.location.pathname === "/lakewales") {
      payload.site = "lakewales"
    }
    else if (props.location.pathname === "/plantcity") {
      payload.site = "plantcity"
    }

    var emailMaskedCardNum;
    if (maskedCardNum) {
      emailMaskedCardNum = maskedCardNum.substring(maskedCardNum.length - 5);
      if (r?.isGooglePay) {
        emailMaskedCardNum = emailMaskedCardNum + " (google pay)"
      }
    }

    var emailBody = {
      email: email,
      phone: passangerBody[0].phone,
      recipients: recipients,
      name: name,
      othername: othername,
      subject: Subject,
      fees: Transactionerror ? totalFees1 : state.totalFees,
      processingFees: Transactionerror ? 0 : state.processingFees,
      amount: amount,
      eventName: eventName,
      ticketCount: ticketCount,
      videoCount: videoCount,
      message: Message,
      slotDates: slotDates,
      slotDateString: moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A"),
      invoice: Transactionerror ? invoiceMail : invoice,
      discountValue,
      grouponCode: grouponCode,
      giftCodeEmail: giftCodeEmail,
      maskedCardNum: emailMaskedCardNum,
      changeReservation: false,
      tandemCouponCode: state.tandemCouponCode,
      changeReservation: false,
      discount
    };


    if (props.location.pathname === "/lakewales") {
      emailBody.site = "lakewales"
    }
    else if (props.location.pathname === "/plantcity") {
      emailBody.site = "plantcity"
    }
    if (tandemStore.isTaxEnabled()) {
      payload.totalTax = state.totalTax;
      emailBody.totalTax = state.totalTax;
    }


    if (Transactionerror) {
      let Message = Transactionerror;
      let type = 'Transaction'
      // if (!!tokenizerData) {
      //   type = 'Tokenizer';
      //   Message = JSON.stringify(tokenizerData)
      // };
      var date1 = new Date();
      var formatDate = moment(date1).tz(timeZone).format("DD/MMM/YYYY,  h:mm A")
      var Subject = "Reservation Error Report";

      var emailError = {
        name: passangerBody[0].firstname + " " + passangerBody[0].lastname,
        othername: othername,
        subject: Subject,
        amount: amount,
        eventName: eventName,
        message: Message,
        slotDates: slotDates,
        reservationDate: formatDate,
        email: passangerBody[0].email,
        totalAmount: parseFloat(totalAmount + state.totalFees).toFixed(2),
        phone: passangerBody[0].phone,
        type
      }
      tandemStore.sendErrorEmail(emailError)
        .then(res => {

        })

      if (window.ENV.gtmId) {
        var dataLayerBody = {
          event: 'failedPurchase',
          value: payload.amount - payload.totalFees - payload.totalTax,
          method: "credit card"
        }
        if (r?.isGooglePay) {
          dataLayerBody.method = "google pay"
        }
        else if (r?.isApplePay) {
          dataLayerBody.method = "apple pay"
        }
        TagManager.dataLayer({
          dataLayer: dataLayerBody
        })
      }

      swal({
        title: "Error",
        text: "Wrong Card details Please enter again",
        icon: "error",
        dangerMode: true,
      });

    } else {
      let totalPrice = 0
      if (state.EventName.trim() == 'AFF') {
        totalPrice = affFacebookTrackVal
      } else {
        const totalTandemPrice = tandemPkgArr.reduce((acc, cur) => !state.isWeekEnd ? acc + cur.price : acc + cur.weekendPrice, 0)
        const totalVideoPrice = vidPkgArr.reduce((acc, cur) => acc + cur.videoPrice, 0)
        totalPrice = totalTandemPrice + totalVideoPrice
      }
      payload.emailBody = emailBody;

      tandemStore.MakeReservation(payload)
        .then((res) => {
          tandemStore.clearAll();
          if (window.ENV.gtmId) {
            var dataLayerBody = {
              event: 'purchase',
              value: totalPrice || 0,
              paid: totalAmountPaid,
              currency: 'USD',
              items: selectedItems,
              transaction_id: transactionId,
            }
            if (r?.isGooglePay) {
              dataLayerBody.method = "google pay"
            }
            else if (r?.isApplePay) {
              dataLayerBody.method = "apple pay"
            }
            TagManager.dataLayer({
              dataLayer: dataLayerBody
            })
          }

          setState({
            ticketCount: 0,
            videoCount: 0,
            deposit: false,
            message: null,
            enablePurchase: false,
            hideMainForm: false,
            giftCard: false,
            cardCode: '',
            errorMessage: false,
            totalTax: 0,
            totalFees: 0,
            processingFees: 0,
            totalAmount: 0,
            slotDates: [],
            discount: undefined,
            discountValue: 0,
            checkoutId: transactionId,
            grouponPackage: null,
            giftPackage: null,
            payType: '',
            isClearGroupon: true,
            giftCodeEmail: undefined,
            tandemCoupon: false,
            tandemCouponFdata: false,
            selectedTandemPackages: [],
            selectedVideoPackages: [],
            couponedTandemPack: {},
            tandemCoupon: false,
            tandemCouponFdata: false,
            tandemCouponZeroValued: false,
            couponedTandemPack: {},
            finalForm: {}
          })
          swal({
            title: "Success",
            text: `Congratulations for creating your skydiving reservation\n Please check your email for confirmation and waiver signing \n We look forward to seeing you soon`,
            icon: "success",
            dangerMode: false,
          })
        })

    }
  }
}