import './squarePay.css';
import { create } from 'apisauce';
import { AfterPayment } from '../Methods/handleReservation/after_payment';
import { makeid } from '../Methods/makeId';
import tandemStore from '../../stores/store';
import '../reservation.css';
import { newHandleReservation } from '../Methods/handleReservation/newHandleReservation';
import { useEffect, useState } from 'react';
import api from '../../services/api';

let card = null;
let token = null;
let result;
let isGooglePay = false;
let isApplePay = false;
const SquareEmbeddedPay = ({ handleFormData, isGiftcard, renderTotal, state, setState, props, onPaymentCompletion, reservationComponentRef, isDisablePayBtn, totalPayAmount, termsAccepted }) => {

    let isenablePurchase, showTotalAmount, paymentResults;
    const [totalAmount, setTotalAmount] = useState(totalPayAmount);
    const [squareLocationId, setSquareLocationId] = useState();
    const [applePayEnabled, setApplePayEnabled] = useState(true);

    useEffect(() => {
        (async () => {
            //const squareLocationIdRes = await api.get(`${window.ENV.reservationApiUrl}//squareOauth/locationId`);
            //setSquareLocationId(squareLocationIdRes);
            //alert('initings square');
            await intializeSquareCard();
        })();
    }, [])



    useEffect(() => {
        if (!isGiftcard) {
            showTotalAmount = renderTotal(
                !!state.dicountedTandemPackage && ((state.taxPerc / 100) * state.dicountedTandemPackage.price * state.ticketCount)
            )
            setTotalAmount(showTotalAmount.props.children[1])
        } else {
            setTotalAmount(state.total);
        }
    })


    const initializeCard = async (payments) => {
        //alert('init card');
        const _card = await payments.card();
        let googlePay;
        let applePay;

        try {

            googlePay = await initializeGooglePay(payments);
            console.log("initializing googlepay", googlePay);
            if (googlePay !== undefined) {
                const googlePayButton = document.getElementById('google-pay-button');
                googlePayButton.addEventListener('click', async function (event) {
                    console.log('google pay click', event);
                    isGooglePay = true;
                    await squareCardForm(event, googlePay);
                });
            }
        }
        catch (e) {
            console.error('Initializing google Pay failed', e);
        }
        try {
            applePay = await initializeApplePay(payments);
            if (applePay !== undefined) {
                const applePayButton = document.getElementById('apple-pay-button');
                applePayButton.addEventListener('click', async function (event) {
                    console.log('apple pay click', event);
                    isApplePay = true;
                    await squareCardForm(event, applePay);
                });
            }
        }
        catch (e) {
            //isApplePayEnabled = false;
            console.error('Initializing apple Pay failed', e);
            setApplePayEnabled(false);
        }
        try {
            if (_card) {
                document.getElementById("square-em-payment-form").style.display = "block";
                document.getElementById("card-button").style.display = "block";
            }
            document.getElementById("card-container").innerHTML = ''
            await _card.attach('#card-container');
            return _card;
        }
        catch (e) {
            console.error('Initializing wallet Pay failed', e);
        }
    }

    const tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            throw new Error(errorMessage);
        }
    }

    const createPayment = async (token) => {
        console.log('in create Payment', token);
        const captchaAction = 'purchase';
        const captchatoken = await window.genRecaptchaToken(captchaAction);
        console.log('square captcha', captchatoken);
        let resResult;
        if (result !== undefined && result !== null) {
            resResult = result;
            if (isGooglePay && resResult !== 'giftcard') {
                resResult.isGooglePay = true;
            }
            else if (isApplePay && resResult !== 'giftcard') {
                resResult.isApplePay = true;
            }
        }
        if (state !== undefined && state !== null) {
            resState = state;
            if (isGooglePay) {
                resState.isGooglePay = true;
            }
            else if (isApplePay) {
                resState.isApplePay = true;
            }
        }

        let square_customer_id;
        if (!isGiftcard) {
            let passengers = tandemStore.getAll().finalForm;
            square_customer_id = parseInt(passengers.passengers[0].phone * Math.random() + new Date().getTime());
        } else if (isGiftcard) {
            let uniqueId = Math.random() * 1009 * Math.random() + new Date().getTime() + Math.random();
            square_customer_id = parseInt(uniqueId);
        }
        let customer_id = parseInt(square_customer_id) + "";
        let refernce_id = parseInt(square_customer_id * Math.random() + new Date().getTime());
        const body = {
            captchatoken,
            captchaAction,
            customer_id,
            refernce_id,
            idempotency_key: makeid(),
            source_id: token,
            note: "paying for sky-diving",
            locationId: window.ENV.processorInfo.location_id,
            app_fee_money: {
                amount: parseInt(Number(state.totalFees) * 100),
                currency: "USD"
            },
            amount_money: {
                amount: parseInt(Number(totalAmount) * 100),
                currency: "USD"
            },
            autocomplete: true,
            reservationData: resResult
            
        };

        if (isGiftcard) {
            body.data = resState
        }

        const paymentResponse = await api.transaction(body);

        //const paymentResponse = await api.post(`${window.ENV.reservationApiUrl}transnational/proxy/transaction`, body);

        if (paymentResponse.ok) {
            return paymentResponse
        }
        if (paymentResponse.data && paymentResponse.data.msg) {
            throw new Error(paymentResponse.data.msg)
        }
        throw new Error(paymentResponse);
    }

    const handlePaymentMethodSubmission = async (event, wallet) => {
        const cardButton = document.getElementById('card-button');
        let paymentMethod;
        if (isGooglePay || isApplePay) {
            paymentMethod = wallet;
        } else {
            paymentMethod = card;
        }

        try {
            cardButton.disabled = true;
            setState({ paymentLoading: true })
            try {
                token = null;
                token = await tokenize(paymentMethod);
            } catch (err) {
                if (err.name !== "TokenizationError") {
                    throw new Error(err);
                }
                setState({ paymentLoading: false })
                cardButton.disabled = false;
            }
            if (token !== null) {
                paymentResults = await createPayment(token);
                setState({ paymentLoading: false })
                cardButton.disabled = false;
                if (paymentResults.ok)
                    setState({ squareRes: paymentResults })
                if (result != "giftcard") {
                    console.log(paymentResults.data);
                    if (isGooglePay) {
                        paymentResults.isGooglePay = true;
                    }
                    else if (isApplePay) {
                        paymentResults.isApplePay = true;
                    }
                    AfterPayment(state, setState, null, paymentResults, null, undefined, paymentResults.data.id, "*" + paymentResults.data?.card_details?.card?.last_4);
                }
                else if (result === "giftcard") {
                    onPaymentCompletion(paymentResults);
                }
            }
        } catch (err) {
            setState({ paymentLoading: false })
            cardButton.disabled = false;
            AfterPayment(state, setState, null, paymentResults, null, err);
        }
    }

    const intializeSquareCard = async () => {
        let payments = null;
        try {
            //const paymentResponse = await api.get(`${window.ENV.reservationApiUrl}transnational/proxy/transaction`);
            payments = window.Square.payments(window.ENV.processorInfo.appId, window.ENV.processorInfo.locationId);
        } catch (err) {
            const statusContainer = document.getElementById('payment-status-container');
            if (statusContainer) {
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
            }
            return;
        }
        try {
            if (card != null) card = null;
            card = await initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }
    }

    const squareCardForm = async (event, wallet) => {
        if (!isGiftcard) {
            isenablePurchase = await handleFormData("squarePay");
        }
        if (isenablePurchase || isGiftcard) {
            if (!isGiftcard) {
                result = await newHandleReservation(state, setState, props);
                showTotalAmount = renderTotal(
                    !!state.dicountedTandemPackage && ((state.taxPerc / 100) * state.dicountedTandemPackage.price * state.ticketCount)
                )
                setTotalAmount(showTotalAmount.props.children[1])
            } else {
                result = "giftcard";
                setTotalAmount(state.total);
            }
            handlePaymentMethodSubmission(event, wallet);
        } else {
            reservationComponentRef?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            return;
        }
    }

    async function buildPaymentRequest(payments) {
        console.log('buildPaymentRequest')
        console.log(totalAmount);
        var paymentRequestBody =
        {
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: totalAmount.toFixed(2).toString(),
                label: 'Total',
            },
        }
        console.log(paymentRequestBody);

        return payments.paymentRequest(paymentRequestBody);
    }

    async function buildApplePayPaymentRequest(payments) {
        console.log('buildPaymentRequest')
        console.log(totalAmount);
        var paymentRequestBody =
        {
            total: {
                amount: 100,
                currencyCode: 'USD'
            }
        }
        console.log(paymentRequestBody);

        return paymentRequest = payments.paymentRequest(paymentRequestBody);
    }

    async function initializeGooglePay(payments) {
        const paymentRequest = await buildPaymentRequest(payments)
        console.log(paymentRequest)
        const googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach('#google-pay-button', {
            buttonSizeMode: 'fill',
            buttonType: 'long'
        });

        return googlePay;
    }
    async function initializeApplePay(payments) {

        const paymentRequest = await buildPaymentRequest(payments)
        console.log(paymentRequest);
        const applePay = await payments.applePay(paymentRequest);
        // Note: You do not need to `attach` applePay.
        return applePay;
    }


    return (
        <>
            {termsAccepted &&
                <form id="square-em-payment-form">
                    <div className={"close-button-div"}>
                        <p>{`Amount: $${totalAmount}`}</p>
                    </div>
                    {applePayEnabled && !isDisablePayBtn() ? <div id="apple-pay-button"></div> : null}
                    {!isDisablePayBtn() ? <div id="google-pay-button"></div> : null}
                    <div id="card-container"></div>
                    <div className={"button-div"}>
                        <button id="card-button" disabled={isDisablePayBtn()} type="button" className='btn-square' onClick={(event) => squareCardForm(event)}>
                            {window.ENV.processorInfo.isCardOnFile ? "Save Card & Pay" : "Pay"}
                        </button>
                    </div>
                </form>}
        </>
    )
}

export default SquareEmbeddedPay;
