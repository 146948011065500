import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import {
    Modal, ModalHeader, ModalBody
} from 'reactstrap';
import TermsConditions from './Terms_conditions/TermsConditions_Index';
import '../../components/CommonComp/Terms_conditions/TermsConditions_index.css'
const TagManager = require('react-gtm-module');


let isDisable;

class TermsAndConditions extends Component {

    state={
        isAcceptDisable:true
    }
    
    componentWillReceiveProps(nextProps) {

        let {allConditions} = nextProps;
        isDisable = allConditions?.every((condition)=>condition.isChecked===true)
        this?.setState({isAcceptDisable:!isDisable})
    }

    handleSubmit = (isAccept) => {
        if (window.ENV.gtmId && isAccept) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'tandcAccept',
                data: window.ENV.gtmId
              }
            })
          }
        this.props.handleTermsAndCondition(isAccept);
    }

     render() {
         let { isModalOpen } = this.props;

        return (
            <Modal
                id="modal-report"
                aria-labelledby="example-custom-modal-styling-title"
                isOpen={isModalOpen}
                className="terrms_conditions_primary"
                style={{
                    zIndex: '10503434',
                    display: "flex",
                    justifyContent: "center"
                }}>
                <ModalHeader
                    style={{ width: "100%" }} className="header_font_size">
                    Terms and Conditions for {window.ENV.TermAndCondition.Title}
                    <span
                        className="ModalCloseButton"
                        onClick={ ()=>this.handleSubmit(false)}
                    >
                        X
                    </span>
                </ModalHeader>
                <ModalBody style={{display:"flex", flexDirection:"column"}}>

                    {!this?.props?.allConditions?.length ? <div className="config_terms_conditions" dangerouslySetInnerHTML={{ __html: window.ENV.TermAndCondition.Content }} />:
                    <TermsConditions allConditions={this.props.allConditions}
                                    setState={this.props.setState}
                                    pSetState= {this.setState.bind(this)}
                    /> }
                    <button
                        className="btn btn-primary btn-lg right btn-style"
                        disabled={this.state.isAcceptDisable}
                        onClick={() => this.handleSubmit(true)}
                    >
                        Accept
                    </button>
                </ModalBody>
            </Modal>
        )
    }
}
export default withRouter(TermsAndConditions)