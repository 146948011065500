


export const full_pay_tandem = (element,vp,state,indx,tp,tandemPkgArrCopy,taxRate) => {
    let video_price= window.ENV.dontApplyVideoCharge ? 0 : (vp[0]?.videoPrice ? vp[0]?.videoPrice :  0);


    let elementSelectedTandemPackPrice = state.isWeekEnd ? tandemPkgArrCopy[indx]['weekendPrice'] 
        : tandemPkgArrCopy[indx]['price'];


    element.totalPaid =elementSelectedTandemPackPrice+video_price
    element.totalTandemPaid=elementSelectedTandemPackPrice;
    element.totalTandemTaxPaid= tp[0].isTaxable ? ((taxRate / 100) * element?.totalTandemPaid) : 0;
    element.totalVideoPaid=video_price;
    element.totalVideoTaxPaid= vp[0]?.isTaxable ? ((taxRate / 100) * element.totalVideoPaid) : 0;
    element.totalTaxPaid = element.totalTandemTaxPaid+element.totalVideoTaxPaid;



    return element;
}