import { useState } from "react";
import ApplePay from "../ApplePay/ApplePay";
import GooglePay from "../GooglePay/GooglePay";
import { newHandleReservation } from "../Methods/handleReservation/newHandleReservation";
const TagManager = require('react-gtm-module');

let result;
const TransnationalPay = ({isDisablePayBtn, handleFormData, isGiftcard, renderTotal, state, setState, props, onPaymentCompletion, that}) => {
    
    let isenablePurchase, showTotalAmount, paymentResults;
    const [totalAmount, setTotalAmount] = useState();

    const transnationalCardForm = async () => {
        if (!isGiftcard) {
            isenablePurchase = await handleFormData("transnationalPay");

        }
        if (isenablePurchase || isGiftcard) {
            if (!isGiftcard) {  
                result = await newHandleReservation(state, setState, props);
                showTotalAmount = renderTotal(
                    !!state.dicountedTandemPackage && ((state.taxPerc / 100) * state.dicountedTandemPackage.price * state.ticketCount)
                )
                setTotalAmount(showTotalAmount.props.children[1]);
                if (window.ENV.gtmId) {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'beginCheckout',
                        data: window.ENV.gtmId
                      }
                    })
                  }
            } else {
                result = "giftcard";
                setTotalAmount(state.total);
            }
            if (!isGiftcard) window.transactionUI(result);
            else window.transactionUI(result, state);
            var modal = document.getElementById("myModal");
            var btn = document.getElementById("myBtn");
            var span = document.getElementsByClassName("close")[0];
            modal.style.display = "block";
            span.onclick = function () {
              modal.style.display = "none";
            }
            window.reservationComponent = that;
            window.onclick = function (event) {
              if (event.target == modal) {
                modal.style.display = "none";
              }
            }
        }
    }

    return (
        <div className="col-md-12" style={{ display: window.innerWidth < 1000 ? "grid" : "flex", justifyContent: "center", alignItems: "center", marginTop: "25px", paddingBottom: isGiftcard ? "18px" : ""}}>
            {!isDisablePayBtn() && !isNaN(state.totalAmount) && state.totalAmount > 0 && window.ENV.processorInfo.appleMerchantId && state.isIosOs && !props.phone &&
            <div className="google_apple_button" style={{ marginRight: "20px", marginTop: "1px" }} >
                <ApplePay key={state.totalAmount} amount={state.totalAmount} onPaymentCompletion={onPaymentCompletion} state={state} setState={setState} props={props} handleFormData={handleFormData} isGiftcard={isGiftcard}/>
            </div>
            }
            {!isDisablePayBtn() && !isNaN(state.totalAmount) && state.totalAmount > 0 && window.ENV.processorInfo.googleMerchantId && !props.phone &&
            <div className="google_apple_button" style={{ marginTop: "7px", marginRight: "20px" }}>
                <GooglePay key={state.totalAmount} amount={state.totalAmount} onPaymentCompletion={onPaymentCompletion} state={state} setState={setState} props={props} handleFormData={handleFormData} isGiftcard={isGiftcard}/>
            </div>
            }
            <button
                disabled={isDisablePayBtn()}
                className={"pay_btn btn btn-primary btn-lg checkoutButton"}
                onClick={transnationalCardForm}
            >
                Pay with Credit Card
            </button>
        </div>
    )
}

export default TransnationalPay;