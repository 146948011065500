


export const groupon_pack = (element,vp,taxRate,state) => {

    element.type = 'groupon';
    element.tandemPackage = state.grouponPackage.tandemPackageName;


    element.totalTandemPaid=state.groupOnPackPrice;
    element.totalTandemTaxPaid= state.selectedGroupOnPack.isTaxable ? ((taxRate / 100) * element?.totalTandemPaid) : 0;
    if (state.grouponPackage.videoPackageName) {
        element.video = state.grouponPackage.videoPackageName;
    }

    if(vp.length > 0){
        element.totalVideoPaid = vp[0].videoPrice;
        element.totalVideoTaxPaid= vp[0]?.isTaxable ? ((taxRate / 100) * element.totalVideoPaid) : 0;
    }else{
        element.totalVideoPaid = 0;
        element.totalVideoTaxPaid = 0;
    }




    element.totalPaid =element.totalTandemPaid+element.totalVideoPaid
    element.totalTaxPaid = element.totalTandemTaxPaid+element.totalVideoTaxPaid;
    return element;
}