import './squarePay.css';
import { create } from 'apisauce';
import { AfterPayment } from '../Methods/handleReservation/after_payment';
import { makeid } from '../Methods/makeId';
import tandemStore from '../../stores/store';
import '../reservation.css';
import { newHandleReservation } from '../Methods/handleReservation/newHandleReservation';
import { useEffect, useState } from 'react';
const api = create({});

let card = null;
let token = null;
let result;
const SquarePay = ({ isDisablePayBtn, handleFormData, isGiftcard, renderTotal, state, setState, props, onPaymentCompletion }) => {

    let isenablePurchase, showTotalAmount, paymentResults;
    const [totalAmount, setTotalAmount] = useState();
    const [squareLocationId, setSquareLocationId] = useState();
    useEffect(() => {
        async () => {
            const squareLocationIdRes = await api.get(`${window.ENV.reservationApiUrl}//squareOauth/locationId`);
            setSquareLocationId(squareLocationIdRes);
        }
    },[])
    const initializeCard = async (payments) => {
        const _card = await payments.card();
        if (_card) {
            document.getElementById("card-button").style.display = "block";
            document.getElementById("overlay").style.display = "block";
            document.getElementById("payment-form").style.display = "block";
        }
        document.getElementById("card-container").innerHTML = ''
        await _card.attach('#card-container');
        return _card;
    }

    const tokenize = async (paymentMethod) =>  {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            throw new Error(errorMessage);
        }
    }

    const createPayment = async (token) => {
        let resResult;
        if (result !== undefined && result !== null) {
            resResult = result;
        }
        if (state !== undefined && state !== null) {
            resState = state;
        }
        let square_customer_id;
        if(!isGiftcard){
            let passengers= tandemStore.getAll().finalForm;
            square_customer_id=parseInt(passengers.passengers[0].phone*Math.random()+new Date().getTime());
        }else if(isGiftcard) {
            let uniqueId = Math.random() * 1009 * Math.random() +new Date().getTime() + Math.random();
            square_customer_id = parseInt(uniqueId);
        }
        let customer_id = parseInt(square_customer_id) + "";
        let refernce_id = parseInt(square_customer_id * Math.random() + new Date().getTime());
        const body = {
            customer_id,
            refernce_id,
            idempotency_key: makeid(),
            source_id: token,
            note: "paying for sky-diving",
            locationId: window.ENV.processorInfo.location_id,
            app_fee_money: {
                amount: parseInt(Number(state.totalFees) * 100),
                currency: "USD"
            },
            amount_money: {
                amount: parseInt(Number(totalAmount) * 100),
                currency: "USD"
            },
            autocomplete: true,
            reservationData: resResult,
        };
        
        if(isGiftcard){
            body.data = resState
        }

        const paymentResponse = await api.post(`${window.ENV.reservationApiUrl}transnational/proxy/transaction`, body);
        if (paymentResponse.ok) {
            return paymentResponse
        }
        if (paymentResponse.data && paymentResponse.data.msg) {
            throw new Error(paymentResponse.data.msg)
        }
        throw new Error(paymentResponse);
    }

    const handlePaymentMethodSubmission = async (event) => {
        const cardButton = document.getElementById('card-button');
        const paymentMethod = card;
        try {
            cardButton.disabled = true;
            try {
                token = null;
                token = await tokenize(paymentMethod);
            } catch (err) {
                if (err.name !== "TokenizationError") {
                    throw new Error(err);
                }
            }
            if (token !== null) {
                paymentResults = await createPayment(token);
                cardButton.disabled = false;
                if (paymentResults.ok)
                    setState({ squareRes: paymentResults })
                closeSquareCard();
                if (result != "giftcard")
                    AfterPayment(state, setState, null, paymentResults);
                else if (result === "giftcard") {
                    onPaymentCompletion(paymentResults);
                }
            }
        } catch (err) {
            cardButton.disabled = false;
            AfterPayment(state, setState, null, paymentResults, null, err);
        }
    }

    const squareCardForm = async () => {
        if (!isGiftcard) {
            isenablePurchase = await handleFormData("squarePay");
        }
        if (isenablePurchase || isGiftcard) {
            if (!isGiftcard) {  
                result = await newHandleReservation(state, setState, props);
                showTotalAmount = renderTotal(
                    !!state.dicountedTandemPackage && ((state.taxPerc / 100) * state.dicountedTandemPackage.price * state.ticketCount)
                )
                setTotalAmount(showTotalAmount.props.children[1])
            } else {
                result = "giftcard";
                setTotalAmount(state.total);
            }
            setState({ isSquareCardDisplay: true });
            let payments = null;
            try {
                const paymentResponse = await api.get(`${window.ENV.reservationApiUrl}transnational/proxy/transaction`);
                payments = window.Square.payments(window.ENV.processorInfo.appId, window.ENV.processorInfo.locationId);
            }catch(err) {
                const statusContainer = document.getElementById('payment-status-container');
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
                return;
            }
            try {
                if (card != null) card = null;
                card = await initializeCard(payments);
            } catch (e) {
                console.error('Initializing Card failed', e);
                return;
            }

        }
    }

    const renderCard = () => {
        return (
            <div id={"overlay"}>
                <form id="payment-form">
                    <div className={"close-button-div"}>
                        <p>{`Amount: $${totalAmount}`}</p>
                        <div onClick={closeSquareCard}>x</div>
                    </div>
                    <div id="card-container"></div>
                    <div className={"button-div"}>
                        <button id="card-button" type="button" className='btn-square' onClick={(event) => handlePaymentMethodSubmission(event)}>
                            {window.ENV.processorInfo.isCardOnFile ? "Save Card & Pay" : "Pay"}
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    const closeSquareCard = () => {
        document.getElementById("overlay").style.display = "none";
    }

    return (
        <>
            {state.isSquareCardDisplay && renderCard(card)}
            {/* <div className="row"> */}
                <div className="col-md-12" style={{ display: window.innerWidth < 1000 ? "grid" : "flex", justifyContent: "center", alignItems: "center", marginTop: "25px", paddingBottom: isGiftcard ? "18px" : "" }}>
                    <button
                        disabled={isDisablePayBtn()}
                        className={"pay_btn btn btn-primary btn-lg checkoutButton margin_right"}
                        onClick={squareCardForm}
                    >
                        Pay with Credit Card
                    </button>
                </div>
            {/* </div> */}
        </>
    )
}

export default SquarePay;
