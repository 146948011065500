
import React, { useEffect } from 'react';
import { Show_Loader } from '../Loader/ShowLoader';
import './TransnationalGateway.css'




const TransnationalGateway = () => {

    const [showLoader,setShowLoader] = React.useState(false);

    useEffect(()=>{ 
        setShowLoader(false)    
        window.transactionUI();
        {window.scrollTo(0, document.querySelector(".container").scrollHeight)}
    },[])

    const onSubmit = async () =>{
        setShowLoader(true);
        const response = await example.submit();
    }

    return(
        <div className = "tg_main">
                {showLoader && <Show_Loader showLoader={showLoader}/>}
                <div className = "tg_modal">
                    <div className = "tg_header">
                        <img src="Transnational-logo.webp"  alt = "Transnational_Logo"></img>
                    </div>
                    <div id="transPayment" className="user_info_card"></div>
                    <button className = "submit_btn" onClick={onSubmit}>Submit</button>
                </div>
        </div>
    )
}


export default TransnationalGateway;