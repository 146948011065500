import './ShowLoader.css'
import ClipLoader from 'react-spinners/ClipLoader';

export const Show_Loader = ({showLoader}) => {
    return(
      <div id={"loader_overlay"}>
        <div className={"loader_main"}>
          <ClipLoader 
            sizeUnit={"px"}
            size={80}
            color={'#123abc'}
            loading={showLoader}
          />
        </div>
      </div>
    )
  }


