import React, { Component } from 'react';
import moment from 'moment-timezone'

class DateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }
  render() {
    var { slot } = this.props;

    // console.log("slots=======>>>>",slot)

    var date = slot.datetime;
    var timeZone = slot.timeZone;
    // console.log(slot);
    // console.log(timeZone);
    // console.log(moment(date));
    // console.log(moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A"));
    // console.log("DateSelct", slot)
    const nameEvent = !!slot?.eventCategory ? slot.eventCategory : slot.eventName
    // const slotName =
    return (
      <div className="row" >
        <div className="col-sm-12">
        
        {/* {this.props.index === 0 ?  <div> 
          <button
            className={(this.props.selected ? "active" : "") + " timeSelect lightButtonBorder appFont inline btn btn-link"}
            onClick={() => this.props.handleClick(slot,slot.eventName)}
          >
            <i className="rounded-circle fa fa-clock-o p-2 roundFix"></i>&nbsp;
            {moment(date).tz(timeZone).format("h:mm A")} {slot.seatsRemaining} Seats available
          
          </button> &nbsp;&nbsp;
          <button
          className={"timeSelect lightButtonBorder appFont inline btn btn-link"}>
            {moment(date).tz(timeZone).format("DD/MMMM/YYYY")}
            </button></div>  : */}
          
            <button
            className={(this.props.selected ? "active" : "") + " timeSelect lightButtonBorder appFont inline btn btn-link"}
            style={this?.props?.changeDate ? {minWidth:"35%",textAlign:"left"} : {}}
            onClick={() => this.props.handleClick(slot, nameEvent)}
            >
              <i className="rounded-circle fa fa-clock-o p-2 roundFix"></i>&nbsp;
            {moment(date).tz(timeZone).format("h:mm A")} {window.ENV.dontShowSeatsAvailable!==true? slot.seatsRemaining+" Seats Available":null} 
            </button>

         {/* } */}
         
        </div>
        {/*<Hr />*/}
      </div>

    );
  }
}

export default DateSelector;
