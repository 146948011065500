
import moment from 'moment';
import tandemStore from '../../../stores/store'
import { createStuffJumpers } from '../createStuffJumpers';
import { makeid } from '../makeId';
import { depoist_pay_aff } from './depoist_pay_aff';
import { depoist_pay_tandem } from './depoist_pay_tandem';
import { full_pay_aff } from './full_pay_aff';
import { full_pay_depoist, full_pay_tandem } from './full_pay_tandem';
import { getPrice } from './getPrice';
import { getTransactionDetails } from './getTransactionDetails';
import { giftCard_Res } from './giftCardRes';
import { groupon_pack } from './groupon_pack';
import recompute_helper from './recomputer_helper';
import { setCustomerIntialDetails } from './setCustomerIntialDetails';
const TagManager = require('react-gtm-module');



export const newHandleReservation = (async (state, setState, props) => {

  var { finalForm, totalAmount, isWeekEnd, discount, discountValue, phone, tandemPkgArr, vidPkgArr, affFacebookTrackVal, allConditions, giftCodeEmail, grouponCode, namedEvent } = state;
  var payload = {};
  const tandemPkgArrCopy = [...tandemPkgArr];
  var priceSchedule = tandemStore.priceSchedule;
  var videoPackages = tandemStore.getVideoPackages();
  var tandemWeekend = state.loadPrices.tandemPackages[0].weekendPrice;
  var tandemWeekday = state.loadPrices.tandemPackages[0].price
  var tandemVideoCost = getPrice(priceSchedule, "video");
  var scheduleFee = getPrice(priceSchedule, "schedule fee");
  var AffFee = tandemStore.getFirstJump().price;
  var taxPerc = parseInt(scheduleFee);
  var tandemUnitCost = 0;


  if (state.EventName.trim() == 'Tandem') {
    tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
  }
  else if (state.EventName.trim() == 'AFF') {
    tandemUnitCost = parseInt(AffFee);
  }


  if (state.grouponPackage !== null || state.manualGiftCard || state.giftPackage !== null || totalAmount > 0) {
    let email = '';
    let name = '';
    let othername = '';
    let eventName = '';
    let ticketCount = '';
    let videoCount = '';
    let slotDates = '';
    let passangerBody = [];
    let recipients = [];
    let taxRate = tandemStore.getTaxRate();
    let videoPackages = tandemStore.getVideoPackages();
    let tandemPackages = tandemStore.getTandemPackages();

    if (props.location.pathname === "/phone" && state.ticketCount > 1) {
      createStuffJumpers(state.ticketCount, finalForm, tandemPkgArrCopy)
    }

    finalForm.passengers.forEach((element, indx) => {
      let tp = [];
      let vp = [];
      vp = videoPackages.filter(ele => ele.item === element.video)
      tp = tandemPackages.filter(ele => ele.item === element.tandemPackage)

      element = setCustomerIntialDetails(element);

      if (state.deposit && props.location.pathname !== '/groupon' && props.location.pathname !== '/rush49') {
        if (state.EventName.trim() == 'Tandem')
          element = depoist_pay_tandem(element, vp, taxRate, state);
        if (state.EventName.trim() == 'AFF')
          element = depoist_pay_aff(element, state);
      }

      if (state.payType === 'full' && props.location.pathname !== '/groupon' && props.location.pathname !== '/rush49') {
        if (state.EventName.trim() == 'Tandem')
          element = full_pay_tandem(element, vp, state, indx, tp, tandemPkgArrCopy, taxRate)
        if (state.EventName.trim() == 'AFF')
          element = full_pay_aff(element, state);
      }

      if (state.grouponPackage !== null && (props.location.pathname === '/rush49' || props.location.pathname === '/groupon')) {
        element = groupon_pack(element, vp, taxRate, state);
      }
      else if (state.giftPackage !== null) {
        element = giftCard_Res(element, vp, taxRate, state)
      }

      if (state.payType === "nopay" && props.location.pathname === '/phone') {
        element.totalPaid = 0;
      }

      if (state.payType === "tandemCoupon") {
        element.totalPaid = state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) : (state.couponedTandemPack.price);
        if (state.couponedTandemPack.isTaxable) {
          element.totalTaxPaid = ((taxRate / 100) * element.totalPaid);
          element.totalTandemTaxPaid = element.totalTaxPaid;
        }
        element.totalVideoPaid = 0;
        element.totalVideoTaxPaid = 0;
      }


      passangerBody.push(element);


      recipients.push({ firstname: element.firstname, lastname: element.lastname, email: element.email });

      if (email == '') {
        email = element.email;
        name = element.firstname + " " + element.lastname;
        eventName = state.namedEvent;
        ticketCount = state.ticketCount;
        videoCount = state.videoCount;
        slotDates = state.slotDates;
      }
      else {
        email = email + ' ,' + element.email;
        if (props.location.pathname !== "/phone")
          othername += `<p class="subText" style="font-family:'Opens Sans', sans-serif;margin: 0 auto; margin-top: 15px; text-align: center;font-size: 1.5em;text-align: center;padding-bottom: 0.2em"><b
                    style="font-weight: 700; font-family: inherit;padding-bottom: 0.4em">Name: </b> ${element.firstname + " " + element.lastname}</p>`;
      }
    })

    recompute_helper(state,passangerBody,props);
    var date = state.slotDates.length > 0 ? new Date(state.slotDate) : new Date();
    var month = date.toLocaleString('en-us', { month: 'long' });
    var timeZone = state.slotDates[0].timeZone;




    var Subject = "Inovice Report";
    var priceSchedule = tandemStore.priceSchedule;
    var tandemWeekend = state.loadPrices.tandemPackages[0].weekendPrice;
    var tandemWeekday = state.loadPrices.tandemPackages[0].price;
    var tandemVideoCost = getPrice(priceSchedule, "video");
    var scheduleFee = getPrice(priceSchedule, "schedule fee");
    var AffFee = tandemStore.getFirstJump().price;

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    var invoice = [];
    var invoiceMail = [];
    if (state.EventName === "AFF") {
      invoice.push({ item: "AFF", count: state.ticketcount, value: state.affValue })
    }

    if (state.grouponPackage !== null) {
      invoice.push({ item: state.grouponPackage.tandemPackageName, count: state.grouponPackage.numTickets, value: state.groupOnPackPrice * state.grouponPackage.numTickets })
    }

    if (state.giftPackage !== null) {
      invoice.push({ item: state.giftPackage.tandemPackageName, count: state.giftPackage.tickets, value: 0.00 })

      if (state.giftPackage.videoPackageName !== null && state.giftPackage.videoPackageName !== undefined && state.giftPackage.videoPackageName !== "none") {
        invoice.push({ item: state.giftPackage.videoPackageName, count: state.giftPackage.tickets, value: 0.00 })
      }
    }

    if (state.payType === 'full' && state.EventName !== "AFF") {
      state.selectedTandemPackages.forEach(pkg => {
        invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: phone ? state.isWeekEnd ? state.ticketCount * pkg.pkgWeekendprice : state.ticketCount * pkg.pkgActualPrice : state.isWeekEnd ? pkg.pkgWeekendprice : pkg.pkgActualPrice });
      });
    }

    if (state.deposit && state.EventName !== "AFF") {
      state.selectedTandemPackages.forEach(pkg => {
        invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: phone ? state.ticketCount * state.tandemEventDepositAmount : pkg.count * state.tandemEventDepositAmount });
      });
    }

    if (state.payType === "tandemCoupon") {
      invoice.push({
        item: state.couponedTandemPack.item, count: state.ticketCount, value: state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) * state.ticketCount :
          (state.couponedTandemPack.price) * state.ticketCount
      })

      if (state.couponedTandemPack.videoType !== "none") {
        invoice.push({ item: state.couponedTandemPack.videoType, count: state.ticketCount, value: 0.00 })
      }
    }

    state.selectedVideoPackages.forEach(pkg => {
      invoice.push({ item: pkg.name, count: phone ? state.ticketCount : pkg.count, value: pkg.price });
    });


    const totalAmountPaid = invoice.reduce((total, cur) => total + cur.value, 0);
    setState({ _totalAmountPaid: totalAmountPaid })
    let selectedItems = [];

    invoice.forEach((item) => {
      const new_item = {
        item_name: item.item,
        price: item.value,
        quantity: item.count
      }
      selectedItems.push(new_item);
    })
    setState({ selected_items: selectedItems })

    var emailBody = {
      email: email,
      phone: passangerBody[0].phone,
      recipients: recipients,
      name: name,
      othername: othername,
      subject: Subject,
      eventName: eventName,
      ticketCount: ticketCount,
      videoCount: videoCount,
      slotDates: slotDates,
      giftCodeEmail: giftCodeEmail,
      slotDateString: moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A"),
      discountValue,
      discount
    };


    if (state.payType === "nopay" && state.phone) {
      // do nothing if nopay
    } else if (window.ENV.processorInfo.processor === "transational" && window.customerRes && window.customerRes.status === "success") {
      var MaskedPAN = window.customerRes.data.payment_method.card.masked_card;
      var CardType = woindow.customerRes.data.payment_method.card.card_type;
      var cardId = window.customerRes.data.payment_method.card.id;
      var customerId = window.customerRes.data.id
      var TransactionId = window.customerRes.data.id;
      var tokenBody = {
        cardNumLast4: MaskedPAN,
        cardType: CardType,
        cardId: cardId,
        customerId: customerId
      }
      passangerBody[0].cardOnFile = [];
      passangerBody[0].cardOnFile.push(tokenBody);
    } else if (window.ENV.processorInfo.processor == 'cardConnect' && window.customerRes) {
      var customerId = window.customerRes.profileid;
      // var MaskedPAN = "xxxxxxxxxx" + r.account.slice(r.account.length - 4);
      var CardType = customerRes.accttype;
      var tokenBody = {
        cardNumLast4: MaskedPAN,
        cardType: CardType,
        customerId: customerId
      }
      passangerBody[0].cardOnFile = [];
      passangerBody[0].cardOnFile.push(tokenBody);
      // passangerBody[0].zipcode = r.zipcode;
    } else {
      passangerBody = passangerBody
    }


    const calender_id = makeid(15)

    if (state.manualGiftCard)
      setState({ videoCost: 0 })


    payload = {
      calender_id,
      passengers: passangerBody,
      ticketCount: state.ticketCount,
      videoCount: state.videoCount,
      videoCost: state.videoCost || 0,
      jumpsPrice: state.deposit === true ? (state.tandemEventDepositAmount * state.ticketCount).toFixed(2) : (tandemUnitCost * state.ticketCount).toFixed(2),
      slotId: finalForm.timeslotId,
      deposit: state.deposit,
      totalFees: state.totalFees,
      processingFees: state.processingFees,
      eventName: state.EventName,
      invoice: invoice,
      discountValue,
      discount,
      conditions: allConditions,
      fromIncompleteReservationEmail : state.fromIncompleteReservationEmail,
      namedEvent: namedEvent,
      state: {
        slotDates: state.slotDates,
        slotDate : state.slotDate,
        totalAmount: state.totalAmount,
        discount: state.discount,
        discountValue: state.discountValue,
        EventName: state.EventName,
        grouponPackage: state.grouponPackage,
        selectedTandemPackages: state.selectedTandemPackages,
        selectedVideoPackages: state.selectedVideoPackages,
        ticketCount: state.ticketCount,
        groupOnPackPrice: state.groupOnPackPrice,
        totalFees: state.totalFees,
        
        phone: state.phone
      }
    };


    if (state.grouponPackage !== null) {

      payload.type = "groupon";
      payload.grouponCode = state.grouponCode;
      payload.grouponPackageName = state.grouponPackage.packageName;
      payload.ticketCount = state.grouponPackage.numTickets;
      ticketCount = state.grouponPackage.numTickets
    }

    if (state.giftPackage !== null) {
      payload.type = "gift";
      payload.giftCode = state.giftPackage.couponCode;
      payload.giftPackageName = state.giftPackage.item;
      payload.ticketCount = state.giftPackage.tickets;
    }


    if (props.location.pathname === "/phone") {
      payload.method = "phone";
      if (state.payType === "nopay") {
        payload.memo = state.memoMessage;
        payload.method = "phone (no pay)";
      }

    }
    else {
      payload.method = "online";
    }

    if (props.location.pathname === "/lakewales") {
      payload.site = "lakewales"
    }
    else if (props.location.pathname === "/plantcity") {
      payload.site = "plantcity"
    }

    var emailBody = {
      email: email,
      phone: passangerBody[0].phone,
      recipients: recipients,
      name: name,
      othername: othername,
      subject: Subject,
      eventName: eventName,
      ticketCount: ticketCount,
      videoCount: videoCount,
      slotDates: slotDates,
      slotDateString: moment(date).tz(timeZone).format("DD/MMM/YYYY,  h:mm A"),
      discountValue,
      grouponCode: grouponCode,
      giftCodeEmail: giftCodeEmail,
      changeReservation: false,
      tandemCouponCode: state.tandemCouponCode,
      changeReservation: false,
      discount
    };


    if (props.location.pathname === "/lakewales") {
      emailBody.site = "lakewales"
    }
    else if (props.location.pathname === "/plantcity") {
      emailBody.site = "plantcity"
    }
    if (tandemStore.isTaxEnabled()) {
      payload.totalTax = state.totalTax;
      emailBody.totalTax = state.totalTax;
    }


    let totalPrice = 0
    if (state.EventName.trim() == 'AFF') {
      totalPrice = affFacebookTrackVal
    } else {
      const totalTandemPrice = tandemPkgArr.reduce((acc, cur) => !state.isWeekEnd ? acc + cur.price : acc + cur.weekendPrice, 0)
      const totalVideoPrice = vidPkgArr.reduce((acc, cur) => acc + cur.videoPrice, 0)
      totalPrice = totalTandemPrice + totalVideoPrice
    }
    payload.emailBody = emailBody;
    payload.processor = window.ENV.processorInfo.processor



    let errorValue = payload.amount - payload.totalFees - payload.totalTax;
    setState({ _errorValue: errorValue });

    if (totalAmount !== 0)
      return payload;

    const result = await tandemStore.MakeReservation(payload);
    console.log(result, "<==================result")
  }
})