


export const depoist_pay_aff = (element,state) => {

    element.totalPaid=state.tandemEventDepositAmount
    // element.totalTandemPaid = state.tandemEventDepositAmount;
    // element.totalTandemTaxPaid=0;
    element.totalTaxPaid=0;

    return element;
}