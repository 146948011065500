import { create } from 'apisauce';

var url = window.location.hostname;

let reservationsUrl =  'http://localhost:4000';
let tenant = "localhost";

if(url.includes("localhost")){
	reservationsUrl =  'http://localhost:4000'
}
else{
	var dz = url.split('.')[0];
	if(dz){
		reservationsUrl = `https://${dz}.reservations-api.dizio.app`;
	}
    tenant  = dz;

}
const api = create({
	//localhost:4000
	baseURL: reservationsUrl,
    headers: {'tenant': tenant}

});

export const getConditions = async() => {
    let response = await api.get("/conditions")
    if(response.status===200){
        return response.data
    }else{
        console.log(response,"<=========Get Conditions Error")
    }
}

export const getAllConditions = async(setAllConditions) => {
    let _conditions=await getConditions();
    setAllConditions({allConditions:_conditions?.data})
}

export const handleOnChange = (index,allConditions,setAllConditions) => {
    let copyAllConditions = [...allConditions];
    copyAllConditions[index].isChecked = !copyAllConditions[index].isChecked;
    setAllConditions({allConditions:copyAllConditions})
}