import tandemStore from '../../../stores/store';


const recompute_helper = (state,passengers,props) => {
  
  console.log(state,"<====================state====")
  console.log(passengers,"<====================state====")
  let taxRate = tandemStore.getTaxRate();
  let videoPackages = tandemStore.getVideoPackages();
  let tandemPackages = tandemStore.getTandemPackages();
  const tandemPkgArrCopy = state.tandemPkgArr
  

  if(state.giftCard && state.haveGiftCardCode && !state.deposit && state.payType === "giftCard"){
    for(let i=0; i< passengers.length; i++) {
      passengers[i].totalPaid = 0;
      passengers[i].totalTandemPaid = 0;
      passengers[i].totalTandemTaxPaid = 0;
      passengers[i].totalTaxPaid = 0;
      passengers[i].totalVideoPaid = 0;
      passengers[i].totalVideoTaxPaid= 0;
    }
  }else if(state.grouponCode && state.isGrouponCodeValid && !state.deposit && state.payType === 'full' && props.location.pathname ==="/groupon" ){

    for(let i=0; i< passengers.length; i++){
      let vp = videoPackages.filter(ele => ele.item === passengers[i].video)
      passengers[i].totalTandemPaid = state.groupOnPackPrice;
      passengers[i].totalTandemTaxPaid= state.selectedGroupOnPack.isTaxable ? ((taxRate / 100) * passengers[i]?.totalTandemPaid) : 0;
      if(vp.length > 0){
        passengers[i].totalVideoPaid = vp[0].videoPrice;
        passengers[i].totalVideoTaxPaid= vp[0]?.isTaxable ? ((taxRate / 100) *  passengers[i].totalVideoPaid) : 0;
      }else{
        passengers[i].totalVideoPaid = 0;
        passengers[i].totalVideoTaxPaid = 0;
      }
      passengers[i].totalPaid =passengers[i].totalTandemPaid+passengers[i].totalVideoPaid
      passengers[i].totalTaxPaid = passengers[i].totalTandemTaxPaid+passengers[i].totalVideoTaxPaid;
    }
  }else if(!state.deposit && state.payType === 'full' && props.location.pathname !== "/groupon"){
   
    for(let i=0; i< passengers.length; i++){
      let vp = videoPackages.filter(ele => ele.item === passengers[i].video)
      let tp = tandemPackages.filter(ele => ele.item === passengers[i].tandemPackage);
      if (state.EventName.trim() == 'Tandem'){
        let video_price= window.ENV.dontApplyVideoCharge ? 0 : (vp[0]?.videoPrice ? vp[0]?.videoPrice :  0);
        let elementSelectedTandemPackPrice;
        if(props.location.pathname !== "/phone"){
          elementSelectedTandemPackPrice= state.isWeekEnd ? tandemPkgArrCopy[i]['weekendPrice'] 
          : tandemPkgArrCopy[i]['price'];
        }else if(props.location.pathname === "/phone"){
          elementSelectedTandemPackPrice = state.isWeekEnd ? tandemPkgArrCopy[0]['weekendPrice'] 
          : tandemPkgArrCopy[0]['price'];
        }
        passengers[i].totalPaid =elementSelectedTandemPackPrice+video_price
        passengers[i].totalTandemPaid=elementSelectedTandemPackPrice;
        passengers[i].totalTandemTaxPaid= tp[0].isTaxable ? ((taxRate / 100) * passengers[i]?.totalTandemPaid) : 0;
        passengers[i].totalVideoPaid=video_price;
        passengers[i].totalVideoTaxPaid= vp[0]?.isTaxable ? ((taxRate / 100) * passengers[i].totalVideoPaid) : 0;
        passengers[i].totalTaxPaid = passengers[i].totalTandemTaxPaid+passengers[i].totalVideoTaxPaid;
       
      }
      else if(state.EventName.trim() == 'AFF'){
        passengers[i].totalPaid = (state.affValue/state.ticketCount);
      }
    }
   }else if(state.deposit && props.location.pathname !== "/groupon" && state.payType === 'deposit'){
    for(let i=0; i< passengers.length; i++){
      let vp = videoPackages.filter(ele => ele.item === passengers[i].video)
      if (state.EventName.trim() == 'Tandem'){
        passengers[i].totalVideoPaid=window.ENV.dontApplyVideoCharge? 0 : vp[0]?.videoPrice || 0;
        passengers[i].totalPaid=state.tandemEventDepositAmount+passengers[i]?.totalVideoPaid;
        passengers[i].totalTandemPaid = state.tandemEventDepositAmount;
        passengers[i].totalTandemTaxPaid=0;
        if(vp[0]?.isTaxable){
          passengers[i].totalVideoTaxPaid=((taxRate / 100) * passengers[i].totalVideoPaid);
        }else{
          passengers[i].totalVideoTaxPaid=0;
        }
        passengers[i].totalTaxPaid=passengers[i].totalTandemTaxPaid+passengers[i].totalVideoTaxPaid;
        passengers[i].totalPaid = state.tandemEventDepositAmount + passengers[i].totalVideoPaid; 
      }
       else if (state.EventName.trim() == 'AFF'){
          passengers[i].totalPaid=state.tandemEventDepositAmount;
          passengers[i].totalTaxPaid=0;
        }
    }
    }else if(props.location.pathname == '/phone' && state.payType ==='nopay' && !state.deposit){
      for(let i=0; i< passengers.length; i++){
        if (state.EventName.trim() == 'Tandem'){
          passengers[i].totalPaid = 0 
          passengers[i].totalTandemPaid = 0;
          passengers[i].totalVideoPaid=0;
          passengers[i].totalTandemTaxPaid = 0;
          passengers[i].totalVideoTaxPaid = 0;
          passengers[i].totalTaxPaid = 0;
        }
        else if (state.EventName.trim() == 'AFF'){
          passengers[i].totalPaid =0;
        }
        
      }
    }else if(state.payType === "tandemCoupon" && state.tandemCouponCode){
      for(let i =0; i<passengers.length;i++){
        passengers[i].totalTandemPaid = state.isWeekEnd ? (state.couponedTandemPack.weekendPrice) : (state.couponedTandemPack.price);
        if (state.couponedTandemPack.isTaxable){
          passengers[i].totalTandemTaxPaid = ((taxRate / 100) * passengers[i].totalTandemPaid);
        }else{
          passengers[i].totalTandemTaxPaid = 0;
        }
        passengers[i].totalVideoPaid = 0;
        passengers[i].totalVideoTaxPaid = 0;
        passengers[i].totalPaid =  passengers[i].totalTandemPaid+passengers[i].totalVideoPaid;
        passengers[i].totalTaxPaid =  passengers[i].totalTandemTaxPaid+passengers[i].totalVideoTaxPaid;
      }
    }

  return passengers;

}

export default recompute_helper;
