import React, { Component } from 'react';
import tandemData from '../stores/store';

class PassengerDetail extends Component {

  constructor(props) {
    super(props);
    this.videoPrice = this.getPrice(tandemData.priceSchedule, "video");
    if (this.props.studentDetail != null)
      tandemData.updateFormWithIndex(this.props.studentDetail, this.props.index - 1)


    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      streetAddress: '',
      email: '',
      zipcode: '',
      adult: false,
      weight: false,
      smsOptIn: false,
      video: "none",
      tandemPackage: "none",
      noVideoOptions: false,
      errors: {},
    };
  }


  componentWillMount = () => {
    if (tandemData.getFormData(this.props.index - 1) == null) {
      this.setState({
        firstname: '',
        lastname: '',
        phone: '',
        streetAddress: '',
        email: '',
        zipcode: '',
        adult: false,
        weight: false,
        video: "none",
        tandemPackage: "none",
        noVideoOptions: false,
        errors: {},
      })
    }
    else {

      this.setState(tandemData.getFormData(this.props.index - 1), () => {
        var eventTandem = {
          target: {
            value: this.state.tandemPackage
          }
        };
        var eventVideo = {
          target: {
            value: this.state.video
          }
        };
        this.handleTandemSelect(eventTandem);
        this.handleVideoSelect(eventVideo);
      })
    }


    if (this?.props?.ticketDetails?.tandemPackageName) {
      var ee = {
        target: {
          value: this.props.ticketDetails.tandemPackageName
        }
      }
      this.handleTandemSelect(ee)
    }
    let tandemPackages = tandemData.getTandemPackages();
    if (tandemPackages.length === 1) {
      var ee = {
        target: {
          value: tandemPackages[0].item
        }
      }
      this.handleTandemSelect(ee)
    }
    this.setState()


  }


  getPrice(priceSchedule, type) {
    var index = priceSchedule.findIndex(x => x.type == type);
    var price = priceSchedule[index].price;
    return price;
  }

  validateEmail = (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  handleValidation(name) {
    let fields = this.state;
    let errors = fields.errors;
    if (fields[name] === '') errors[name] = "This field is required";
    else if ((name === 'firstname' || name === 'lastname') && !fields[name].match(/^[a-zA-Z]+$/)) errors[name] = "Only letters are allowed";
    else if (name === 'email' && !this.validateEmail(fields[name])) errors[name] = "Please enter valid email";
    else if (name === 'zipcode' && !fields[name].match(/^\d{5}(?:[-\s]\d{4})?$/)) errors[name] = "Zipcode should contain 5 numbers";
    else if (name === 'phone' && !fields[name].match(/^([0|\+[0-9]{1,5})?([0-9]{9})$/)) errors[name] = "Phone should contain min 10 numbers or + for international";
    else if (name === 'tandemPackage' && fields[name] === "none" && !this.props.tandemCoupon) errors[name] = "Please Select Tandem Package";
    else errors[name] = "";
    this.setState({ errors: errors });
  }

  handleInput = (event, key) => {
    var price = 0;
    var cache = { ...this.state };
    if (key === 'adult' || key === 'weight' || key === "smsOptIn") {
      cache[key] = event.target.checked
    }
    else {
      cache[key] = event.target.value;
    }

    this.setState({ ...cache }, () => {
      this.handleValidation(key);
    });

    if (!this.state.errors.length) {
      tandemData.updateFormWithIndex(cache, this.props.index - 1);
      var finalData = tandemData.getAll().finalForm;
      if (finalData.passengers.length > 0) {
        finalData.passengers.forEach(x => {

          if (x.video) {
            price = price + 1;
          }
        })

      }
    }
  };

  handleBlur = (event, key) => {
    var price = 0;
    var cache = { ...this.state };
    if (key === 'adult' || key === 'weight'|| key === "smsOptIn") {
      cache[key] = event.target.checked
    }
    else {
      cache[key] = event.target.value;
    }

    this.setState({ ...cache }, () => {
      this.handleValidation(key);
    });

    if (!this.state.errors.length) {
      tandemData.updateFormWithIndex(cache, this.props.index - 1);
      var finalData = tandemData.getAll().finalForm;
      if (finalData.passengers.length > 0) {
        finalData.passengers.forEach(x => {

          if (x.video) {
            price = price + 1;
          }
        })
        this.props.handleFieldChange()
      }
    }
  };

  handleTandemSelect = (event) => {
    //console.log(event);
    //console.log(event.target.value);
    var price = 0;
    var cache = { ...this.state };
    let noVideo = false;
    let video = "none";
    cache["tandemPackage"] = event.target.value;
    this.setState({ tandemPackage: event.target.value }, () => {
      this.handleValidation('tandemPackage');
    });
    var tandemPriceObject = {};
    let passPkgTandemArr = []
    if (!this.state.errors.length) {
      var finalData = tandemData.getAll().finalForm;
      var tandemPackages = tandemData.getTandemPackages();
      var pkg = tandemPackages.filter(pkg => pkg.item === event.target.value)[0];
      if (pkg !== undefined && pkg.videoType !== "none") {
        this.setState({ noVideoOptions: true })
        cache["noVideoOptions"] = true;
        video = cache["video"];
        cache["video"] = "none";
        noVideo = true;
      }
      else {
        this.setState({ noVideoOptions: false })
        cache["noVideoOptions"] = false;
      }
      tandemData.updateFormWithIndex(cache, this.props.index - 1);
      finalData = tandemData.getAll().finalForm;

      if (!this.props.phone) {
        if (finalData.passengers.length > 0) {
          finalData.passengers.forEach(x => {
            var pkg = tandemPackages.filter(pkg => pkg.item === x.tandemPackage)[0];
            if (pkg !== undefined) {
              const { item, price, isTaxable, weekendPrice } = pkg
              passPkgTandemArr.push({ item, price, isTaxable, weekendPrice })
              if (tandemPriceObject[x.tandemPackage] === undefined) {
                tandemPriceObject[x.tandemPackage] = 1;
              }
              else {
                tandemPriceObject[x.tandemPackage] = tandemPriceObject[x.tandemPackage] + 1;
              }
            }
          })
        }
      } else {
        if (finalData.passengers.length > 0) {
          let i = 1;
          while (i <= this.props.ticketCount) {
            var pkg = tandemPackages.filter(pkg => pkg.item === event.target.value)[0];
            if (pkg !== undefined) {
              const { item, price, isTaxable, weekendPrice } = pkg
              passPkgTandemArr.push({ item, price, isTaxable, weekendPrice })
              if (tandemPriceObject[event.target.value] === undefined) {
                tandemPriceObject[event.target.value] = 1;
              }
              // else {
              //   tandemPriceObject[event.target.value] = tandemPriceObject[event.target.value] + 1;
              // }
            }
            i++;
          }
        }
      }
    }

    if (!this.props.ticketDetails) {
      this.props.handleTandemUpdate(tandemPriceObject, event.target.value, passPkgTandemArr, noVideo, video);
      this.props.handleFieldChange()
    }

    if (noVideo && video !== "none") {
      this.setState({ video: "" })
    }
  };




  handleVideoSelect = (event) => {

    if (this.props && this.props.ticketDetails && Object.keys(this.props && this.props.ticketDetails).length) {
      // return
    }


    var price = 0;
    var cache = { ...this.state };
    cache["video"] = event.target.value;
    this.setState({ video: event.target.value });


    var videoPriceObject = {};
    let videoPkgArray = []
    if (!this.state.errors.length) {
      var finalData = tandemData.getAll().finalForm;

      tandemData.updateFormWithIndex(cache, this.props.index - 1);
      finalData = tandemData.getAll().finalForm;

      var videoPackages = tandemData.getVideoPackages();
      if (finalData.passengers.length > 0) {
        finalData.passengers.forEach(x => {

          var pkg = videoPackages.filter(pkg => pkg.item === x.video)[0];
          if (pkg !== undefined) {
            const { item, videoPrice, isTaxable } = pkg

            videoPkgArray.push({ item, videoPrice, key: this.props.trueIndex, isTaxable })
            if (videoPriceObject[x.video] === undefined) {
              videoPriceObject[x.video] = 1;
            }
            else {
              videoPriceObject[x.video] = videoPriceObject[x.video] + 1;
            }
          }
        })
      }
    }

    this.props.handleVideoUpdate(videoPriceObject, event.target.value, videoPkgArray);
    this.props.handleFieldChange()


  };

  componentWillReceiveProps(nextProps) {


    if (nextProps.validateTandemPack) {
      this.handleValidation('tandemPackage');
    }

    if (nextProps.type === "groupon" && nextProps?.passengerDetails?.video) {
      this.setState({ videoPackageName: nextProps.passengerDetails.video })
    } else if (this?.props?.studentDetail?.video) {
      this.setState({ videoPackageName: this.props.studentDetail.video })
    }

    if (nextProps.chkFormVal) {

      for (let key in this.state) {
        if (key != 'errors') this.handleValidation(key);
      }
    }
    if (nextProps.ticketDetails) {
      var ee = {
        target: {
          value: nextProps.ticketDetails.tandemPackageName
        }
      }
      this.handleTandemSelect(ee)
    }


    if ((nextProps.payType === "full" || nextProps.payType === "deposit") && nextProps.selectedTandemPackages.length === 0) {
      this.setState({ tandemPackage: "none" }, () => {
        // this.handleValidation('tandemPackage');
      })
    }



  }

  renderTandemOptions = () => {

    var tandemPackages = tandemData.getTandemPackages();
    let tandemOptions = []
    var videoOptions = [];
    // let tandemOptions = []

    if (this.props.eventFilter === 'Tandem' || this.props.eventFilter === 'Tandem Jump') {

      const onlyTandem = tandemPackages.filter(el => (!el?.calenderEvent || el?.calenderEvent === "Tandem" || el?.calenderEvent === "Tandem Jump"))
      tandemOptions = onlyTandem.map(pkg => {
        return (<option value={pkg.item}> {pkg.item} </option>)
      });
    } else {
      const specialEvents = tandemPackages.filter(el => el?.calenderEvent)
      // if(this?.props?.couponedTandemPack){
      //   specialEvents.push(this.props.couponedTandemPack)
      // }
      const filteredArray = specialEvents.filter(tp => tp.calenderEvent === this.props.eventFilter)
      tandemOptions = filteredArray.map(pkg => {
        return (<option value={pkg.item}> {pkg.item} </option>)
      });
    }


    tandemOptions.unshift(<option value="none">none</option>);




    return tandemOptions;
  }

  renderVideoOptions = () => {
    var videoPackages = tandemData.getVideoPackages();

    var videoOptions = [];

    var videoOptions = videoPackages.map(pkg => {
      return (<option value={pkg.item}> {pkg.item} </option>)

    });
    videoOptions.unshift(<option value="none">none</option>);
    return videoOptions;
  }

  render() {

    return (
      <div className="col-12 col-sm-12 col-md-6 my-3 padding-no">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title text-center ticketHeading">
              Jumper {this.props.index} {this.props.index === 1 ? "- main contact" : null}
            </h5>

            <div>
              <div className="htmlForm-group">
                <label className="customLabel">First Name</label>
                <input
                  value={this.state.firstname}
                  onChange={(e) => this.handleInput(e, 'firstname')}
                  type="text"
                  className="form-control customInputField"
                />
                <span className="text-danger error-validation ">{this.state.errors["firstname"]}</span>
              </div>
              <div className="htmlForm-group">
                <label className="customLabel">Last Name</label>
                <input
                  value={this.state.lastname}
                  onChange={(e) => this.handleInput(e, 'lastname')}
                  type="text"
                  className="form-control customInputField"
                />
                <span className="text-danger error-validation ">{this.state.errors["lastname"]}</span>
              </div>

              {this.props.index === 1 || window.ENV.useEmailsForWaiver ?

                <div className="htmlForm-group">
                  <label className="customLabel">Email {window.ENV.useEmailsForWaiver ? "(* e-waiver will be send to this email)" : null}</label>
                  <input
                    value={this.state.email}
                    onChange={(e) => this.handleInput(e, 'email')}
                    onBlur={(e) => this.handleBlur(e, 'email')}
                    type="text"
                    className="form-control customInputField"
                    placeholder="you@domain.com"
                  />
                  <span className="text-danger error-validation">{this.state.errors["email"]}</span>
                </div> : null
              }
              {this.props.index === 1 ?
                <div className="htmlForm-group">
                  <label className="customLabel">Phone</label>
                  <input
                    value={this.state.phone}
                    onChange={(e) => this.handleInput(e, 'phone')}
                    onBlur={(e) => this.handleBlur(e, 'phone')}
                    type="text"
                    className="form-control customInputField"
                    placeholder="999-999-9999"
                  />
                  <span className="text-danger error-validation">{this.state.errors["phone"]}</span>
                </div>
                : null
              }
              {
                this.props.eventName.toLowerCase() === "tandem" ?
                  <div>
                    {this.props.type !== "groupon" && !this.props.ticketDetails && this.props.type !== "gift"
                      ?
                      <React.Fragment>
                        {tandemData.getTandemPackages().length > 1 && <div className="htmlForm-group">
                          <label className="customLabel" style={{ marginTop: "10px" }}>Select Tandem Option </label>
                          <br />
                          <select value={this.props && this.props.ticketDetails && this.props.ticketDetails.tandemPackageName ? this.props.ticketDetails.tandemPackageName : this.state.tandemPackage} onChange={(e) => this.handleTandemSelect(e)}>
                            {this.renderTandemOptions()}
                          </select>
                          <br />
                          <span className="text-danger error-validation">{this.state.errors["tandemPackage"]}</span>
                        </div>}
                      </React.Fragment>
                      : null
                    }

                    {

                      !this.props.ticketDetails && !this.state.noVideoOptions && this.props.type !== "gift" && !this.props.phone && tandemData.getVideoPackages().length >= 1 ?
                        <div className="htmlForm-group">
                          <label className="customLabel">Select Video Option </label>
                          <br />
                          <select value={this.props && this.props.ticketDetails && this.props.ticketDetails.videoPackageName !== "none" ? this.props.ticketDetails.videoPackageName : this.state.videoPackageName ? this.state.videoPackageName : this.state.video} onChange={(e) => this.handleVideoSelect(e)}>
                            {this.renderVideoOptions()}
                          </select>
                          <br />
                        </div> : <strong>Video available at checkin</strong>
                    }


                    {

                      this.state.noVideoOptions && !this.props.ticketDetails && this.state.tandemPackage !== "none" ?
                        <div className="htmlForm-group">
                          Includes Video
                        </div> : null
                    }

                  </div>
                  :
                  null
              }
              {this.props.index === 1 ?
                <div className="htmlForm-group">
                  <label className="customLabel">opt-in for sms reminders</label>
                  <br/>
                  <input
                    value={this.state.smsOptIn}
                    onChange={(e) => this.handleInput(e, 'smsOptIn')}
                    onBlur={(e) => this.handleBlur(e, 'smsOptIn')}
                    type="checkbox"
                  />
                  <span className="text-danger error-validation">{this.state.errors["smsOptIn"]}</span>
                </div>
                : null
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PassengerDetail;
