import SquareEmbeddedPay from "./squarePayment/squareEmbeddedPay";
import SquarePay from "./squarePayment/squarePay";
import TransnationalPay from "./TransnationalPayment/TransnationalPay";

const processorFactory = (processorType) => {
    if(processorType === "square"){
        return  SquareEmbeddedPay;
    }
    else{
        return TransnationalPay;
    }
}


export default processorFactory;