import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Calendar from 'react-calendar';
import ReactPlayer from 'react-player'
import * as moment from 'moment';
import swal from 'sweetalert';
import { FormContainer, FormComponent, } from 'react-authorize-net';
import {
  Modal, ModalHeader, ModalBody, Col, Row, Label, Media
} from 'reactstrap';
import { Accordion, Card, Button, ListGroup } from 'react-bootstrap';

import DateSelector from './dateselector';
import Api from '../services/api';
import TicketForm from './ticketForm';
import PassengerForm from './passengerForm';
import tandemStore from '../stores/store';

import NabVelocity from './Nab/nabVelocity';
import './reservation.css'
import DiscountInput from './DiscountInput';
import Hr from './customHr';
import tandempic from '../images/tandem.webp'
import affpic from '../images/aff.webp'
import JumpTypeButton from './jumpTypeButton';
import Shoploader from './ShopComponent/ShopLoader';
import TermAndCondition from './CommonComp/TermsConditions';
import TotalTax from './Sub-components/TotalTax';
import { trackSingleCustom } from 'react-facebook-pixel';
import { getAllConditions } from './CommonComp/Terms_conditions/utils';
import { CalculatePrice } from './Methods/calculatePrice';
import { createStuffJumpers } from './Methods/createStuffJumpers';
import { makeid } from './Methods/makeId';
import TransnationalGateway from './TransnationalGateway/TransnationalGateway';
import { handleReservation } from './Methods/handleReservation/handleReservation';
import GooglePay from './GooglePay/GooglePay';

const TagManager = require('react-gtm-module');

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.inCompleteReservation = {};
    this.accordionIndex = "";
  }
  messages = {
    NoSlotAvilable: (
      <div className="alert alert-warning">No Slots available for this day</div>
    ),
    Initial: <div className="alert alert-info">Please select a date to proceed</div>
  };


  state = {
    manualGiftCard: false,
    date: null,
    slotDates: [],
    ticketDetails: null,
    ticketCount: 0,
    videoCount: 0,
    videoCost: 0,
    deposit: false,
    message: null,
    enablePurchase: false,
    hideMainForm: false,
    finalForm: tandemStore.getAll(),
    allowedDates: [],
    chkFormVal: false,
    PriceArray: [],
    EventName: '',
    slotDate: new Date(),
    totalAmount: 0,
    totalFees: 0,
    totalTax: 0,
    giftCard: false,
    tandemCoupon: false,
    tandemCouponZeroValued:false,
    tandemCouponFdata: false,
    cardCode: '',
    errorMessage: false,
    checkoutId: '',
    isWeekEnd: false,
    TandC: false,
    isModalOpen: false,
    isModalaAuthorize: false,
    eventFilter: 'All',
    discount: undefined,
    discountValue: 0,
    grouponPackage: null,
    giftPackage: null,
    submitted: false,
    selectedVideoPackages: [],
    selectedTandemPackages: [],
    affValue: 0,
    transNationalPay: 'none',
    grouponCode: "",
    payType: '',
    haveGiftCardCode: true,
    studentDetails: [],
    selectedOption: '',
    phone: false,
    couponCode: '',
    isShowLoader: true,
    memoMessage: "",
    isClearGroupon: false,
    giftCodeEmail: undefined,
    currentMonthDate: new Date(),
    seatsAvailable: 0,
    seatsError: false,
    dicountedTandemPackage: null,
    taxPerc: 0,
    cond: false,
    namedEvent: '',
    tandemPkgArr: [],
    vidPkgArr: [],
    affFacebookTrackVal: 0,
    totalTandemTaxDue: 0,
    couponedTandemPack: {},
    isGiftCodeValid: true,
    allConditions: [],
    validateTandemPack: false,
    groupOnPackPrice: 0,
    selectedGroupOnPack: {},
    tandemEventDepositAmount: window.ENV.deposit,
    isGrouponCodeValid: true,
    isValidGrouponCode: true,
    showTransnational:false
  
};

  events = [];

  componentDidMount = () => {

    localStorage.removeItem('shoploader');

    getAllConditions(this.setState.bind(this))


    if (this.props.location.pathname === "/phone") {
      this.setState({ phone: true });
    }
    this.setState({
      isModalaAuthorize: false,
    })
    this.checkUrlForGiftCode();

    if (this.props.location.pathname === "/groupon" || this.props.location.pathname === "/rush49") {
      this.setState({ isGrouponCodeValid: false })
    }

    if (this.props.location.pathname === "/giftcard-reservation") {
      this.setState({ isGiftCodeValid: false })
    }


  }
  checkUrlForGiftCode = () => {
    let { params } = this.props.match;
    if (params.giftcode) {
      this.setState({ giftCodeEmail: params.giftcode })
    }
  }

  HandleAutoFillValue = () => {
    tandemStore.getParkedReservation()
      .then(reservationRes => {

        if (reservationRes && reservationRes != null && reservationRes.slotId != null) {
          this.inCompleteReservation = reservationRes;
          var date = new Date(reservationRes.slotId.datetime)
          this.setState({ date: date });
          var wdate = new Date(date);
          var weekEnd = (wdate.getDay() % 6 === 0);
          if (weekEnd) {
            this.setState({
              isWeekEnd: true
            })
          }
          else {
            this.setState({
              isWeekEnd: false
            })
          }

          var theDate = new Date(
            date.getTime()
          );
          var year = theDate.getFullYear();
          var month = theDate.getMonth() + 1;
          var date = theDate.getDate();

          var formattedDate = ("0" + date).slice(-2);

          var formattedMonth = ("0" + month).slice(-2);


          var parsedDate = year + '-' + formattedMonth + '-' + formattedDate;

          this.setState({
            payType: reservationRes.payType
          })
          Api.GetTimeslots(parsedDate).then(this.processSlotResponse)
            .then(() => { this.handleClick(reservationRes.slotId, reservationRes.eventName) })
            .then(() => {
              if (reservationRes.tandemStudents) {
                this.setState({
                  studentDetails: reservationRes.tandemStudents
                })
              }
              else {
                this.setState({
                  studentDetails: reservationRes.students
                })
              }
              this.handleTicketUpdate(reservationRes.ticketCount)
            })
        }
        else {
          alert("No Reservation Pending/Invalid Request")
          this.props.history.push("/");
        }
      })
  }

  updateAllowedDates = (r) => {
    window.debugthis = r.data;

    this.events = r.data;
    var allowedDates = [];

    if (window.ENV.disableSameDayBooking)
      allowedDates = r.data ? r.data.filter(x => x.isBlackedOut === false).map(elem => elem.datetime.split('T')[0]) : []
    else {
      var d = new moment();
      var startDate = d.clone().startOf("day").format("YYYY-MM-DD");
      allowedDates = r.data ? r.data.filter(x => x.isBlackedOut === false && x.datetime.split('T')[0] != startDate).map(elem => elem.datetime.split('T')[0]) : []

    }

    this.hideLoader();

    if (r.data.length > 0) {
      let currentMonthDate = new Date(r.data[0].datetime);
      this.setState({ allowedDates: allowedDates, slotDates: [], currentMonthDate });
    }
    else {
      this.setState({ allowedDates: allowedDates, slotDates: [] })
    }
  }

  handleOnDiscountAdd = (discount, value, code, dicountedTandemPackage) => {
    if (code === undefined || "") {
      this.setState({
        dicountedTandemPackage: null,
      }, () => {
        const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
        this.setState({ ...priceObj })
      })
    }
    if (!!dicountedTandemPackage) {
      this.setState({
        dicountedTandemPackage,
      }, () => {
        const priceObj = (this.state)
        this.setState({ ...priceObj })
      })
    } else {
      this.setState({ discount, discountValue: value })
    }
  }

  handleGrouponPackageVerified = (pkg, grouponCode) => {

    if (Object.keys(pkg).length === 0) {
      this.setState({ isGrouponCodeValid: false, isValidGrouponCode: false })
    } else {
      this.setState({ isGrouponCodeValid: true, isValidGrouponCode: true })
    }
    if (grouponCode === "noValid") {
      this.setState({ isValidGrouponCode: true })
    }

    if (pkg !== undefined) {
      this.setState({ grouponPackage: pkg, ticketCount: pkg.numTickets, grouponCode: grouponCode });
    }
  }

  handleGiftPackageVerified = (pkg, giftCode) => {
    if (pkg !== undefined) {
      this.setState({ giftPackage: pkg, ticketCount: pkg.tickets, giftCode: giftCode, giftCard: true });
    }
  }


  onCalendarChange = (date) => {

    this.setState({showTransnational:false})
   
    if (this.state.isGrouponCodeValid === false) {
      return;
    }

    if (this.state.isGiftCodeValid === false) {
      return
    }

    if (window.ENV.gtmId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'calenderClick',
          data: date?.toString(),
        }
      })
    }
    if (window.ENV.facebooktrackingID) {
      window.fbq('track', 'calendar click', {});
    }
    this.setState({ date: date });

    if (!this.props.giftcardReservation) {
      window.ENV.depositOnly ? this.setState({ payType: "deposit" })
      : this.setState({ payType: "full" })
    }

    var wdate = new Date(date);
    var weekEnd = (wdate.getDay() % 6 === 0);
    if (weekEnd) {
      this.setState({
        isWeekEnd: true
      })
    }
    else {
      this.setState({
        isWeekEnd: false
      })
    }

    var theDate = new Date(
      date.getTime()
    );


    var year = theDate.getFullYear();
    var month = theDate.getMonth() + 1;
    var date = theDate.getDate();

    var formattedDate = ("0" + date).slice(-2);

    var formattedMonth = ("0" + month).slice(-2);
    var parsedDate = year + '-' + formattedMonth + '-' + formattedDate;

    Api.GetTimeslots(parsedDate).then(this.processSlotResponse);

  };

  processSlotResponse = (r) => {

    if (this.state.eventFilter == "All")
      var filterData = r.data.filter(x => x.isBlackedOut !== true);
    else {
      var filterData = r.data.filter(x => {
        //if (x.eventName === 'Tandem') x.eventName = 'Tandem Jump'
        //if (x.eventName === 'AFF') x.eventName = 'AFF First Jump Course'
        return x.isBlackedOut !== true && x.eventName == this.state.eventFilter
      });
    }



    r.data.length !== 0 &&
      this.setState({ slotDates: filterData, finalForm: {}, selectedDate: r.data[0].datetime, videoCount: 0 });

    r.data.length !== 0 && !this.props.giftcardReservation &&
      this.setState({ ticketCount: 0 })

    r.data.length === 0 &&
      this.setState({ message: this.messages.NoSlotAvilable });
    tandemStore.clearAll();
    if (this.SELECTTIME) {
      this.SELECTTIME.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }

    console.log(this.state, "<=======on calender state")
  };

  setClearGroupon = () => { this.setState({ isClearGroupon: false }) }

  handleClick = async(slot, eventName) => {

   

    console.log(this.state.ticketCount, "<===========ticket count")
    if (this.state.grouponPackage !== null && (this.props.location.pathname === "/groupon" || this.props.location.pathname === "/rush49")) {
      const allTandemPackages = tandemStore.getAllTandemPackages();
      const selectedGrouponPack = allTandemPackages.find((tdp) => (
        tdp.item === this.state.grouponPackage.tandemPackageName
      ))

      this.setState({ selectedGroupOnPack: selectedGrouponPack })
      let price = this.state.isWeekEnd ? selectedGrouponPack.weekendPrice : selectedGrouponPack.price
      this.setState({ groupOnPackPrice: price })
    }

    if (this.state.ticketCount > slot.seatsRemaining) {
      swal({
        title: "Warning!",
        text: "Cannot Book more than available seats",
        icon: "error",
        dangerMode: false,
      })
      this.setState({ seatsError: true })
    }
    else {
      this.setState({ seatsError: false })
    }

    var cache = { ...this.finalForm };
    cache.timeslotId = slot._id;
    if (window.ENV.gtmId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'timeSlotClick',
          data: slot.datetime?.toString()
        }
      })
    }


    this.setState({ finalForm: cache, EventName: eventName, slotDate: slot.datetime, seatsAvailable: slot.seatsRemaining,namedEvent:slot.eventName  }, async() => {
      const priceObj=CalculatePrice(this.state,this.getPrice,this.props)
      this.setState({...priceObj})
      if(this.timeslotIdref){
        this.timeslotIdref.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      const response = await tandemData.getEvents()
      const selectedEvent = response?.data.find(event=>{
        if (event.eventName === this.state.namedEvent && event.depositAmount > 0){
          return event;
        }
      })
      if(selectedEvent){
        console.log(selectedEvent,"<====selectedEvent")
        this.setState({tandemEventDepositAmount:selectedEvent.depositAmount})
      }
    });
    tandemStore.updateFinalForm(cache);
  };


  renderSlotSelector = (isTandem, isSpecial) => {
    const { finalForm } = this.state;
    var tandemSlots;
    var affSlots;
    var slots;
    if (isTandem) {
      slots = this.state.slotDates.filter(slot => slot.eventName === "Tandem");
    }
    else {
      slots = this.state.slotDates.filter(slot => slot.eventName === "AFF");
    }
    if (slots.length > 0) {

      return slots.sort((a, b) => new Date(a.datetime) - new Date(b.datetime)).map((slot) => (
        <div key={slot._id}>
          <DateSelector
            selected={finalForm.timeslotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message;
    }

  };

  specialRenderSlotSelector = (data) => {
    const { finalForm } = this.state;
    var slots = this.state.slotDates;

    if (slots.length > 0) {
      slots = slots.filter(slot => slot.eventName == data.name)
      return slots.sort((a, b) => new Date(a.datetime) - new Date(b.datetime)).map((slot) => (
        <div key={slot._id}>
          <DateSelector
            selected={finalForm.timeslotId === slot._id}
            handleClick={this.handleClick}
            slot={slot}
          />
        </div>
      ));
    } else {
      return this.state.message;
    }
  };



  handleTicketUpdate = (val, data, tandemPack, updateticket) => {
    console.log(this.state, "<=======ticket state")
    if (updateticket === "DEC" && this.state.finalForm.passengers.length > 0 && val < this.state.finalForm.passengers.length) {
      let deleteTandemPack = this.state.finalForm.passengers[this.state.finalForm.passengers.length - 1].tandemPackage;
      let deleteVideoPack = this.state.finalForm.passengers[this.state.finalForm.passengers.length - 1].video;
      this.state.finalForm.passengers.pop();
      this.setState({ isTrue: true }, () => {
        let updatedSelectedTandemPackages = this.state.selectedTandemPackages.filter(pack => {
          if (pack.name === deleteTandemPack && this.state.isTrue) {
            this.setState({ isTrue: false })
            let singlePackPrice = pack.price / pack.count;
            let singlePackActualPrice = pack.pkgActualPrice / pack.count;
            pack.count = pack.count - 1;
            pack.price = pack.price - singlePackPrice;
            pack.pkgActualPrice = pack.pkgActualPrice - singlePackActualPrice;
            if (pack.count === 0) {
              return
            }
          }
          return pack;
        })
        this.setState({ selectedTandemPackages: updatedSelectedTandemPackages });
      })

      this.setState({ isTrue: true }, () => {
        let updatedSelectedVideoPackages = this.state.selectedVideoPackages.filter(pack => {

          if (pack.name === deleteVideoPack && this.state.isTrue) {
            this.setState({ isTrue: false })
            let singlePackPrice = pack.price / pack.count;
            pack.count = pack.count - 1;
            pack.price = pack.price - singlePackPrice;
            if (pack.count === 0) {
              return
            }
          }
          return pack;
        })
        this.setState({ selectedVideoPackages: updatedSelectedVideoPackages })
      });
    }

    if (val === undefined) {
      val = 0;
    }

    if (tandemPack) {
      this.setState({ couponedTandemPack: tandemPack, ticketCount: +val, ticketDetails: data },()=>{
        const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
        this.setState({ ...priceObj })
      })
    }else{
      this.setState({ ticketCount: +val, ticketDetails: data }, () => {
        const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
        this.setState({ ...priceObj })
      })}

    if (data?.status === "available" && !this.state.tandemCoupon) {
      this.setState({ giftPackage: data })
    }

    
  };


  handleTandemUpdate = (val) => {


    if (val === undefined) {
      val = 0;
    }

    var counts = Object.values(val);

    var totalCount = counts.reduce(function (a, b) {
      return a + b;
    }, 0);

    var videoPackages = tandemStore.getVideoPackages();

    var totalVideoCost = 0;

    var selectedVideoPackages = [];

    Object.keys(val).forEach(key => {
      var price = videoPackages.filter(pkg => pkg.item === key)[0].videoPrice;
      var count = val[key];
      totalVideoCost += price * count;


      selectedVideoPackages.push({ name: key, count: val[key], price: price * val[key] });
    })

    this.setState({ videoCount: totalCount, videoCost: totalVideoCost, selectedVideoPackages: selectedVideoPackages })

    const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
    this.setState({ ...priceObj })

  };

  handleTandemUpdate = (val, e, tandemPkgArr) => {
    if (val === undefined) {
      val = 0;
    }
    var counts = Object.values(val);
    var totalCount = counts.reduce(function (a, b) {
      return a + b;
    }, 0);
    var tandemPackages = tandemStore.getTandemPackages();
    var totalTandemCost = 0;
    var selectedTandemPackages = [];
    Object.keys(val).forEach(key => {
      var price;
      let pkgActualPrice;
      let pkgWeekendprice;
      if (this.state.deposit) {
        price = this.state.tandemEventDepositAmount;
        pkgActualPrice = tandemPackages.filter(pkg => pkg.item === key)[0].price;
        pkgWeekendprice = tandemPackages.filter(pkg => pkg.item === key)[0].weekendPrice;
      }
      else if (this.state.isWeekEnd) {
        price = tandemPackages.filter(pkg => pkg.item === key)[0].price;
        pkgWeekendprice = tandemPackages.filter(pkg => pkg.item === key)[0].weekendPrice;
        pkgActualPrice = price;
      }
      else {
        price = tandemPackages.filter(pkg => pkg.item === key)[0].price;
        pkgActualPrice = price;
        pkgWeekendprice = tandemPackages.filter(pkg => pkg.item === key)[0].weekendPrice;
      }

      var count = val[key];
      totalTandemCost += price * count;
      let isTaxble;
      tandemPkgArr.map(pack => {

        if (pack.item === key) {
          isTaxble = pack.isTaxable;
        }
      })

      selectedTandemPackages.push({ name: key, count: val[key], price: price * val[key], pkgActualPrice: pkgActualPrice * val[key], pkgWeekendprice: pkgWeekendprice * val[key], isTaxble: isTaxble });
    })

    this.setState({ selectedTandemPackages: selectedTandemPackages, tandemPkgArr }, () => {
      {
        const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
        this.setState({ ...priceObj })
      }
    });

  };




  handleVideoUpdate = (val, e, vidPkgArr) => {
    if (val === undefined) {
      val = 0;
    }

    var counts = Object.values(val);

    var totalCount = counts.reduce(function (a, b) {
      return a + b;
    }, 0);

    var videoPackages = tandemStore.getVideoPackages();

    var totalVideoCost = 0;

    var selectedVideoPackages = [];
    // console.log(val,"<=======val")
    Object.keys(val).forEach(key => {
      var price = window.ENV.dontApplyVideoCharge ? 0 : videoPackages.filter(pkg => pkg.item === key)[0].videoPrice;
      var count = val[key];
      totalVideoCost += price * count;

      let isTaxble;
      vidPkgArr?.map(videoPack => {
        if (videoPack.item === key) {
          isTaxble = videoPack?.isTaxable;
        }
      })
      selectedVideoPackages.push({ name: key, count: val[key], price: price * val[key], isTaxble: isTaxble });
    })
    this.setState({ videoCount: totalCount, videoCost: totalVideoCost, selectedVideoPackages: selectedVideoPackages, vidPkgArr }, () => {
      const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
      this.setState({ ...priceObj })
    }
    )


  };

  handleTandemCoupon = (price) => {
    if (price === 0) {
      this.setState({  tandemCouponZeroValued: true })
    }
    this.setState({tandemCoupon:true,tandemCouponFdata:true,selectedVideoPackages:[],selectedTandemPackages:[],payType:"tandemCoupon",deposit:false})
  }


  handleDeposit = async(val) => {

    const response = await tandemData.getEvents()
    const selectedEvent = response?.data.find(event=>{
      if (event.eventName === this.state.namedEvent && event.depositAmount > 0){
        return event;
      }
    })

   
    if(selectedEvent){
      console.log(selectedEvent,"<====selectedEvent")
      this.setState({tandemEventDepositAmount:selectedEvent.depositAmount})
    }
    

    this.setState({
      discount: undefined,
      discountValue: 0
    })
    if (this.state.manualGiftCard && this.state.payType === "giftCard") {
      this.setState({ validateTandemPack: true, selectedTandemPackages: [], selectedVideoPackages: [] })
    }
    this.setState({ deposit: val, manualGiftCard: false, giftCard: false, payType: 'deposit', couponCode: '', ticketDetails: null, giftPackage: null,tandemCouponFdata:false,tandemCoupon:false },
      () => {
        const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
        this.setState({ ...priceObj })
      });

  }


  handleFullDeposit = (val) => {
    if (val === 'nopay') {
      this.setState({ giftCard: false, payType: 'nopay', manualGiftCard: false, totalAmount: 0, couponCode: '', ticketDetails: null },
        () => {
          const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
          this.setState({ ...priceObj })
        }
      );
    } else {
      if (this.state.manualGiftCard && this.state.payType === "giftCard") {
        this.setState({ validateTandemPack: true, selectedTandemPackages: [], selectedVideoPackages: [] })
      }
      this.setState({ deposit: val, giftCard: false, payType: 'full', manualGiftCard: false, couponCode: '', ticketDetails: null, giftPackage: null,tandemCouponFdata:false,tandemCoupon:false},
        () => {
          const priceObj = CalculatePrice(this.state, this.getPrice, this.props)
          this.setState({ ...priceObj })
        })
    }

  }

  handleGiftCard = (val, data) => {
    this.setState({ payType: 'giftCard' ,tandemCoupon:false})
  }

  getPrice(priceSchedule, type) {
    var index = priceSchedule.findIndex(x => x.type == type);
    var price = priceSchedule[index].price;
    return price;
  }



  CalculatePrice2(ticket, video, deposit) {
    const { isWeekEnd } = this.state;

    var priceSchedule = tandemStore.priceSchedule;
    var videoPackages = tandemStore.getVideoPackages();


    var tandemWeekend = this.state.loadPrices.tandemPackages[0].weekendPrice;
    var tandemWeekday = this.state.loadPrices.tandemPackages[0].price;

    var tandemVideoCost = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    var AffFee = tandemStore.getFirstJump().price;

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;


    if (this.state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }

    else if (this.state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    var totalVideoCost = 0;
    Object.keys(video).forEach(key => {
      var price = videoPackages.filter(pkg => pkg.item === key)[0].videoPrice;
      var count = video[key];
      totalVideoCost += price * count;
    })

    if (deposit === true) {
      tandemUnitCost = this.state.tandemEventDepositAmount;
      var taxValue =
        tandemUnitCost * ticket +
        totalVideoCost;
      var taxPrice = (taxPerc / 100) * taxValue;


      var subTotal =
        tandemUnitCost * ticket +
        totalVideoCost;

      var totalPrice = subTotal + taxPrice;
      if (this.props.location.pathname === "/phone") {
        totalPrice = subTotal;
      }
      this.setState({ totalAmount: totalPrice, totalFees: taxPrice })
    }
    else {
      var taxValue =
        tandemUnitCost * ticket +
        totalVideoCost;
      var taxPrice = (taxPerc / 100) * taxValue;


      var subTotal =
        tandemUnitCost * ticket +
        totalVideoCost;

      var totalPrice = subTotal + taxPrice;

      if (this.props.location.pathname === "/phone") {
        totalPrice = subTotal;
      }

      this.setState({ totalAmount: totalPrice, totalFees: taxPrice })

    }
    this.finalFormRef.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
  componentWillMount = () => {



    tandemStore.on('childFormUpdate', this.handleFormData);

    var d = new moment();
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    Api.getMonthlySlots({ start: startMonth, end: endMonth })
      .then(this.updateAllowedDates);

    var profileId, merchantProfileId, serviceID;


    var data = [];
    var that = this;
    Promise.all([tandemStore.loadDetails(), tandemStore.loadSettings()])
      .then(res => {
        if (res[0] !== undefined) {
          Promise.all([tandemStore.loadPrices()])
            .then(function (result) {
              data = result[0];

              that.setState({ loadPrices: data });
              let params = new URLSearchParams(window.location.search).get('reservation');
              if (params != undefined) {
                tandemStore.reservationId = params;
                that.HandleAutoFillValue();
              }
            })
        }
      })
      .catch(err => {
        console.log(err);
      })

  }

  hideLoader = () => {
    if (this.state.isShowLoader) {

      let initialloader = document.querySelector(".shop-page-loader");
      if (initialloader) {
        initialloader.classList.add('fade-out-loader');
        let time = setTimeout(() => {
          this.setState({
            isShowLoader: false,
          })
          initialloader.classList.remove('fade-out-loader');
          clearTimeout(time);
        }, 500)
      }
    }
  }

  handleToken = (r) => {

    if (r.data) {
      var token = r.data.slice(1, -1);

      tandemStore.setTokenVal(token);
    } else {
      alert('Something went wrong Please reload, Some of the services are not available');
    }
  }

  updateAvailableDatesSlots = (value) => {
    var d = new moment(value.activeStartDate);
    var startMonth = d.clone().startOf('month').format("YYYY-MM-DD");
    var endMonth = d.clone().endOf('month').format("YYYY-MM-DD");
    Api.getMonthlySlots({ start: startMonth, end: endMonth }).then(this.updateAllowedDates)
  }

  validateEmail = (value) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  handleFormData = (passengers) => {

    // debugger;
    if (passengers.target && passengers.target.className.indexOf('checkoutButton') > -1) {

      this.setState({ chkFormVal: true });
      var cache = { ...this.state };
      cache.finalForm = tandemStore.getAll().finalForm;
      this.setState({ ...cache });
      var passed = 0;

      var ticketCount = this.state.ticketCount;

      if (this.state.grouponPackage !== null) {
        ticketCount = this.state.grouponPackage.numTickets;
      }
      else if (this.state.giftPackage !== null) {
        ticketCount = this.state.giftPackage.tickets;
      }

      var count = ticketCount;

      if (this.props.location.pathname === "/phone") {
        count = 1;
      }

      for (let i = 0; i < count; i++) {

        var fdata = cache.finalForm.passengers[i];

        if (this.state.EventName === "AFF") {
          if (fdata)
            fdata.weight = true;
        }

        if (this.state.tandemCouponFdata) {
          if (fdata)
            fdata.weight = true;
        }

        if (this.state.giftCard) {
          fdata.weight = true;
        }

        if (fdata && fdata.firstname && fdata.lastname
          &&
          (i == 0 && fdata.email && this.validateEmail(fdata.email) || i > 0)
          && (i == 0 && fdata.phone || i > 0)
          && (i == 0 && fdata.errors.phone == "" || i > 0)
          && fdata.adult && fdata.weight
          && ((this.state.EventName != "AFF")
            ?
            !!this.state.giftCodeEmail ? true : false ||
              this.props.location.pathname === '/phone' ? true : false ||
                this.props.location.pathname === '/groupon' ? true :false ||            
                  this.props.location.pathname === '/rush49' ? true
              :
              !(!!this.state.dicountedTandemPackage) ? fdata.errors.tandemPackage === "" : true
              : true
          )
        ) {
          if (this.state.manualGiftCard) {
            handleReservation(this.state, this.setState.bind(this), this.props)
            return
          }
          else if (this.state.payType === 'nopay') {
            handleReservation(this.state, this.setState.bind(this), this.props);
            return;
          }



          passed = passed + 1;



          if (passed == count) this.setState({ enablePurchase: true }, () => { this.handleSummaryButton() });


          else this.setState({ chkFormVal: true });
        }
        else {
          this.setState({ enablePurchase: false });
          this.setState({ chkFormVal: true });
        }
      }
    } else {
      return;
    }
  };

  handleSummaryButton = () => {

    if (window.ENV.facebooktrackingID) {
      window.fbq('track', 'InitiateCheckout');
    }

    if (window.ENV.gtmId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'beginCheckout',
          data: window.ENV.gtmId
        }
      })
    }

    let { grouponPackage, giftPackage, totalAmount, enablePurchase, giftCard } = this.state;


    if ((grouponPackage !== null || giftPackage !== null) && totalAmount === 0) {

      handleReservation(this.state, this.setState.bind(this), this.props)
    }
    else if(this.state.tandemCoupon && this.state.totalAmount === 0){
      handleReservation(this.state, this.setState.bind(this), this.props);
    }
    else if (enablePurchase && !giftCard) {
      if (window.ENV.processorInfo.processor !== 'authorize') {
        if (window.ENV.processorInfo.processor == "nab") {
          document.getElementsByClassName("velocity-button")[0].click();
        }
        else if (window.ENV.processorInfo.processor == "cardConnect") {
          window.initializeCardConnect();
          var modal = document.getElementById("myModalCardConnect");
          modal.style.display = "block";
          window.reservationComponent = this;
        }
        else {

          window.transactionUI();
          var modal = document.getElementById("myModal");
        

          var btn = document.getElementById("myBtn");


          var span = document.getElementsByClassName("close")[0];


          modal.style.display = "block";

          span.onclick = function () {
            modal.style.display = "none";
          }
          window.reservationComponent = this;


          window.onclick = function (event) {
            if (event.target == modal) {
              modal.style.display = "none";
            }
          }

        }

      }
      else {
        this.setState({ isModalaAuthorize: !this.state.isModalaAuthorize })
      }
    }
    else {
      const { finalForm, EventName, isWeekEnd } = this.state;

      var eventType = '';
      if (EventName === 'Tandem') {
        eventType = isWeekEnd ? 'weekend Tandem' : 'weekday Tandem'
      }
      else {
        eventType = EventName;
      }


      var payload = {
        "code": this.state.cardCode.trim(),
        "lastname": finalForm.passengers[0].lastname,
        "firstname": finalForm.passengers[0].firstname,
        "giftType": eventType
      }

      tandemStore.VerifyGiftCard(payload).then((res) => {
        if (res !== false) {
          handleReservation(this.state, this.setState.bind(this), this.props);
          var payload = {
            cardId: res[0]._id,
            isUsed: true
          }
          tandemStore.UpdateGiftCard(payload);
        }
        else {

          this.setState({
            errorMessage: true
          });

          setTimeout(() => {
            this.setState({
              errorMessage: false
            })
          }, 5000)
        }
      }).catch(ex => {
        console.log(ex);
      });
    }
  };
  isDisablePayBtn = () => {
    let { TandC, selectedTandemPackages, seatsError } = this.state;

    let value = !TandC && this.props.location.pathname !== "/phone";
    
    if (this.state.EventName == "AFF" || "Tandem" && (TandC || this.props.location.pathname === "/phone") && !seatsError) {
      return false;
    }
    else if (selectedTandemPackages.length <= 0 || value) {

      return true;
    }
    else {
      return false;
    }

  }
  handleCardCode = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  removeError = () => {
    this.setState({ seatsError: false })
  }

  validateTickets = (tNums) => {
    if (this.state.ticketCount > tNums) {
      swal({
        title: "Warning!",
        text: "Cannot Book more than available seats",
        icon: "error",
        dangerMode: false,
      })
      this.setState({ seatsError: true })
    }
    else {
      this.setState({ seatsError: false })
    }
  }

  renderButton = () => {
    if (this.state.ticketCount && this.state.ticketCount > 0) {
      console.log(this.state.giftCard, "<====this.state.giftCard|")
      return (
        <div style={{ margin: 'auto' }}>
          {this.state.giftCard || (this.state.tandemCoupon && this.state.totalAmount === 0) || this.state.payType === 'nopay'
            ?
            <div className="row">

              <div className="col-md-12">
                <button
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Reserve Now
                </button>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-md-12">
                <button
                  disabled={this.isDisablePayBtn()}
                  className="checkoutButton btn btn-primary btn-lg"
                  onClick={this.handleFormData}
                >
                  Pay Now
                </button>
              </div>
            </div>
          }
        </div>
      );
    }
    else if (this.state.grouponPackage !== undefined) {
      return (
        <div className="row">
          <div className="col-md-12">
            <button
              disabled={!this.state.TandC && this.state.seatsError}
              className="checkoutButton btn btn-primary btn-lg"
              onClick={this.handleFormData}
            >
              {this.state.videoCount || this.state.groupOnPackPrice > 0 ? "Pay Now" : "Reserve Now"}
            </button>
          </div>
        </div>
      )
    }
    else if (this.state.giftPackage !== undefined) {
      return (
        <div className="row">
          <div className="col-md-12">
            <button
              disabled={!this.state.TandC && this.state.seatsError}
              className="checkoutButton btn btn-primary btn-lg"
              onClick={this.handleFormData}
            >
              Reserve Now
            </button>
          </div>
        </div>
      )
    }
  };


  renderGrouponPackage = () => {
    return (
      <div className="col-md-12">
        <div className="card cardOverRide">
          <div className="card-header">PACKAGE</div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ textAlign: 'center' }}>Count</th>

              </tr>
            </thead>
            <tbody>
              <tr><td>{this.state.grouponPackage.tandemPackageName}</td><td>{this.state.grouponPackage.numTickets}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderGiftPackage = () => {
    return (
      <div className="col-md-12">
        <div className="card cardOverRide">
          <div className="card-header">GIFT PACKAGE</div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ textAlign: 'center' }}>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.giftPackage.item}</td><td>{this.state.giftPackage.tickets}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderTotal = (totalPaid) => {
    const { discountValue, deposit, totalAmount, dicountedTandemPackage, ticketCount, totalTax, selectedVideoPackages } = this.state;
    let totalPayVideo = 0;
    selectedVideoPackages.forEach(pkg => totalPayVideo += pkg.price)
    if (!!dicountedTandemPackage) {
      return (
        <td id="amountTotal" style={{ textAlign: 'right' }}>
          ${((dicountedTandemPackage.price * (ticketCount)) + (totalPaid) + (totalTax) + totalPayVideo).toFixed(2)}
        </td>
      )
    } else {
      return (
        !deposit
          ? <td id="amountTotal" style={{ textAlign: 'right' }}>
            ${(totalAmount.toFixed(2) - discountValue.toFixed(2)).toFixed(2)}
          </td>
          : <td id="amountTotal" style={{ textAlign: 'right' }}>
            ${totalAmount.toFixed(2)}
          </td>
      )
    }
  }


  renderInvoice = () => {
    const { isWeekEnd, totalTax, payType, ticketCount, affValue,
      loadPrices, EventName, grouponPackage, giftPackage, selectedTandemPackages
      , selectedVideoPackages, totalFees, discount, discountValue, deposit, totalAmount, dicountedTandemPackage
    } = this.state;

    console.log(this.state, "<============invoice state")
    var priceSchedule = tandemStore.priceSchedule;
    var tandemWeekend = loadPrices.tandemPackages[0].weekendPrice;
    var tandemWeekday = loadPrices.tandemPackages[0].price;
    var tandemVideoCost = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    var AffFee = tandemStore.getFirstJump().price;
    let selectedGrouponPack = {}
    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }



    return (
      <div className="col-md-12">
        <div className="card cardOverRide">
          <div className="card-header">
            <p className="header">ORDER SUMMARY</p>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ textAlign: 'center' }}>Count</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>

              {
                EventName === "AFF" ?
                  <tr>
                    <td>AFF First Jump Course</td>
                    <td style={{ textAlign: 'center' }}>{ticketCount}</td>
                    <td style={{ textAlign: 'right' }}>${affValue.toFixed(2)}</td>
                  </tr>
                  : null
              }
              {
                grouponPackage !== null ?

                  <tr>
                    <td>{grouponPackage.tandemPackageName}</td>
                    <td style={{ textAlign: 'center' }}>{grouponPackage.numTickets}</td>
                    <td style={{ textAlign: 'right' }}>${this.state.groupOnPackPrice * grouponPackage.numTickets}</td>
                  </tr>
                  : null
              }
              {
                giftPackage !== null ?

                  <tr>
                    <td>{giftPackage.tandemPackageName}</td>
                    <td style={{ textAlign: 'center' }}>{giftPackage.tickets}</td>
                    <td style={{ textAlign: 'right' }}>$0.00</td>
                  </tr>
                  : null
              }
              {
                giftPackage !== null && giftPackage.videoPackageName !== null && giftPackage.videoPackageName !== undefined && giftPackage.videoPackageName !== 'none' ?

                  <tr>
                    <td>{giftPackage.videoPackageName}</td>
                    <td style={{ textAlign: 'center' }}>{giftPackage.tickets}</td>
                    <td style={{ textAlign: 'right' }}>$0.00</td>
                  </tr>
                  : null
              }

              {
                !!dicountedTandemPackage ?
                  <tr>
                    <td>{dicountedTandemPackage.item}</td>
                    <td style={{ textAlign: 'center' }}>{ticketCount}</td>
                    <td style={{ textAlign: 'right' }}>${(dicountedTandemPackage.price * (ticketCount)).toFixed(2)}</td>
                  </tr>
                  :
                  !this.state.giftCard && !this.state.tandemCoupon ? selectedTandemPackages.map(pkg => {
                    return (
                      <tr>
                        {/* {console.log(pkg.count,this.state.tandemEventDepositAmount,"<=======pkg.count*this.state.tandemEventDepositAmount")} */}
                        <td>{pkg.name}</td>
                        <td style={{ textAlign: 'center' }}>{this.state.phone ? ticketCount : pkg.count}</td>
                        <td style={{ textAlign: 'right' }}>${this.state.phone ?
                          !deposit ?
                            this.state.isWeekEnd ? (ticketCount * pkg.pkgWeekendprice)?.toFixed(2) : (ticketCount * pkg.pkgActualPrice)?.toFixed(2)
                            : (ticketCount * this.state.tandemEventDepositAmount)?.toFixed(2)
                          :
                          !deposit ?
                            this.state.isWeekEnd ? (pkg.pkgWeekendprice)?.toFixed(2) : (pkg.pkgActualPrice)?.toFixed(2)
                            : (pkg.count * this.state.tandemEventDepositAmount)} </td>
                      </tr>
                    )
                  }) : ""

              }
              {
                !this.state.giftCard && !this.state.tandemCoupon ? selectedVideoPackages.map(pkg => {
                  return (
                    <tr>
                      <td>{pkg.name}</td>
                      <td style={{ textAlign: 'center' }}>{pkg.count}</td>
                      <td style={{ textAlign: 'right' }}>${(pkg.price).toFixed(2)}</td>
                    </tr>
                  )
                }) : ""
              }

             
              { this.state.tandemCoupon && this.state.payType === "tandemCoupon" &&

                  <tr>
                    <td>{this.state.couponedTandemPack.item}</td>
                    <td style={{ textAlign: 'center' }}>{this.state.ticketCount}</td>
                    <td style={{ textAlign: 'right' }}>${this.state.isWeekEnd ? (this.state.couponedTandemPack.weekendPrice*this.state.ticketCount).toFixed(2):
                    (this.state.couponedTandemPack.price*this.state.ticketCount).toFixed(2)}</td>
                  </tr>
                
              }

              { this.state.tandemCoupon && this.state.payType === "tandemCoupon" && this.state.couponedTandemPack.videoType !== "none" &&

                  <tr>
                    <td>{this.state.couponedTandemPack.videoType}</td>
                    <td style={{ textAlign: 'center' }}>{this.state.ticketCount}</td>
                    <td style={{ textAlign: 'right' }}>$0.00</td>
                  </tr>
                
              }

              {this.props.location.pathname !== "/phone" && !!totalFees ?
                <tr>
                  <td>Fees</td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>{
                    !!dicountedTandemPackage ?
                      `$${((this.state.taxPerc / 100) * dicountedTandemPackage.price * ticketCount).toFixed(2)}`
                      :
                      `$${totalFees.toFixed(2)}`}
                  </td>
                </tr> : null
              }


              {
                discount && !deposit && (
                  !!dicountedTandemPackage ? null :
                    <tr>
                      <td>Discount</td>
                      <td></td>
                      <td style={{ textAlign: 'right' }}>- ${discountValue.toFixed(2)}</td>
                    </tr>
                )
              }
              {!!totalTax && <TotalTax totalTax={totalTax} payType={payType} length={selectedVideoPackages.length} />}

              <tr>
                <td>TOTAL :</td>
                <td></td>
                {this.renderTotal(
                  !!dicountedTandemPackage && ((this.state.taxPerc / 100) * dicountedTandemPackage.price * ticketCount)
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  checkDateAvailability = ({ date }) => {
    let d = new moment(date);
    const { eventFilter, allowedDates } = this.state;
    let evaluatedDates = allowedDates;
    if (eventFilter !== 'All') {


      if (window.ENV.disableSameDayBooking)
        evaluatedDates = this.events ? this.events.filter(x => x.isBlackedOut === false && x.eventName === eventFilter).map(elem => elem.datetime.split('T')[0]) : [];
      else {
        let sd = new moment();
        var startDate = sd.clone().startOf("day").format("YYYY-MM-DD");
        evaluatedDates = this.events ? this.events.filter(x => x.isBlackedOut === false && x.eventName === eventFilter && x.datetime.split('T')[0] != startDate).map(elem => elem.datetime.split('T')[0]) : [];
      }
    }
    return (evaluatedDates.indexOf(d.format("YYYY-MM-DD")) == -1)
  }

  changeTC = (v) => {
    this.setState({
      TandC: v
    })
    if (v) {
      this.setState({
        isModalOpen: true
      })
    }
  }
  onSuccessHandler = (response, formValue) => {
    this.setState({ isModalaAuthorize: false })
    this.handleReservationAuthorize(response)
  }

  onErrorHandler = (response) => {
    this.setState({ isModalaAuthorize: false })
    swal({
      title: "Error",
      text: "something failed, please try again",
      icon: "error",
      dangerMode: true,
    });
  }


  onFilterChange = (selectedOption, category) => {
    this.setState({
      eventFilter: selectedOption,
    })

  }

  returnEvents = () => {
    let names = []
    const slotsArray = [...this.state.slotDates]

    slotsArray.forEach(val => {
      let category = val.eventCategory
      if (val.eventName == 'Tandem') val.eventName = 'Tandem Jump'
      if (names.findIndex(n => n.name === val.eventName) === -1) {
        if (val.eventName === 'Tandem') category = 'Tandem'
        if (val.eventName === 'AFF') category = 'AFF'
        names.push({ name: val.eventName, category })
      }
    })
    return (
      this.state.selectedDate !== undefined ?
        names.map(val => {
          console.log(val,"<======val.........")
          let pic;
          let eventName = val.name
          if (val.name === 'Tandem') eventName = 'Tandem Jump'
          if (val.name === 'AFF') eventName = 'AFF First Jump Course'

          if (val.category === 'Tandem') {
            pic = tandempic
          }
          else if (val.category === 'AFF') {
            pic = affpic
          } else {
            pic = tandempic
          }
          return (<div>
            <Media className="tandem-date-show">
              <Media body>
                <button className="btn btn-default fontNoColor" style={{ background: '#c6dee6', color: '#013e8b' }}>
                  {eventName}
                </button>
                {this.specialRenderSlotSelector(val)}
              </Media>
              <Media right top href="#">
                <Media object src={pic} style={{ maxHeight: 256, maxWidth: 256 }} alt="Generic placeholder image" />
              </Media>
            </Media>
            <Hr />
          </div>
          )
        })
        :
        null
    )
  }


  handleGiftCodeInvalid = (val) => {
    this.setState({ isGiftCodeValid: val })
  }

  handleHaveGiftcardCode = (val) => {
    this.setState({ haveGiftCardCode: val })
  }

  renderMainForm = (finalForm) => {

    // if(this.state.isGiftCodeValid===false){
    //   return
    // }
    var tandemPackages = [];
    var videoPackages = [];
    if (this.state.loadPrices !== undefined && this.props.location.pathname !== "/groupon" && this.props.location.pathname !== "/rush49") {
      tandemPackages = this.state.loadPrices.tandemPackages.filter(pkg => pkg.isBookable).map(pkg => {
        if (pkg.weekendPrice !== pkg.price) {
          return (
            <React.Fragment>
              <li>
                <p>{pkg.item} weekday ${pkg.price} </p>
              </li>
              <li>
                <p>{pkg.item} weekend ${pkg.weekendPrice} </p>
              </li>
            </React.Fragment>
          )
        }
        else {
          return (
            <li>
              <p>{pkg.item} ${pkg.price} </p>
            </li>
          )
        }
      })

      var tandemPackages1 = this.state.loadPrices.tandemPackages.filter(pkg => pkg.isBookable).map((pkg, i) => {
        if (pkg.weekendPrice !== pkg.price) {
          return (
            <React.Fragment>
              <div className="marginBwAccordion">
                <div>
                  <p className="right-side-info">
                    {pkg.item} weekday {!this.props.giftcardReservation ? <>$ {pkg.price}</> : " "}
                  </p>
                </div>
                {
                  pkg.description && pkg.description != '' &&
                  <Card.Body style={{ padding: "0px 0 10px 0" }}>{pkg.description}</Card.Body>
                }
              </div>
              <div className="marginBwAccordion">
                <div>
                  <p className="right-side-info">
                    {pkg.item} weekend {!this.props.giftcardReservation ? <>$ {pkg.weekendPrice}</> : " "}
                  </p>
                </div>
                {
                  pkg.description && pkg.description != '' &&
                  <Card.Body style={{ padding: "0px 0 10px 0" }}>{pkg.description}</Card.Body>
                }
              </div>
            </React.Fragment>
          )
        }
        else {
          return (
            <div className="marginBwAccordion">
              <div>
                <p className="right-side-info">
                  {pkg.item} {!this.props.giftcardReservation ? <>$ {pkg.price}</> : " "}
                </p>
              </div>
              {
                pkg.description && pkg.description != '' &&
                <Card.Body style={{ padding: "0px 0 10px 0" }}>{pkg.description}</Card.Body>
              }

            </div>
          )
        }
      })
    }

    if (this.state.loadPrices !== undefined) {

      videoPackages = this.state.loadPrices.videoPackages.filter(pkg => pkg.isBookable).map((pkg, i) => {
        return (

          <div className="marginBwAccordion">

            <div>
              <p className="right-side-info">
                <p className="videoAdvertisement">Check Video</p>
                {pkg.item}  {!this.props.giftcardReservation ? <>$ {pkg.videoPrice}</> : " "}
              </p>
            </div>

            {pkg.videoLink ?
              <Card.Body style={{ padding: "0px 0 10px 0" }}>
                <div className="videoContainer">
                  <ReactPlayer height="100%" width="100%" url={pkg.videoLink} />
                </div>
              </Card.Body>
              : ""}



            {pkg.description ?
              <Card.Body style={{ padding: "0px 0 10px 0" }}>{pkg.description}</Card.Body>
              : ""
            }
          </div>


        )

      })
    }




    var ticketCount = 0;

    {
      console.log(this.props.giftcardReservation, "<======this.props.giftcardReservation")
      console.log(this.state.giftPackage, "<=====this.state.giftPackage")
    }

    if ((this.props.location.pathname === "/rush49" || this.props.location.pathname === "/groupon") && this.state.grouponPackage !== null) {
      ticketCount = this.state.grouponPackage.numTickets;
    }
    else if (this.props.giftcardReservation && this.state.giftPackage !== null) {
      console.log(this.state.giftPackage.tickets, "<========this.state.giftPackage.tickets")
      ticketCount = this.state.giftPackage.tickets;
    }
    else {
      ticketCount = this.state.ticketCount;
    }
    var type = "regular";

    if (this.props.location.pathname === "/groupon" || this.props.location.pathname === "/rush49") {
      type = "groupon"
    }
    else if (this.props.giftcardReservation) {
      type = "gift";
    }

    return (
      <div className="row" style={{ marginBottom: 50 }}>
        {
          this.props.location.pathname === "/" || this.props.location.pathname === "/phone" || this.props.giftcardReservation ?
            <img
              style={{
                paddingBottom: "10px", width: window.ENV.logoWidth, height: window.ENV.logoHeight, left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto'
              }
              }
              src={window.ENV.logoUrl} />
            : null
        }
        {
          this.props.location.pathname === "/plantcity" ?
            <img
              style={{
                paddingBottom: "10px", width: "400px", left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto'
              }
              }
              src={window.ENV.site1logoUrl}
            /> : null
        }
        {
          this.props.location.pathname === "/lakewales" ?
            <img
              style={{
                paddingBottom: "10px", width: "400px", left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto'
              }
              }
              src={window.ENV.site2logoUrl}
            /> : null
        }
        {
          this.props.location.pathname === "/groupon" ?
            <React.Fragment>
              <img
                style={{
                  paddingBottom: "10px", width: window.ENV.logoWidth, height: window.ENV.logoHeight, left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }
                }
                src={window.ENV.logoUrl} />
              <img
                style={{
                  paddingBottom: "10px", width: "400px", left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }
                }
                src={window.ENV.grouponUrl}
              />
            </React.Fragment> : null
        }
        {
          this.props.location.pathname === "/rush49" ?
            <React.Fragment>
              <img
                style={{
                  paddingBottom: "10px", width: window.ENV.logoWidth, height: window.ENV.logoHeight, left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }
                }
                src={window.ENV.logoUrl} />
              <img
                style={{
                  paddingBottom: "10px", width: "400px", left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }
                }
                src={window.ENV.rush49Url}
              />
            </React.Fragment> : null
        }
        <h4
          className="text-center text-heading"
          style={{
            fontWeight: 400,
            letterSpacing: 5,
            backgroundColor: '#00419d',
            color: 'white',
            width: '100%',
            paddingTop: 30,
            paddingBottom: 30,
            padding: '60px'
          }}
        >
          SCHEDULE YOUR JUMP
        </h4>
        {
          this.props.location.pathname === "/groupon" || this.props.location.pathname === "/rush49"?
            <div className="col-md-12 p-4 containerPadding" style={{ position: 'relative' }}>
              <div className="row">
                <div className="col-md-2">
                  <span className="customHeading">ENTER CODE</span>
                </div>
              </div>
              <div className="row">
                <DiscountInput
                  onChange={this.handleGrouponPackageVerified}
                  type="groupon"
                  text="enter code"
                  isCleared={this.state.isClearGroupon}
                  setClearGroupon={this.setClearGroupon}
                />
              </div>
              {this.state.grouponPackage !== null && this.state.grouponPackage.packageName !== undefined ?
                <div className="row">
                  {this.renderGrouponPackage()}
                </div>
                : !this.state.isValidGrouponCode &&
                <div className="col-md-12">
                  <div className="card cardOverRide">
                    <div className="card-header text-danger error-validation textSize">Invalid Code !!
                    </div></div></div>
              }
            </div> :
            null
        }
        {
          this.props.giftcardReservation ?
            <div className="col-md-12 p-4 containerPadding" style={{ position: 'relative' }}>
              <div className="row">
                <div className="col-md-2">
                  <span className="customHeading">ENTER GIFT CODE</span>
                </div>
              </div>
              <div className="row">
                <DiscountInput
                  onChange={this.handleGiftPackageVerified}
                  type="gift"
                  text="enter gift code"
                  giftCode={this.state.giftCodeEmail}
                  isCleared={this.state.isClearGroupon}
                  setClearGroupon={this.setClearGroupon}
                  handleGiftCodeInvalid={this?.handleGiftCodeInvalid}
                  handleHaveGiftcardCode={this.handleHaveGiftcardCode}
                />
              </div>
              {/* {console.log(this.state.haveGiftCardCode,"<<<<<this.state.haveGiftCardCode")} */}
              {this.state.giftPackage !== null && this.state.giftPackage.item !== undefined && this.state.haveGiftCardCode ?
                <div className="row">
                  {this.renderGiftPackage()}
                </div>
                : this.state.haveGiftCardCode && !this.state.isGiftCodeValid ? <div className="col-md-12">
                  <div className="card cardOverRide">
                    <div className="card-header text-danger error-validation textSize">Invalid Giftcard Code !!
                    </div></div></div> : ""
              }
            </div> :
            null
        }

        <div className="col-md-12 p-4 containerPadding" style={{ position: 'relative' }}>

          <div className="row">
            <div className="col-md-2">
              <span className="customHeading">SELECT JUMP</span>
            </div>
            <div className="col-md-10">
              <div className="row">
                <JumpTypeButton onFilterChange={this.onFilterChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <span className="customHeading">SELECT DATE</span>
            </div>

          </div>


          <Modal
            isOpen={this.state.isModalaAuthorize}
            style={{ zIndex: '10503434', minWidth: "40%" }}>
            <ModalHeader style={{ width: "100%" }}>PAYMENT
              <span
                className="ModalCloseButton"
                style={{ backgroundColor: 'white', fontSize: '18px', color: 'grey' }}
                onClick={() => this.setState({ isModalaAuthorize: false })}
              >
                Close
              </span>
            </ModalHeader>
            <ModalBody  >
              <FormContainer
                environment={window.ENV.evironment}
                onError={this.onErrorHandler}
                onSuccess={this.onSuccessHandler}
                amount={this.state.discount ? this.state.totalAmount - this.state.discountValue : this.state.totalAmount}
                component={FormComponent}
                clientKey={window.ENV.clientKey}
                apiLoginId={window.ENV.apiLoginId}
              />
            </ModalBody>
          </Modal>

          <Calendar
            minDate={new Date()}
            onChange={this.onCalendarChange}
            value={this.state.date}
            activeStartDate={this.state.currentMonthDate}
            minDetail="month"
            onActiveDateChange={this.updateAvailableDatesSlots}
            tileDisabled={this.checkDateAvailability}

          />

          <div>

            <Accordion className={`perticketPrice ${this.state.selectedDate !== undefined && 'active'}`}>
              {tandemPackages1}
              {videoPackages}
              {this.state.loadPrices !== undefined && this.props.location.pathname !== "/groupon" && this.props.location.pathname !== "/rush49" && !window.ENV.dontShowAFF && <React.Fragment>
                <div>
                  <div>
                    <Accordion.Toggle as={Button} eventKey={(tandemStore.getFirstJump().type === "STP" || tandemStore.getFirstJump().type === "ASP") ? tandemStore.getFirstJump().calenderEvent : tandemStore.getFirstJump().type + '' + tandemStore.getFirstJump().price} style={{ width: '100%' }}>

                      {tandemStore.getFirstJump().type === "STP" || tandemStore.getFirstJump().type === "ASP" ?
                        <>{tandemStore.getFirstJump().name}       {!this.props.giftcardReservation ? <>$ {tandemStore.getFirstJump().price}</> : " "}</>
                        : <>{tandemStore.getFirstJump().calenderEvent}   {!this.props.giftcardReservation ? <>$ {tandemStore.getFirstJump().price}</> : " "}</>
                      }
                    </Accordion.Toggle>
                  </div>
                  {


                    tandemStore.getFirstJump().description && tandemStore.getFirstJump().description != '' &&
                    <Accordion.Collapse eventKey={(tandemStore.getFirstJump().type === "STP" || tandemStore.getFirstJump().type === "ASP") ? tandemStore.getFirstJump().name : tandemStore.getFirstJump().calenderEvent + '' + tandemStore.getFirstJump().price}>
                      <Card.Body>{tandemStore.getFirstJump().description}</Card.Body>
                    </Accordion.Collapse>
                  }

                </div>
              </React.Fragment>}
            </Accordion>

          </div>


        </div>
        {this.state.isGiftCodeValid ?
          <div className="col-md-12 p-4 containerPadding" ref={ref => this.SELECTTIME = ref}>
            {
              this.state.slotDates.length > 0 && (
                <div className="dateSelectector">
                  <hr />
                  <span className="customHeading">SELECT TIME</span> &nbsp;&nbsp;&nbsp;



                  <span className="customHeading">
                    {moment(this.state.selectedDate).format("DD MMMM YYYY")}
                  </span>



                </div>
              )
            }
            <div className="tandumJumpmg">


              {this.returnEvents()}
            </div>
          </div>

          : ""}

        <div ref={ref => this.timeslotIdref = ref}>
          {console.log(this.state, "<=========ticketCount")}
          {this.state.slotDates.length > 0 &&
            finalForm.timeslotId && (
              <TicketForm
                tandemEventDepositAmount={this.state.tandemEventDepositAmount}
                reservationComponentRef={this.finalFormRef}
                className="containerPadding"
                seatsAvailable={this.state.seatsAvailable}
                handleTandemCoupon={this.handleTandemCoupon}
                handleTicketUpdate={(number, details, tandemPack, updateticket) => this.handleTicketUpdate(number, details, tandemPack, updateticket)}
                ticketNumber={ticketCount}
                validateTickets={this.validateTickets}
                removeError={this.removeError}
                seatsError={this.state.seatsError}
                tandemEventDepositAmount = {this.state.tandemEventDepositAmount}
                handleDeposit={this.handleDeposit}
                depositOnly={window.ENV.depositOnly}
                handleFullDeposit={this.handleFullDeposit}
                handleGiftCard={this.handleGiftCard}
                type={type}
                addMemoMessage={(val) => this.addMemoMessage(val)}
                payType={this.state.payType}
                validatedGiftCard={(couponCode, data) => this.validatedGiftCard(couponCode, data)}
              />
            )}
        </div>
        <div ref={ref => this.finalFormRef = ref} style={{ width: "100%" }} >
          {console.log(this.state, "<=========ticketCount")}
          {(!this.state.seatsError && finalForm.timeslotId) && (
            <PassengerForm
              couponedTandemPack={this.state.couponedTandemPack}
              ticketDetails={this.state.ticketDetails}
              passengerCount={ticketCount}
              chkFormVal={this.state.chkFormVal}
              handleVideoUpdate={this.handleVideoUpdate}
              handleTandemUpdate={this.handleTandemUpdate}
              phone={this.props.location.pathname === "/phone"}
              eventName={this.state.EventName}
              type={type}
              eventFilter={this.state.namedEvent}
              handleFieldChange={this.handleFieldUpdate}
              studentDetails={this.state.studentDetails}
              payType={this.state.payType}
              selectedTandemPackages={this.state.selectedTandemPackages}
              validateTandemPack={this.state.validateTandemPack}
              tandemCoupon={this.state.tandemCoupon}
            />
          )}
        </div>
        {
          this.state.slotDates.length > 0 &&
          finalForm.timeslotId && (this.state.ticketCount > 0 || this.state.grouponPackage !== null || this.state.giftPackage !== null) && this.renderInvoice()
        }
        {this.state.slotDates.length > 0 && finalForm.timeslotId && this.state.ticketCount > 0 && !this.state.deposit && <DiscountInput validateEmail={this.validateEmail} ticketCount={this.state.ticketCount} finalForm={this.state.finalForm} pathname={this.props.location.pathname} onChange={this.handleOnDiscountAdd} type="discount" text="enter discount code" eventType={this.state.EventName} />}
        {
          this.props.location.pathname !== "/phone" && this.state.slotDates.length > 0 && finalForm.timeslotId && (this.state.ticketCount > 0 || this.state.grouponPackage !== null || this.state.giftPackage !== null) > 0 &&
          <div className="containerPadding col-md-12"
            style={{
              margin: "auto", textAlign: "center",
              marginTop: "40px"
            }}
          >
          
            <button
              className="terms_conditions__button"
              onClick={() => this.setState({
                isModalOpen: true
              })}
            >
              Terms and Conditions
            </button><br />
            <input type="checkbox"
              disabled
              checked={this.state.TandC}
              onChange={val => this.changeTC(val.target.checked)}
            /> <span className="terms_conditions">
              I have read the terms and conditions
            </span>
          </div>
        }

        {
          this.state.slotDates.length > 0 &&
          <div className="containerPadding col-md-12" style={{ margin: "auto" }} >
            {
              finalForm.timeslotId && (this.state.ticketCount > 0 || this.state.grouponPackage !== undefined || this.state.giftPackage !== undefined) && this.renderButton()
            }
          </div>
        }
       
        { this.state.showTransnational && 
          <>
           <TransnationalGateway />
          </>
       
        }
       
      </div >
    );
  };




  showMainForm = () => {
    this.setState({ hideMainForm: false, deposit: false });
  }

  onPaymentCompletion = (r, cutomerResponse, Transactionerror, tokeniserResponse, transactionErr) => {

    console.log(r, cutomerResponse, Transactionerror, tokeniserResponse, transactionErr,"<==r, cutomerResponse, Transactionerror, tokeniserResponse, transactionErr")
    const { checkoutId } = this.state;
    if (window.ENV.processorInfo.processor == 'nab' && r != null && ((r.Status === "Successful" && checkoutId !== r.TransactionId) || r.status === "success")) {
      handleReservation(this.state, this.setState.bind(this), this.props, r, cutomerResponse);
    }
    else if (window.ENV.processorInfo.processor == 'transnational') {
      this.setState({showTransnational:false})
      handleReservation(this.state, this.setState.bind(this), this.props, r, cutomerResponse, Transactionerror, tokeniserResponse, transactionErr);
    }
    else if (window.ENV.processorInfo.processor == 'cardConnect') {
      handleReservation(this.state, this.setState.bind(this), this.props, r, cutomerResponse, Transactionerror);
    }
    else if (checkoutId === r.TransactionId) {
      console.log('Error');
    }
  }

  handleReservationAuthorize = (r, cutomerResponse) => {

    var { finalForm, hideMainForm, totalAmount, totalFees, giftCard, checkoutId, deposit, isWeekEnd, videoCount, discount, discountValue } = this.state;
    var payload = {};
    var submit = false;
    if (giftCard) {
      var transactionId = 'GiftCardTransaction';
      var amount = totalAmount;
      submit = true;
    }
    else if (r.messages.resultCode === "Ok") {
      var transactionId = '4354687129'
      var amount = totalAmount;
      submit = true;
    }

    var priceSchedule = tandemStore.priceSchedule;
    var tandemWeekend = this.state.loadPrices.tandemPackages[0].weekendPrice;
    var tandemWeekday = this.state.loadPrices.tandemPackages[0].price
    var tandemVideoCost = this.getPrice(priceSchedule, "video");
    var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
    var AffFee = this.getPrice(priceSchedule, "AFF1");

    var taxPerc = parseInt(scheduleFee);
    var tandemUnitCost = 0;

    if (this.state.EventName.trim() == 'Tandem') {
      tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
    }
    else if (this.state.EventName.trim() == 'AFF') {
      tandemUnitCost = parseInt(AffFee);
    }

    if (amount && submit) {
      var email = '';
      var name = '';
      var othername = '';
      var eventName = '';
      var ticketCount = '';
      var videoCount = '';
      var slotDates = '';
      var passangerBody = [];
      var recipients = [];
      finalForm.passengers.forEach(element => {
        if (element.video === true) {
          element.totalPaid = deposit ? this.state.tandemEventDepositAmount + (this.state.videoCount * tandemVideoCost) : tandemUnitCost + (this.state.videoCount * tandemVideoCost);
        }
        else {
          element.totalPaid = deposit ? this.state.tandemEventDepositAmount : tandemUnitCost;
        }
        passangerBody.push(element);
        recipients.push({ firstname: element.firstname, lastname: element.lastname, email: element.email });
        if (email == '') {
          email = element.email;
          name = element.firstname + " " + element.lastname;
          amount = amount;
          eventName = this.state.EventName;
          ticketCount = this.state.ticketCount;
          videoCount = this.state.videoCount;
          slotDates = this.state.slotDates;
        }
        else {
          email = email + ' ,' + element.email;
          othername += `<p class="subText" style="font-family:'Opens Sans', sans-serif;margin: 0 auto; margin-top: 15px; text-align: center;font-size: 1.5em;text-align: center;padding-bottom: 0.2em"><b
              style="font-weight: 700; font-family: inherit;padding-bottom: 0.4em">Name: </b> ${element.firstname + " " + element.lastname}</p>`;
        }
      });

      var date = new Date(this.state.slotDates[0].datetime);
      var month = date.toLocaleString('en-us', { month: 'long' });
      var Subject = "Inovice Report";
      var totalAmountUpdate = this.state.discount ? totalAmount - this.state.discountValue : totalAmount;
      var Message = "Your Reservation Has Been Successfully Made for " + date.getDate() + ' ' + month + ' ' + date.getFullYear() + ' ' + 'at' + ' ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + " and The Invoice generated is : " + totalAmountUpdate + " ";
      var emailBody = {
        email: email,
        name: name,
        phone: finalForm.passengers[0].phone,
        othername: othername,
        subject: Subject,
        amount: amount,
        eventName: eventName,
        ticketCount: ticketCount,
        videoCount: videoCount,
        message: Message,
        slotDates: slotDates,
        recipients: recipients,
        discount,
        discountValue
      };

      const { isWeekEnd } = this.state;
      var priceSchedule = tandemStore.priceSchedule;
      var tandemWeekend = this.state.loadPrices.tandemPackages[0].weekendPrice;
      var tandemWeekday = this.state.loadPrices.tandemPackages[0].price;
      var tandemVideoCost = this.getPrice(priceSchedule, "video");
      var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
      var AffFee = this.getPrice(priceSchedule, "AFF1");

      var taxPerc = parseInt(scheduleFee);
      var tandemUnitCost = 0;

      if (this.state.EventName.trim() == 'Tandem') {
        tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
      }
      else if (this.state.EventName.trim() == 'AFF') {
        tandemUnitCost = parseInt(AffFee);
      }

      if (this.state.manualGiftCard)
        this.setState({ videoCost: 0 })



      payload = {
        passengers: passangerBody,
        ticketCount: this.state.ticketCount,
        videoCount: this.state.videoCount,
        videoCost: this.state.videoCost || 0,
        jumpsPrice: this.state.deposit === true ? (this.state.tandemEventDepositAmount * this.state.ticketCount).toFixed(2) : (tandemUnitCost * this.state.ticketCount).toFixed(2),
        slotId: finalForm.timeslotId,
        deposit: this.state.deposit,
        amount: amount,
        totalFees: this.state.totalFees,
        transactionId: transactionId,
        eventName: this.state.EventName,
        discount,
        discountValue
      };


      tandemStore.MakeReservation(payload);

      tandemStore.sendEmail(emailBody);

      swal({
        title: "Success",
        text: "Congratulations for creating your skydiving reservation \n Please check your email for confirmation and waiver signing \n We look forward to seeing you soon",
        icon: "success",
        dangerMode: false,
      })

      this.setState({
        ticketCount: 0,
        videoCount: 0,
        deposit: false,
        message: null,
        enablePurchase: false,
        hideMainForm: false,
        giftCard: false,
        cardCode: '',
        errorMessage: false,
        slotDates: [],
        discount: undefined,
        grouponPackage: null,
        discountValue: 0,
        checkoutId: transactionId,
        tandemCoupon: false,
        tandemCouponFdata: false,
        selectedTandemPackages: [],
        selectedVideoPackages: [],
        couponedTandemPack: {}
      })
    }

  }

  handleGrouponReservation = (data) => {
    var { finalForm, hideMainForm, totalAmount, totalFees, giftCard, checkoutId, deposit, isWeekEnd, videoCount, discount, discountValue } = this.state;
  }
  handleTermsAndCondition = (isAccepted) => {
    this.setState({ isModalOpen: false, TandC: isAccepted })
  }

  addMemoMessage = (val) => {
    this.setState({ memoMessage: val })
  }





  // renderPaymentButton = () => {
  //   var htmlInput = '<button className="velocity-button customHeading" data-terminal-profile-id="' + url.key + '" >Pay Now</button>';
  //   return renderHTML(htmlInput);
  // }
  handleFieldUpdate = () => {


    var isFormHaveEmail = false
    var formField = tandemStore.formData.finalForm.passengers;
    if (formField.length > 0) {
      formField.forEach((value, i) => {
        if (value != null && value.email != null && value.email.trim() != '') {
          isFormHaveEmail = true;
        }
      })
      if (isFormHaveEmail) {
        var { finalForm, hideMainForm, totalAmount, totalFees, giftCard, checkoutId, deposit, isWeekEnd, videoCount, discount, discountValue } = this.state;
        var payload = {};
        if (giftCard) {
          var transactionId = 'GiftCardTransaction';

          // })
        }
        var priceSchedule = tandemStore.priceSchedule;
        var videoPackages = tandemStore.getVideoPackages();

        var tandemWeekend = this.state.loadPrices.tandemPackages[0].weekendPrice;
        var tandemWeekday = this.state.loadPrices.tandemPackages[0].price
        var tandemVideoCost = this.getPrice(priceSchedule, "video");
        var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
        var AffFee = tandemStore.getFirstJump().price;

        var taxPerc = parseInt(scheduleFee);
        var tandemUnitCost = 0;
        var priceSchedule = tandemStore.priceSchedule;
        var tandemWeekend = this.state.loadPrices.tandemPackages[0].weekendPrice;
        var tandemWeekday = this.state.loadPrices.tandemPackages[0].price;
        var tandemVideoCost = this.getPrice(priceSchedule, "video");
        var scheduleFee = this.getPrice(priceSchedule, "schedule fee");
        var AffFee = tandemStore.getFirstJump().price;

        var taxPerc = parseInt(scheduleFee);
        var tandemUnitCost = 0;
        var passangerBody = [];
        finalForm.passengers.forEach(element => {
          if (element != undefined && element != null) {
            var passenger = {};
            var videoPrice = 0;
            if (element.video !== "none")
              videoPrice = videoPackages.filter(pkg => pkg.item === element.video)[0].videoPrice;
            if (element.video !== "none") {
              element.totalPaid = deposit ? 50 + (videoPrice) : tandemUnitCost + (videoPrice);
            }
            else {
              element.totalPaid = deposit ? 50 : tandemUnitCost;
            }
            passenger = {
              firstname: element.firstname,
              lastname: element.lastname,
              email: element.email,
              phone: element.phone ? element.phone : "",
              tandemPackage: element.tandemPackage,
              video: element.video,
              adult: element.adult,
              weight: element.weight
            }
            passangerBody.push(passenger);
          }

        })
        if (this.state.EventName.trim() == 'Tandem') {
          tandemUnitCost = isWeekEnd ? parseInt(tandemWeekend) : parseInt(tandemWeekday);
        }
        else if (this.state.EventName.trim() == 'AFF') {
          tandemUnitCost = parseInt(AffFee);
        }


        var invoice = [];

        if (this.state.EventName === "AFF") {
          invoice.push({ item: "AFF", count: this.state.ticketcount, value: this.state.affValue })
        }

        this.state.selectedTandemPackages.forEach(pkg => {
          invoice.push({ item: pkg.name, count: pkg.count, value: pkg.price });
        });

        this.state.selectedVideoPackages.forEach(pkg => {
          invoice.push({ item: pkg.name, count: pkg.count, value: pkg.price });
        });

        if (this.state.manualGiftCard)
          this.setState({ videoCost: 0 })

        if (passangerBody.length > 0) {
          payload = {
            passengers: passangerBody,
            ticketCount: this.state.ticketCount,
            numVideos: this.state.videoCount,
            videoCost: this.state.videoCost || 0,
            jumpsPrice: this.state.deposit === true ? (50 * this.state.ticketCount).toFixed(2) : (tandemUnitCost * this.state.ticketCount).toFixed(2),
            slotId: finalForm.timeslotId,
            deposit: this.state.deposit,
            amount: this.state.discount ? totalAmount - this.state.discountValue : totalAmount,
            totalFees: this.state.totalFees,
            transactionId: transactionId,
            eventName: this.state.EventName,
            transactionId: transactionId,
            payType: this.state.payType,
            invoice: this.inCompleteReservation && this.inCompleteReservation.invoice && invoice.length == 0 ? this.inCompleteReservation.invoice : invoice,
            discountValue,
            discount
          };
          tandemStore.saveParkedReservation(payload);
        }
      }
    }
  }

  validatedGiftCard = (couponCode) => {
    this.setState({
      giftCard: true,
      manualGiftCard: true,
      videoCost: 0,
      selectedTandemPackages: [],
      selectedVideoPackages: [],
      couponCode
    })
  }





  render() {

    var { finalForm, hideMainForm, totalAmount, totalFees, phone } = this.state;
    if (this.state.hideMainForm) {
      payload = {
        passengers: this.state.finalForm.passengers,
        ticketCount: this.state.ticketCount,
        videoCount: this.state.videoCount,
        slotId: this.state.finalForm.timeslotId,
        deposit: this.state.deposit
      };
    }

    const totalAmountUpdated = this.state.discount ? totalAmount - this.state.discountValue : totalAmount;

    return this.state.isShowLoader ? <Shoploader /> : (
      <div
        id="reservationForm"
        style={{
          backgroundColor: 'rgba(235, 244, 247, 0.72)',
          overflowX: 'hidden',
          position: 'relative',
          minHeight: window.innerHeight,
          marginBottom: 30
        }}
      >
      <GooglePay/>
      {this.renderMainForm(finalForm)}
        {
          window.ENV.processorInfo.processor == "nab" &&
          <span style={{ display: 'none' }}>
            <NabVelocity
              amount={totalAmountUpdated}
              terminalProfileId={window.ENV.processorInfo.paymentKey}
              onProcessPayment={this.onPaymentCompletion}
              text="Card"
            />
          </span>
        }
        <TermAndCondition isModalOpen={this.state.isModalOpen} handleTermsAndCondition={this.handleTermsAndCondition}
          allConditions={this.state.allConditions} setState={this.setState.bind(this)}
        />
      </div>
    );
  }
}

export default withRouter(Reservation);
