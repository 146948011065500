import tandemStore from '../../../stores/store'
const TagManager = require('react-gtm-module');

export const AfterPayment = (state, setState, props, r, cutomerResponse, Transactionerror, transaction_id, maskedCard) => {
  console.log(maskedCard);
  var { tandemPkgArr, vidPkgArr, affFacebookTrackVal } = state;
  if (Transactionerror) {
    if (window.ENV.gtmId) {
      var dataLayerBody = {
        event: 'failedPurchase',
        value: state._errorValue,
        method: "credit card"
      }
      if (r?.isGooglePay) {
        dataLayerBody.method = "google pay"
      }
      else if (r?.isApplePay) {
        dataLayerBody.method = "apple pay"
      }
      else{
        dataLayerBody.method = "credit card"
      }
      TagManager.dataLayer({
        dataLayer: dataLayerBody
      })
    }

    swal({
      title: "Error",
      text: window.ENV.processorInfo.processor == "square" ? Transactionerror.message : "Wrong Card details Please enter again",
      icon: "error",
      dangerMode: true,
    });
    return
  }

  if (r.status === "success" || r.status == 200) {

    let totalPrice = 0
    if (state.EventName.trim() == 'AFF') {
      totalPrice = affFacebookTrackVal
    } else {
      const totalTandemPrice = tandemPkgArr.reduce((acc, cur) => !state.isWeekEnd ? acc + cur.price : acc + cur.weekendPrice, 0)
      const totalVideoPrice = vidPkgArr.reduce((acc, cur) => acc + cur.videoPrice, 0)
      totalPrice = totalTandemPrice + totalVideoPrice
    }
    tandemStore.clearAll();
    if (window.ENV.gtmId) {
      var dataLayerBody = {
        event: 'purchase',
        value: totalPrice || 0,
        paid: state._totalAmountPaid,
        currency: 'USD',
        items: state.selected_items,
        transaction_id,
      }
      if (r?.isGooglePay) {
        dataLayerBody.method = "google pay"
      }
      else if (r?.isApplePay) {
        dataLayerBody.method = "apple pay"
      }
      else{
        dataLayerBody.method = "credit card"
      }
      TagManager.dataLayer({
        dataLayer: dataLayerBody
      })
    }



    setState({
      ticketCount: 0,
      videoCount: 0,
      deposit: false,
      message: null,
      enablePurchase: false,
      hideMainForm: false,
      giftCard: false,
      cardCode: '',
      errorMessage: false,
      totalTax: 0,
      totalFees: 0,
      totalAmount: 0,
      slotDates: [],
      discount: undefined,
      discountValue: 0,
      checkoutId: r.transactionId,
      grouponPackage: null,
      giftPackage: null,
      payType: '',
      isClearGroupon: true,
      giftCodeEmail: undefined,
      tandemCoupon: false,
      tandemCouponFdata: false,
      selectedTandemPackages: [],
      selectedVideoPackages: [],
      couponedTandemPack: {},
      tandemCoupon: false,
      tandemCouponFdata: false,
      tandemCouponZeroValued: false,
      couponedTandemPack: {},
      finalForm: {}

    })
    tandemStore.clearAll();
    let cardString ='';
    if(maskedCard){
      cardString = "with " + maskedCard.substr(maskedCard.length-5);
      if(r.isGooglePay){
        cardString += " (Google Pay)"
      }
      if(r.isApplePay){
        cardString += " (Apple Pay)"
      }
    }
    
    swal({
      title: "Success",
      text: `Congratulations for creating your skydiving reservation ${cardString} \n Please check your email (including spam and junk) for confirmation and waiver signing \n We look forward to seeing you soon`,
      icon: "success",
      dangerMode: false,
    })

  }
}