import { useEffect, useState } from "react";



const ShowPaymentCards = (props) => {



    return (
        <div className="row" style={props.giftShop && { margin: "auto" }}>
            {(window.ENV.processorInfo.appleMerchantId || window.ENV.processorInfo.processor === "square") && props.isIos &&
                <div className="col-md-2 col-sm-2 col-2 col-lg-1">
                    <img src="/images/ApplePay.webp" alt="cards-logo" className="height_adjusment" />
                </div>
            }
            {(window.ENV.processorInfo.googleMerchantId || window.ENV.processorInfo.processor === "square") &&
                <div className="col-md-2 col-sm-2 col-2 col-lg-1">
                    <img src="/images/GooglePay.webp" alt="cards-logo" className="height_adjusment" />
                </div>
            }

            <div className="col-md-2 col-sm-2 col-2 col-lg-1">
                <img src="/images/Mastercard.webp" alt="cards-logo" className="height_adjusment" />
            </div>
            
            {window.ENV.processorInfo.dontShowDiscover!==true && <div className="col-md-2 col-sm-2 col-2 col-lg-1">
                <img src="/images/Discover.webp" alt="cards-logo" className="height_adjusment" />
            </div>}

            <div className="col-md-2 col-sm-2 col-2 col-lg-1">
                <img src="/images/Group6624.webp" alt="cards-logo" className="height_adjusment" />
            </div>

            {window.ENV.processorInfo.dontShowAmex!==true && <div className="col-md-2 col-sm-2 col-2 col-lg-1" >
                <img src="/images/Amex.webp" alt="cards-logo" className="height_adjusment" />
            </div>}
        </div>
    )
}

export default ShowPaymentCards;