import React, { Component } from 'react';
import './shoploader.css';

class ShopLoader extends Component {


    render() {
        return(
        <div className="shop-page-loader">
            <div id="kapowsin">
                {/* <img src="./loaders/kapowsin.webp" alt="" /> */}
                <img src={window.ENV.logoUrl} alt="" />

            </div>
            <div id="dizio">
                <img src="./loaders/dizio.logo.webp" width="194" height="62" alt="Dizio" />
                {/* <img src={window.ENV.logoUrl} width="194" height="62" alt="Dizio" /> */}

            </div>
            <div id="loader">
                <div className="longfazers">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="loading">LOADING</div>
                <div className="skydiver">
                    <span className="left">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="left-hand">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="right">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="right-hand">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <img src="./loaders/tandem.webp" width="150" alt="" />
                </div>
            </div>
        </div>
        )
    }
}

export default ShopLoader;